import React, { useState, useMemo } from 'react';
import { elementColors, signs } from "../../data/zodiacConstants";
import { bodyReference } from "../../scripts/chart";
import { useBodyViewSettings } from "../../contexts/bodyViewContext";
import { useSabianSymbols } from "../../hooks";

// Import extracted components
import ToggleSwitch from '../ui/ToggleSwitch';
import Symbol from '../symbols/Symbol';

// Import helper functions
import { 
  getHouseForDegree, 
  getZodiacSigns 
} from '../../utils/planetaryHelpers';

import './PlanetaryComponents.css';

/**
 * Component for displaying celestial body positions with their zodiac signs, houses, and Sabian symbols
 * 
 * @param {Object} props - Component props
 * @param {Object} props.positionData - Data containing positions of celestial bodies
 * @param {Array} props.cusps - Array of house cusp degrees
 * @param {string} props.title - Title for the position card
 * @returns {JSX.Element} - The body position card component
 */
const BodyPositionCard = ({ positionData, cusps, title }) => {
  const { isChartVisible } = useBodyViewSettings();
  const [showSabian, setShowSabian] = useState(false);
  const [expandedSymbols, setExpandedSymbols] = useState({});

  const zodiacSigns = getZodiacSigns();

  // Memoize visible positions to prevent infinite re-renders
  const visiblePositions = useMemo(() => {
    if (!positionData || !positionData.positions) return [];
    return positionData.positions.filter(pos => isChartVisible(pos.body));
  }, [positionData, isChartVisible]);

  // Memoize degrees to prevent unnecessary API calls
  const degreesToFetch = useMemo(() => {
    return visiblePositions.map(pos => pos.degree);
  }, [visiblePositions]);

  // Use the custom hook to fetch and manage Sabian symbols
  const { symbols: sabianSymbols, loading } = useSabianSymbols(degreesToFetch);

  // Toggle expanded state for a specific symbol
  const toggleSymbolExpanded = (degree) => {
    setExpandedSymbols(prev => ({
      ...prev,
      [degree]: !prev[degree]
    }));
  };

  // Determine if the card represents today's date
  const isToday = positionData && positionData.date && 
    new Date().toDateString() === positionData.date.toDateString();

  return (
    <div 
      key={(positionData && positionData.date) ? positionData.date.getTime() : 'bpc'}
      style={{
        marginBottom: 24,
        borderRadius: 4,
        backgroundColor: 'rgba(255,255,255,0)',
        borderLeft: isToday ? '3px solid white' : '3px solid transparent'
      }}
    >
      {/* Title */}
      <div style={{ 
        color: 'rgba(255,255,255,.5)',
        textAlign: 'center', 
        width: '100%',
      }}>
        {title}
      </div>

      {/* Date Display */}
      <div style={{ 
        marginBottom: 8,
        borderBottom: '1px solid rgba(255,255,255,0.2)',
        paddingBottom: 4,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: 'rgba(255,255,255,.5)',
        textAlign: 'center', 
        width: '100%',
      }}>
        {positionData.date && (
          <span>
            {positionData.date.toLocaleDateString('en-US', { 
              weekday: 'short',
              day: 'numeric'
            })}
          </span>
        )}
      </div>

      {/* Sabian Symbol Toggle */}
      <ToggleSwitch 
        isOn={showSabian} 
        onToggle={() => setShowSabian(!showSabian)} 
        label={loading ? "Loading Symbols..." : "Show Symbols"} 
      />

      {/* Body Positions */}
      <div style={{ fontSize: 14 }}>
        {visiblePositions.map((pos, i) => {
          const bodyInfo = bodyReference[pos.body];
          if (!bodyInfo) {
            return null;
          }
          
          const signData = signs.filter(sign => sign.name === zodiacSigns[pos.sign])[0];
          const houseNumber = cusps ? getHouseForDegree(pos.degree, cusps) : null;
          const sabianSymbol = sabianSymbols[pos.degree];
          const isExpanded = !expandedSymbols[pos.degree];

          return (
            <div key={i}>
              {/* Body Info Row */}
              <div style={{ 
                marginBottom: 4,
                display: 'flex',
                alignItems: 'center',
                gap: 8
              }}>
                {/* Body Symbol */}
                <span style={{ 
                  minWidth: 48, 
                  color: bodyInfo.color, 
                  textAlign: 'center', 
                  lineHeight: '12px', 
                  borderRadius: 10, 
                  width: 10, 
                  height: 10 
                }} className='symbolText'>
                  <div style={bodyInfo.circle ? { 
                    border: '1px solid ' + bodyInfo.color, 
                    margin: 'auto', 
                    borderRadius: 10, 
                    width: 10, 
                    maxHeight: 10
                  } : {}}>
                    {bodyInfo.symbol}
                  </div>
                </span>

                {/* Sign and House Info */}
                <span style={{ 
                  flex: 1,
                  fontSize: 10,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 8
                }}>
                  <span style={{ width: 96 }}>
                    <span className="symbolText" style={{ 
                      lineHeight: '10px', 
                      color: elementColors[signData.element]
                    }}>
                      {signData.symbol}
                    </span> {zodiacSigns[pos.sign]} {pos.signDegree && pos.signDegree.toFixed(1)}°
                    {pos.longitudeSpeed < 0 && ' ℞'} {/* Add retrograde symbol if moving backwards */}
                  </span>
                  
                  {/* House Number */}
                  {houseNumber && (
                    <span style={{
                      color: bodyInfo.color,
                      fontSize: 10,
                      marginLeft: 4
                    }}>
                      House {houseNumber}
                    </span>
                  )}
                </span>
              </div>

              {/* Sabian Symbol Display */}
              <Symbol 
                showSabian={showSabian}
                sabianSymbol={sabianSymbol}
                pos={pos}
                isExpanded={isExpanded}
                toggleSymbolExpanded={toggleSymbolExpanded}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BodyPositionCard;
