//src/contexts/birthContext.js
import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { calculateNatalTransits } from '../api/trine-backend.api';
import { processNatalTransits } from '../scripts/transits';
import { getTimezoneFromCoordinates } from '../scripts/helpers';
import { getCoordMidpoint } from '../scripts/chart';

export const BirthContext = createContext(null);

export const useBirthData = (instance = 1) => {
  const [timezone, setTimezone] = useState('America/New_York');
  const [unixTimestamp, setUnixTimestamp] = useState(new Date().getTime());
  const [coordinates, setCoordinates] = useState({ latitude: null, longitude: null });
  const [transits, setTransits] = useState(null);
  const [isLoadingTransits, setIsLoadingTransits] = useState(false);

  const setCoordinateValue = useCallback((value, property) => {
    setCoordinates(prev => ({
      ...prev,
      [property]: parseFloat(value) || null
    }));
  }, []);

  return {
    timezone,
    setTimezone,
    unixTimestamp,
    setUnixTimestamp,
    coordinates,
    setCoordinates,
    setCoordinateValue,
    transits,
    setTransits,
    isLoadingTransits,
    setIsLoadingTransits
  };
};

export const BirthProvider = ({ children }) => {
  const birth1 = useBirthData(1);
  const birth2 = useBirthData(2);
  const midpoint = useBirthData(3);
//   const [midpoint, setMidpoint] = useState({
//     coordinates: { latitude: null, longitude: null },
//     timestamp: null,
//     timezone: null
//   });

  const getTimezoneNo = useCallback(n => n === 1 ? birth1.timezone : birth2.timezone, [birth1.timezone, birth2.timezone]);
  const setTimezoneNo = useCallback((t, n) => n === 1 ? birth1.setTimezone(t) : birth2.setTimezone(t), [birth1.setTimezone, birth2.setTimezone]);

  const getUnixTimestampNo = useCallback(n => n === 1 ? birth1.unixTimestamp : birth2.unixTimestamp, [birth1.unixTimestamp, birth2.unixTimestamp]);
  const setUnixTimestampNo = useCallback((t, n) => n === 1 ? birth1.setUnixTimestamp(t) : birth2.setUnixTimestamp(t), [birth1.setUnixTimestamp, birth2.setUnixTimestamp]);

  const getCoordinatesNo = useCallback(n => n === 1 ? birth1.coordinates : birth2.coordinates, [birth1.coordinates, birth2.coordinates]);
  const setCoordinatesNo = useCallback((c, n) => n === 1 ? birth1.setCoordinates(c) : birth2.setCoordinates(c), [birth1.setCoordinates, birth2.setCoordinates]);
  const setCoordinatesNoValue = useCallback((value, property, n) => {
    if (n === 1) birth1.setCoordinateValue(value, property);
    if (n === 2) birth2.setCoordinateValue(value, property);
  }, [birth1.setCoordinateValue, birth2.setCoordinateValue]);

  const getTransits = useCallback(async (birth) => {
    // Skip if already loading or missing required data
    if (birth.isLoadingTransits || 
        !birth.unixTimestamp || 
        !birth.coordinates.latitude || 
        !birth.coordinates.longitude || 
        !birth.timezone) {
      return;
    }

    try {
      birth.setIsLoadingTransits(true);

      // Calculate date range
      const now = new Date();
      const prevMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      const nextMonth = new Date(now.getFullYear(), now.getMonth() + 2, 0);

      const result = await calculateNatalTransits(
        prevMonth.getTime(),
        nextMonth.getTime(),
        birth.unixTimestamp,
        birth.coordinates.latitude,
        birth.coordinates.longitude,
        birth.timezone
      );

      if (result) {
        const processed = processNatalTransits(result);
        birth.setTransits(processed);
      }
    } catch (error) {
      console.error('Error calculating transits:', error);
      birth.setTransits(null);
    } finally {
      birth.setIsLoadingTransits(false);
    }
  }, []);

  // Calculate midpoint when both birth data are complete

  
  useEffect(() => {
    if (birth1.coordinates.latitude !== null && 
        birth1.coordinates.longitude !== null &&
        birth1.coordinates.latitude !== undefined && 
        birth1.coordinates.longitude !== undefined &&
        birth2.coordinates.latitude !== null && 
        birth2.coordinates.longitude !== null &&
        birth2.coordinates.latitude !== undefined && 
        birth2.coordinates.longitude !== undefined) {
      
      const midpointCoord = getCoordMidpoint(birth1.coordinates.latitude, birth1.coordinates.longitude, birth2.coordinates.latitude, birth2.coordinates.longitude)
      const midTime = Math.floor((birth1.unixTimestamp + birth2.unixTimestamp) / 2);
      const midTz = getTimezoneFromCoordinates(midpointCoord.latitude, midpointCoord.longitude);

      midpoint.setCoordinates({
        latitude: midpointCoord.latitude,
        longitude: midpointCoord.longitude
      });
      midpoint.setUnixTimestamp(midTime);
      midpoint.setTimezone(midTz);
    }
  }, [
    birth1.coordinates.latitude, birth1.coordinates.longitude, birth1.unixTimestamp, birth2.coordinates.latitude, birth2.coordinates.longitude, birth2.unixTimestamp
  ]);

//   useEffect(() => {
//     if (birth1.coordinates.latitude !== null && 
//         birth1.coordinates.longitude !== null && 
//         birth1.timezone) {
//       const timer = setTimeout(() => {
//         getTransits(birth1);
//       }, 1000); // Increased debounce to 1 second
//       return () => clearTimeout(timer);
//     }
//   }, [
//     birth1.unixTimestamp, birth1.coordinates.latitude, birth1.coordinates.longitude, birth1.timezone, birth1.isLoadingTransits, getTransits
//   ]);

  // Debounced transit calculations
  useEffect(() => {
    if (!midpoint.isLoadingTransits && 
      midpoint.coordinates.latitude !== null && 
      midpoint.coordinates.longitude !== null && 
        midpoint.timezone) {
            
      const timer = setTimeout(() => {
        getTransits(midpoint);
      }, 1000); // Increased debounce to 1 second
      return () => clearTimeout(timer);
    }
  }, [
    midpoint.unixTimestamp,
    midpoint.coordinates.latitude,
    midpoint.coordinates.longitude,
    midpoint.timezone,
    midpoint.isLoadingTransits,
    getTransits
  ]);


  // Debounced transit calculations
  useEffect(() => {
    if (!birth1.isLoadingTransits && 
      birth1.coordinates.latitude !== null && 
      birth1.coordinates.longitude !== null && 
        birth1.timezone) {
      const timer = setTimeout(() => {
        getTransits(birth1);
      }, 1000); // Increased debounce to 1 second
      return () => clearTimeout(timer);
    }
  }, [
    birth1.unixTimestamp,
    birth1.coordinates.latitude,
    birth1.coordinates.longitude,
    birth1.timezone,
    birth1.isLoadingTransits,
    getTransits
  ]);

  useEffect(() => {
    if (!birth2.isLoadingTransits && 
        birth2.coordinates.latitude && 
        birth2.coordinates.longitude && 
        birth2.timezone) {
      const timer = setTimeout(() => {
        getTransits(birth2);
      }, 1000); // Increased debounce to 1 second
      return () => clearTimeout(timer);
    }
  }, [
    birth2.unixTimestamp,
    birth2.coordinates.latitude,
    birth2.coordinates.longitude,
    birth2.timezone,
    birth2.isLoadingTransits,
    getTransits
  ]);

  const birthDataComplete1 = birth1.coordinates.latitude !== null && birth1.coordinates.latitude !== undefined && birth1.coordinates.longitude !== null && birth1.coordinates.longitude !== undefined && birth1.timezone !== null && birth1.timezone !== undefined && birth1.unixTimestamp !== null && birth1.unixTimestamp !== undefined;
  const birthDataComplete2 = birth2.coordinates.latitude !== null && birth2.coordinates.latitude !== undefined && birth2.coordinates.longitude !== null && birth2.coordinates.longitude !== undefined && birth2.timezone !== null && birth2.timezone !== undefined && birth2.unixTimestamp !== null && birth2.unixTimestamp !== undefined;
  const midpointDataComplete = midpoint.coordinates.latitude !== null && midpoint.coordinates.latitude !== undefined && midpoint.coordinates.longitude !== null && midpoint.coordinates.longitude !== undefined && midpoint.timezone !== null && midpoint.timezone !== undefined && midpoint.unixTimestamp !== null && midpoint.unixTimestamp !== undefined;

  const value = {
    birth1,
    birth2,
    midpoint,
    getTimezoneNo,
    setTimezoneNo,
    getUnixTimestampNo,
    setUnixTimestampNo,
    getCoordinatesNo,
    setCoordinatesNo,
    setCoordinatesNoValue,
    birthDataComplete1,
    birthDataComplete2,
    midpointDataComplete
  };

  return <BirthContext.Provider value={value}>{children}</BirthContext.Provider>;
};

export const useDualBirthData = () => {
  const context = useContext(BirthContext);
  if (!context) throw new Error('useDualBirthData must be used within BirthProvider');
  return context;
};

export default BirthProvider;
