//src/components/modalsmenus/FloatingModal.jsx
import React, { useState } from 'react';
import { useViewData } from '../../contexts/viewContext';


const FloatingModal = ({ title, children, defaultOpen = true, hideUI, size=320, maxWidth='auto'}) => {
    // const [isOpen, setIsOpen] = useState(defaultOpen);

    const {getFloatingModalOpen, setFloatingModalOpen} = useViewData();
    const isOpen = getFloatingModalOpen(title)

    if (hideUI) return null;
    return (
        <div className="" style={{width: 'fit-content', maxWidth, height: isOpen ? size : 16, backdropFilter: 'blur(2px)',
            pointerEvents: 'auto',
            padding: '8px 8px',
            backgroundColor: 'rgba(0,0,0,0.3)',
            border: '1px solid rgba(255,255,255,0.3)',
            borderRadius: 8,
            color: 'white',
            margin: 8,
            cursor: 'pointer',fontSize: 10,overflow: 'hidden'}}>
            <div 
                className="flex justify-between items-center px-4 py-2 bg-opacity-80 bg-gray-900 cursor-pointer"
                onClick={(e) => {
                    setFloatingModalOpen(title, !isOpen)
                    e.stopPropagation();
                }}
                style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between',borderBottom: '1px solid rgba(255,255,255,.2)', paddingBottom: 8}}
            >
                <span className="text-white text-sm font-medium">{title}</span>
                <span>{isOpen ? '▲' : '▼' }</span>
                 
            </div>
            <div 
                className="transition-all duration-300 ease-in-out"
                style={{
                    maxHeight: isOpen ? size+40 : '0',
                    height: isOpen ? size+40 : 0,
                    opacity: isOpen ? 1 : 0,
                    overflow: 'hidden'
                }}
            >
                {children}
            </div>
        </div>
    );
};

export default FloatingModal;