import eclipse from './eclipse.json'
import { useState, useEffect, useMemo } from 'react'
import { elementColors, getZodiacDegree, signs } from "../../data/zodiacConstants"
import { AspectItem } from '../aspects/AspectItem';
import WheelConfig from '../charts/WheelConfig';
import { getSabianSymbols } from '../../api/trine-backend.api';
import Symbol from '../symbols/Symbol';





const EclipsePane = ({ calculateEclipseData, eclipseBodies, eclipseAspects, wheels, eclipseWindow, height=540 }) => {
    // Find the closest upcoming eclipse to today's date


    const findClosestUpcomingEclipse = () => {
        const today = new Date();
        const currentYear = today.getFullYear();
        const currentMonth = today.getMonth(); // 0-11
        const currentDay = today.getDate();
        
        
        // Convert month names to numbers for comparison
        const monthToNumber = {
            'January': 0, 'February': 1, 'March': 2, 'April': 3,
            'May': 4, 'June': 5, 'July': 6, 'August': 7,
            'September': 8, 'October': 9, 'November': 10, 'December': 11
        };
        
        // Calculate the difference in days between today and each eclipse
        const eclipsesWithDiff = eclipse.map((item, index) => {
            const eclipseDate = new Date(
                item.year,
                monthToNumber[item.month],
                item.day
            );
            
            // Calculate difference in milliseconds and convert to days
            const diffTime = eclipseDate.getTime() - today.getTime();
            const diffDays = diffTime / (1000 * 60 * 60 * 24);
            
            return { index, diffDays };
        });
        
        // Filter to only upcoming eclipses (positive diff)
        const upcomingEclipses = eclipsesWithDiff.filter(item => item.diffDays >= 0);
        
        if (upcomingEclipses.length > 0) {
            // Sort by closest date and return the index
            upcomingEclipses.sort((a, b) => a.diffDays - b.diffDays);
            return upcomingEclipses[0].index;
        } else {
            // If no upcoming eclipses, return the most recent past eclipse
            const pastEclipses = eclipsesWithDiff.filter(item => item.diffDays < 0);
            pastEclipses.sort((a, b) => b.diffDays - a.diffDays); // Sort in reverse to get closest past
            return pastEclipses.length > 0 ? pastEclipses[0].index : 0;
        }
    };
    
    // List of zodiac signs for reference
    const zodiacSigns = [
        "Aries", "Taurus", "Gemini", "Cancer",
        "Leo", "Virgo", "Libra", "Scorpio",
        "Sagittarius", "Capricorn", "Aquarius", "Pisces"
    ];
    
    // Initialize state with the closest upcoming eclipse
    const [selectedEclipse, setSelectedEclipse] = useState(findClosestUpcomingEclipse());
    // Initialize selectedChart state with default value of 1
    const [selectedChart, setSelectedChart] = useState('chart1');

    const [eclipseDegree, setEclipseDegree] = useState(0)
    const [loading, setLoading] = useState(false);
    const [sabianSymbols, setSabianSymbols] = useState({});
    const [degreesToFetch, setDegreesToFetch] = useState([]);
    const [expandedSymbols, setExpandedSymbols] = useState({});
    
    // console.log(eclipseAspects)

    const getEBodies = wheel => ([...wheel.bodies, ...wheel.asteroids, ...wheel.angleBodies, ...wheel.parts])
    

    // Function to normalize degrees to 0-360 range
    const normalizeDegree = (degree) => {
        return ((degree % 360) + 360) % 360;
    }

    
    // Memoize the wheels data to avoid unnecessary recalculations
    const memoizedWheels = useMemo(() => wheels, [wheels]);
    
    // Memoize the bodies for the selected chart
    const selectedChartBodies = useMemo(() => {
        const eb = memoizedWheels && memoizedWheels[selectedChart] 
            ? getEBodies(memoizedWheels[selectedChart]) 
            : null;
        

        return eb
    }, [memoizedWheels, selectedChart]);

    
    

    // Memoized eclipse aspect bodies at specified angles from eclipseDegree
    const eclipseAspectBodies = useMemo(() => {
        const angles = [30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330];
        return angles.map((angle, index) => ({
            color: "rgba(153,153,153,1)",
            degree: normalizeDegree(eclipseDegree + angle),
            index: 'ECL',//(index + 1).toString(),
            name: `Sun`,
            strokeWidth: 2,
            symbol: "",
            opacity: 0.3,
        }));
    }, [eclipseDegree]);
    
    useEffect(() => {
        if (selectedEclipse && memoizedWheels && memoizedWheels[selectedChart] && selectedChartBodies) {
            const position = eclipse[selectedEclipse].position;
            const signName = position.sign;
            setEclipseDegree(getZodiacDegree(signName, eclipse[selectedEclipse].position.degree+position.minute/60))
            calculateEclipseData(selectedChart, eclipseDegree, selectedChartBodies)
        }        
    }, [memoizedWheels, selectedEclipse, eclipseWindow, selectedChart, calculateEclipseData, selectedChartBodies])

    // Update degrees to fetch when eclipseDegree or eclipseAspectBodies change
    useEffect(() => {
        // Get unique degrees from eclipseDegree and eclipseAspectBodies
        const degrees = [eclipseDegree, ...eclipseAspectBodies.map(body => body.degree)];
        const uniqueDegrees = [...new Set(degrees)];
        setDegreesToFetch(uniqueDegrees);
    }, [eclipseDegree, eclipseAspectBodies]);

    // Fetch Sabian symbols for the degrees
    useEffect(() => {
        const fetchSabianSymbols = async () => {
            if (!degreesToFetch || degreesToFetch.length === 0) return;
            
            setLoading(true);
            try {
                // Call the API to get Sabian symbols
                const symbols = await getSabianSymbols(degreesToFetch);
                
                // Create a map of degree to symbol
                const symbolMap = {};
                degreesToFetch.forEach((degree, index) => {
                    symbolMap[degree] = symbols[index];
                });
                
                setSabianSymbols(symbolMap);
            } catch (error) {
                console.error('Error fetching Sabian symbols:', error);
            } finally {
                setLoading(false);
            }
        };
        
        fetchSabianSymbols();
    }, [degreesToFetch]);

    // Toggle expanded state for a specific symbol
    const toggleSymbolExpanded = (degree) => {
        setExpandedSymbols(prev => ({
            ...prev,
            [degree]: !prev[degree]
        }));
    };
    
    // Format the eclipse date and time for display
    const formatEclipseDateTime = (eclipse) => {
        return `${eclipse.month} ${eclipse.day}, ${eclipse.year} at ${eclipse.time}`;
    };
    
    // Get sign data for a sign name
    const getSignData = (signName) => {
        return signs.find(sign => sign.name === signName);
    };
    
    // Handle change in the selector
    const handleSelectorChange = (e) => {
        setSelectedEclipse(parseInt(e.target.value));
    };
    
    // Create a display name for each eclipse in the selector
    const getEclipseDisplayName = (eclipse, index) => {
        return `${eclipse.modifier} ${eclipse.body} Eclipse - ${eclipse.month} ${eclipse.day}, ${eclipse.year}`;
    };

    // Handle change in the chart selector
    const handleChartSelectorChange = (e) => {
        setSelectedChart(e.target.value);
    };

    // Memoized aspects between moon body and all angles
    const moonAspects = useMemo(() => {
        const aspectTypes = [
            { key: "conjunction", level: "major", label: "Conjunction", angle: 0, orb: 5 },
            { key: "sextile", level: "major", label: "Sextile", angle: 60, orb: 4 },
            { key: "square", level: "major", label: "Square", angle: 90, orb: 5 },
            { key: "trine", level: "major", label: "Trine", angle: 120, orb: 5 },
            { key: "opposition", level: "major", label: "Opposition", angle: 180, orb: 5 }
        ];

        return aspectTypes.map(aspect => ({
            aspectKey: aspect.key,
            aspectLevel: aspect.level,
            aspectLevelLabel: aspect.level.charAt(0).toUpperCase() + aspect.level.slice(1),
            label: aspect.label,
            orb: Math.random(), // Random orb for demonstration
            orbUsed: aspect.orb,
            point2Key: "Sun",
            point2Label: "Moon",
            point2Source: "ECL",
            point1Key: "eastpoint", // Example, could be different for each aspect
            point1Label: "East Point",
            point1Source: 1
        }));
    }, []);

    // Combine the original eclipseAspects with the new moonAspects
    const combinedAspects = useMemo(() => {
        return [...(eclipseAspects || []), ...moonAspects];
    }, [eclipseAspects, moonAspects]);


    const eclChart = useMemo(() => ({
        ...memoizedWheels[selectedChart],
        synastry: {
          bodies: [{
            color: "rgba(153,153,153,1)",
            degree:  eclipseDegree,
            index: "1",
            name: "Moon",
            strokeWidth: 2,
            symbol: "☾"
          }, ...eclipseAspectBodies],
          aspects: combinedAspects,
          angleBodies: [],
          cusps: [],
          asteroids: [],
          metadata: {},
          synastryAspects: combinedAspects
        },
        aspects: combinedAspects,
        selectedAspect: null,
        aspectSearch: [],
        setSelectedAspect: () => {}
      }), [eclipseAspectBodies, combinedAspects])
      

    
    //   console.log('eclipseAspects:', eclipseAspects);
    //   console.log('eclipseAspectBodies:', eclipseAspectBodies);
    //   console.log('moonAspects:', moonAspects);
    //   console.log('sabianSymbols:', sabianSymbols);
      
    return (
        <div className="eclipse-pane" style={{overflowY: 'scroll', maxHeight: height, overflowX: 'hidden'}}>
            
            <div style={{display: 'flex', flexDirection: 'row'}}>
            <div className="eclipse-selector">
                
                <select 
                    id="eclipse-select"
                    value={selectedEclipse}
                    onChange={handleSelectorChange}
                    className="eclipse-dropdown"
                    style={{
                        // flex: 1,
                        padding: '8px',
                        backgroundColor: 'rgba(255,255,255,0.1)',
                        border: '1px solid rgba(255,255,255,0.2)',
                        borderRadius: 8,
                        margin: 8,
                        color: 'white',
                        fontSize: 12}}
                >
                    {eclipse.map((item, index) => (
                        <option key={index} value={index} style={{
                            // flex: 1,
                            padding: '4px',
                            backgroundColor: 'rgba(255,255,255,0.1)',
                            border: '1px solid rgba(255,255,255,0.2)',
                            borderRadius: 4,
                            color: 'white',
                            fontSize: 12}}>
                            {getEclipseDisplayName(item, index).replace(/SOLAR/g, "☉︎").replace(/LUNAR/g, "☾").replace(/Eclipse/g,'')}
                        </option>
                    ))}
                </select>
            </div>

                        
            <div>
                            <select 
                                id="chart-select"
                                value={selectedChart}
                                onChange={handleChartSelectorChange}
                                className="chart-dropdown"
                                style={{
                                    // flex: 1,
                                    padding: '8px',
                                    backgroundColor: 'rgba(255,255,255,0.1)',
                                    border: '1px solid rgba(255,255,255,0.2)',
                                    borderRadius: 8,
                                    margin: 8,
                                    color: 'white',
                                    fontSize: 12}}
                            >
                                <option value={"chart1"} disabled={!memoizedWheels || !memoizedWheels.chart1}>C1</option>
                                <option value={"chart2"} disabled={!memoizedWheels || !memoizedWheels.chart2}>C2</option>
                                <option value={"relocated1"} disabled={!memoizedWheels || !memoizedWheels.relocated1}>R1</option>
                                <option value={"relocated2"} disabled={!memoizedWheels || !memoizedWheels.relocated2}>R2</option>
                                <option value={"davison"} disabled={!memoizedWheels || !memoizedWheels.davison}>DC</option>
                            </select>
                        </div>
                        </div>
                    

            
            {eclipse[selectedEclipse] && (
                <div className="eclipse-details" >

                    <div style={{maxWidth: 420}}>
                        <WheelConfig {...eclChart} chartAspectWeight={1.8} />
                    </div>

                    <div style={{display: 'flex', flexDirection: 'row', }}>
                        <div style={{fontSize: 48, color: eclipse[selectedEclipse].body === 'SOLAR' ? '#ffff00' : '#999999' }}>
                            {eclipse[selectedEclipse].body === 'SOLAR' ? '☉︎' : '☾'} 
                        </div>
                        <div>
                            
                            <div style={{marginTop: 8}}>{formatEclipseDateTime(eclipse[selectedEclipse])} UTC+0 </div>
                            <p>               
                            
                            {/* Render sign with symbol and color */}
                            {(() => {
                                const position = eclipse[selectedEclipse].position;
                                const signName = position.sign;
                                const signData = getSignData(signName);
                                
                                return (
                                    <span style={{ marginLeft: 4 }}>
                                        <span className="symbolText" style={{ 
                                            lineHeight: '10px', 
                                            color: elementColors[signData.element]
                                        }}>
                                            {signData.symbol}
                                        </span>{' '}
                                        {signName} {eclipse[selectedEclipse].position.degree}° {position.minute}'   
                                        <span style={{color: 'black', backgroundColor: '#999999', fontWeight: 900, padding: 4 }}>
                                            {eclipse[selectedEclipse].modifier.toUpperCase()}
                                        </span>
                                    </span>
                                );
                            })()}
                            </p>
                        </div>
                    </div>
                    
                    {/* Display Sabian Symbol for the eclipse degree */}
                    <div style={{maxWidth: 420, marginLeft: -24}}>
                        <Symbol
                            showSabian={true}
                            sabianSymbol={sabianSymbols[eclipseDegree]}
                            pos={{
                                degree: eclipseDegree,
                                body: eclipse[selectedEclipse]?.body === 'SOLAR' ? 'Sun' : 'Moon'
                            }}
                            isExpanded={!expandedSymbols[eclipseDegree]}
                            toggleSymbolExpanded={toggleSymbolExpanded}
                        />
                    </div>
                    
                    {loading && <div>Loading Sabian symbols...</div>}
                    
                    {eclipseAspects && eclipseAspects
                        .sort((a, b) => a.orb < b.orb ? -1 : 1)
                        .map((aspect, i) => {
                            const switchAspect = {...aspect, point1Key: aspect.point2Key, point1Label: aspect.point2Label, point2Key: aspect.point1Key, point2Label: aspect.point1Label, point1Source: aspect.point2Source, point2Source: aspect.point1Source}
                            return <AspectItem aspect={switchAspect} setSelectedAspect={() => {}} key={'aspectecl'+i} aspectType={'transit'} />
                        })}
                    <div style={{minHeight: 64}} />
                </div>
            )}
        
        </div>
    );
};

export default EclipsePane;