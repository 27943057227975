import React from 'react';

/**
 * Component that renders a celestial body with its symbol and optionally its name
 * 
 * @param {Object} props - Component props
 * @param {Object} props.body - Body configuration object
 * @param {string} props.body.name - Name of the celestial body (e.g., "Saturn")
 * @param {string} props.body.index - Index of the body
 * @param {string} props.body.color - Color code for the body (e.g., '#996633')
 * @param {Function} props.body.colorFunc - Function that returns color with alpha (e.g., a => `rgba(150,132,81,${a})`)
 * @param {string} props.body.symbol - Unicode symbol for the body (e.g., '♄')
 * @param {boolean} props.showText - Whether to show the name of the body alongside its symbol
 */
const BodyComponent = ({ body, showText }) => {
    if (!body) return null;

    return (
        <div
            className="symbolText"
            style={{
                fontSize: 12,
                color: body.color || 'rgba(255,255,255,.9)',
                fontFamily: "Noto Sans Symbols",
                lineHeight: 1,
                pointerEvents: 'none',
                backgroundColor: 'rgba(0,0,0,.33)',
                backdropFilter: 'blur(4px)',
                padding: 4,
                borderRadius: 4,
                border: `1px solid ${body.color}`
            }}
        >
            {body.symbol} {showText && body.name}
        </div>
    );
};

export default BodyComponent;