import React from 'react';

/**
 * A reusable toggle switch component
 * 
 * @param {Object} props - Component props
 * @param {boolean} props.isOn - Whether the switch is on
 * @param {Function} props.onToggle - Function to call when the switch is toggled
 * @param {string} props.label - Label text for the switch
 * @returns {JSX.Element} - The toggle switch component
 */
const ToggleSwitch = ({ isOn, onToggle, label }) => (
  <div style={{ 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'space-between',
    marginBottom: 8,
    padding: '4px 8px',
    borderRadius: 4,
    backgroundColor: 'rgba(255,255,255,0.05)'
  }}>
    <span style={{ 
      color: 'rgba(255,255,255,0.8)', 
      fontSize: 12 
    }}>{label}</span>
    <label style={{
      position: 'relative',
      display: 'inline-block',
      width: 36,
      height: 20,
      cursor: 'pointer'
    }}>
      <input
        type="checkbox"
        checked={isOn}
        onChange={onToggle}
        style={{ opacity: 0, width: 0, height: 0 }}
      />
      <span style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: isOn ? 'rgba(255,255,255,0.8)' : 'rgba(255,255,255,0.2)',
        borderRadius: 10,
        transition: '0.4s'
      }}>
        <span style={{
          position: 'absolute',
          content: '""',
          height: 16,
          width: 16,
          left: isOn ? 18 : 2,
          bottom: 2,
          backgroundColor: 'white',
          borderRadius: 50,
          transition: '0.4s'
        }}></span>
      </span>
    </label>
  </div>
);

export default ToggleSwitch;