import React, { memo } from 'react';
import styles from '../ZodiacWheel.module.scss';

/**
 * SynastryCusp component renders a line representing a house cusp in a synastry chart
 */
const SynastryCusp = memo(({ centerX, centerY, degree, radius, outerRadius, index }) => {
  const angle = degree * (Math.PI / 180);
  
  // Calculate position for the house number (6 degrees after the cusp)
  const labelAngle = (degree - 6) * (Math.PI / 180);
  const labelRadius = outerRadius - 12; // Slightly inside the outer circle
  const labelX = centerX + Math.cos(labelAngle) * labelRadius;
  const labelY = centerY + Math.sin(labelAngle) * labelRadius;
  
  return (
    <>
      <line
        className={styles.cuspLine}
        x1={centerX + Math.cos(angle) * radius}
        y1={centerY + Math.sin(angle) * radius}
        x2={centerX + Math.cos(angle) * outerRadius}
        y2={centerY + Math.sin(angle) * outerRadius}
      />
      <text
        className={styles.cuspText}
        x={labelX}
        y={labelY}
        transform={`rotate(${degree + 90}, ${labelX}, ${labelY})`}
      >
        ←{index+1}
      </text>
    </>
  );
});

export default SynastryCusp;