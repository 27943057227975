//src/components/modalsmenus/
import React, { memo } from 'react';
import { useViewData } from '../../contexts/viewContext';

export const OptionButton = memo(({ symbol, isOn, onClick, label, isMobile, type }) => {
  const borderSize = 32
  const buttonStyle = {
    borderRadius: type === 'merged' ? 0 : borderSize,
    borderTopLeftRadius: type === 'first' ? borderSize : type === 'merged' ? 0 : type === 'last' ? 0 : borderSize,
    borderTopRightRadius: type === 'first' ? isMobile ? borderSize : 0 : type === 'merged' ? 0 : type === 'last' ? isMobile ? 0 : borderSize :borderSize,
    borderBottomLeftRadius: type === 'first' ? isMobile ? 0 : borderSize : type === 'merged' ? 0 : type === 'last' ? isMobile ? borderSize : 0 : borderSize,
    borderBottomRightRadius: type === 'first' ? 0 : type === 'merged' ? 0 : type === 'last' ? borderSize : borderSize,
    width: 36,
    maxWidth: 36,
    height: 36,
    display: 'flex',
    flexDirection: isMobile?'row-reverse': 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: isOn ? 'none' : '1px solid rgba(255,255,255,.5)',
    backgroundColor: isOn ? 'white' : 'rgba(0,0,0,.5)',
    color: isOn ? 'black' : 'white',
    cursor: 'pointer',
    margin: 4,
    marginLeft: isMobile ? 4 : type !== undefined ? type === 'first' ? 8 : 0 : 6,
    marginRight: isMobile ? 4 : type !== undefined ? 1 : type === 'last' ? 8 : 6,
    marginTop: (isMobile && type !== undefined) ? type === 'first' ? 8 : 0 : 6,
    marginBottom: (isMobile && type !== undefined) ? 1 : 6,
    pointerEvents: 'auto',
    transition: '.3s all',
    
  };

  return (
    <button className="buttonOption" style={buttonStyle} onClick={onClick}>
      <div  className="symbolText" style={{ fontSize: 18}}>{symbol}</div>
      <div className="buttonHover" style={isMobile?{
        width: 100, marginRight: 16, marginLeft: -116, textAlign: 'right', marginTop: 0, marginBottom: 0, height: 10, pointerEvents: 'none',
      }:{}}>{label}</div>
    </button>
  );
});

const OptionsPane = (props) => {

  const { getOptions, setOptionKey, getIsMobile } = useViewData();
  const options = getOptions();
  const isMobile = getIsMobile();

  const toggles = [
    { key: 'trines', symbol: '△', label: 'Trine', type: 'first', show: {relocate: false} },
    { key: 'sextiles', symbol: '✱', label: 'Sextile' , type: 'merged', show: {relocate: false} },
    { key: 'squares', symbol: '▢', label: 'Square' , type: 'merged', show: {relocate: false} },
    { key: 'parans', symbol: '∩', label: 'Parans (test)' , type: 'last', show: {relocate: false} },
    { key: 'second', symbol: '∬', label: '2nd Person' ,type: options.second ? 'first' : undefined, },
    { key: 'synastry', symbol: '⚘', label: 'Synastry Lines' ,type: 'last', show: {second: true} },
    { key: 'relocate', symbol: '⌖', label: 'Click Relocate' },//✈
    
  ];

  if (!isMobile) toggles.push({ key: 'eclipse', symbol: '◍', label: 'Eclipses' })

  const handleToggle = (key) => {
    setOptionKey(key, !getOptions()[key]);
  };
  const evalToggle = toggles.filter(toggle => {
    if (!toggle.show) return true;
    const key = Object.keys(toggle.show)[0];
    return options[key] === toggle.show[key];
  });

  return (
    <div
      style={{
        width: '100vw',
        position: 'absolute',
        top: 16,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        zIndex: isMobile ? 850 : 1002,
        pointerEvents: 'none',
        // backdropFilter: 'blur(2px)'
      }}
    >
      {isMobile && <div style={{ flex: 1 }} />}
      <div style={{ display: 'flex', flexDirection: isMobile?'column':'row', padding: 8,  marginTop: isMobile ? 32 : 0}}>
        {evalToggle.map(({ key, symbol, label, type }) => (
          <OptionButton
            key={key}
            symbol={symbol}
            isOn={options[key]}
            label={label}
            onClick={() => handleToggle(key)}
            type={type}
            isMobile={isMobile}
          />
        ))}
      </div>
    </div>
  );
};

export default memo(OptionsPane);
