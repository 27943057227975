// src/features/birthdata/ProfileManager.jsx
import React, { useState, useCallback, useRef } from 'react';
import { useUser } from '../../contexts/userContext';
import { useViewData } from '../../contexts/viewContext';
import { getTimezoneFromCoordinates, unixToDate } from '../../utils/formatting/dateTime';
import BirthDataForm from './BirthDataForm';
import './ProfileManager.scss';

/**
 * Custom debounce hook
 * @param {Function} callback - Function to debounce
 * @param {number} delay - Delay in milliseconds
 * @returns {Function} Debounced function
 */
const useDebounce = (callback, delay) => {
  const timeoutRef = useRef(null);
  
  return useCallback((...args) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    
    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  }, [callback, delay]);
};

/**
 * Component for displaying profile list and handling profile loading
 */
const ProfileList = ({
  userData,
  showProfileMenu,
  setShowProfileMenu,
  setReverseChartModal,
  handleLoadProfile,
  handleDeleteProfile,
}) => {
  return (
    <div className="profile-list">
      {userData && (
        <button
          onClick={() => setShowProfileMenu(!showProfileMenu)}
          className="btn btn--dropdown"
        >
          ↓
        </button>
      )}
      {showProfileMenu && userData?.profiles && (
        <div className="profile-list__dropdown">
          <div className="profile-list__controls">
            <button
              onClick={() => {
                setReverseChartModal(true);
                setShowProfileMenu(false);
              }}
              className="btn btn--standard"
            >
              Reverse Chart
            </button>
            <div className="flex-1" />
            <button
              onClick={() => setShowProfileMenu(false)}
              className="btn btn--danger"
            >
              Cancel Load
            </button>
          </div>
          {userData.profiles.map((profile, i) => (
            <div
              key={i}
              onClick={() => handleLoadProfile(profile, i)}
              className="profile-list__item"
            >
              <span>{profile.name}</span>
              <div className="flex-1" />
              {profile.tag && (
                <div className="profile-list__tag">
                  {profile.tag}
                </div>
              )}
              <button
                onClick={(e) => handleDeleteProfile(profile.name, e)}
                className="btn btn--danger"
              >
                ✕
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

/**
 * ProfileManager component for handling profile operations
 * 
 * @param {Object} props - Component props
 * @param {Function} props.onChangeLocation - Callback when location changes
 * @param {string} props.date - Date string in YYYY-MM-DD format
 * @param {Function} props.setDate - Function to update date
 * @param {string} props.time - Time string in HH:MM format
 * @param {string} props.timezone - IANA timezone string
 * @param {Function} props.setTimezone - Function to update timezone
 * @param {Function} props.setTime - Function to update time
 * @param {number} props.unixTimestamp - Unix timestamp
 * @param {Function} props.setUnixTimestamp - Function to update Unix timestamp
 * @param {string} props.placesValue - Default value for the places autocomplete
 * @param {Function} props.resetBirthData - Function to reset birth data
 * @param {number} props.index - Index of the birth data form
 * @param {number} props.latitude - Latitude value
 * @param {number} props.longitude - Longitude value
 * @returns {JSX.Element} ProfileManager component
 */
const ProfileManager = ({ 
  onChangeLocation, 
  date, 
  setDate, 
  time, 
  timezone, 
  setTimezone, 
  setTime, 
  unixTimestamp, 
  setUnixTimestamp, 
  placesValue, 
  resetBirthData, 
  index, 
  latitude, 
  longitude 
}) => {
  const { userData, user, saveProfile, deleteProfile, updateProfile } = useUser();
  const { setReverseChartModal } = useViewData();
  
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [showSaveForm, setShowSaveForm] = useState(false);
  const [profileName, setProfileName] = useState('');
  const [profileData, setProfileData] = useState({});
  const [profileIndex, setProfileIndex] = useState(null);
  const [tagValue, setTagValue] = useState('');
  const [showProfileName, setShowProfileName] = useState(false);

  // Check if current data matches any existing profile
  const currentDataMatchesProfile = userData?.profiles?.some(profile => 
    profile.location.latitude === latitude &&
    profile.location.longitude === longitude &&
    profile.unixTimestamp === unixTimestamp
  );

  // Debounced update function
  const debouncedUpdateProfile = useDebounce((index, data) => {
    updateProfile(index, data);
  }, 500); // 500ms delay

  /**
   * Handle loading a profile
   * @param {Object} profile - Profile data
   * @param {number} index - Profile index
   */
  const handleLoadProfile = (profile, index) => {
    const newTimezone = getTimezoneFromCoordinates(profile.location.latitude, profile.location.longitude);
    const dateObject = unixToDate(profile.unixTimestamp, newTimezone);
    
    // Update birth data
    setTimezone(newTimezone);
    onChangeLocation({
      geometry: {
        location: {
          lat: () => profile.location.latitude,
          lng: () => profile.location.longitude
        }
      }
    });
    
    setDate(dateObject.dateString);
    setTime(dateObject.timeString);
    setUnixTimestamp(profile.unixTimestamp);
    
    // Update profile state
    setProfileName(profile.name);
    setProfileData(profile);
    setProfileIndex(index);
    setTagValue(profile.tag || '');
    setShowProfileName(true);
    setShowProfileMenu(false);
  };

  /**
   * Handle deleting a profile
   * @param {string} profileName - Name of the profile to delete
   * @param {Object} e - Event object
   */
  const handleDeleteProfile = async (profileName, e) => {
    e.stopPropagation(); // Prevent triggering the profile load
    try {
      await deleteProfile(profileName);
      setShowProfileName(false);
    } catch (error) {
      console.error('Error deleting profile:', error);
    }
  };

  /**
   * Handle saving a profile
   */
  const handleSaveProfile = async () => {
    if (!profileName.trim()) return;

    try {
      await saveProfile(
        profileName,
        { latitude, longitude },
        unixTimestamp
      );
      setShowSaveForm(false);
      setShowProfileName(true);
    } catch (error) {
      console.error('Error saving profile:', error);
      setProfileName('');
      setProfileData({});
      setProfileIndex(null);
    }
  };

  /**
   * Handle canceling profile save
   */
  const handleCancelSave = () => {
    setShowSaveForm(false);
    setProfileName('');
    setProfileData({});
    setProfileIndex(null);
  };

  const savedUser = user && currentDataMatchesProfile;
  const unsavedUser = user && !currentDataMatchesProfile;

  // Profile name display
  if (showProfileName) {
    return (
      <div className="profile-manager__profile-name">
        <div className="profile-manager__profile-name-content">
          <div>
            <div className="profile-manager__profile-title">{profileName}</div>
            <div className="profile-manager__tag-container">
              <div className="profile-manager__tag-icon">🏷</div>
              <input
                type="text"
                value={tagValue}
                onChange={(e) => {
                  setTagValue(e.target.value);
                  debouncedUpdateProfile(profileIndex, {tag: e.target.value});
                }}
                placeholder="Add a tag..."
                className="profile-manager__tag-input"
              />
            </div>
          </div>
          <div className="profile-manager__controls">
            <button
              onClick={() => setShowProfileName(false)}
              className="btn btn--standard"
            >
              ✏️
            </button>
            <div className="flex-1" />
            <ProfileList 
              userData={userData}
              showProfileMenu={showProfileMenu}
              setShowProfileMenu={setShowProfileMenu}
              setReverseChartModal={setReverseChartModal}
              handleLoadProfile={handleLoadProfile}
              handleDeleteProfile={handleDeleteProfile}
            />
          </div>
        </div>
      </div>
    );
  }

  // Save profile form
  if (showSaveForm) {
    return (
      <div className="profile-manager__save-form">
        <div className="profile-manager__save-form-content">
          <input
            type="text"
            value={profileName}
            onChange={(e) => setProfileName(e.target.value)}
            placeholder="Profile name"
            className="profile-manager__save-input"
          />
          <button
            onClick={handleSaveProfile}
            className="btn btn--standard"
          >
            ✔
          </button>
          <button
            onClick={handleCancelSave}
            className="btn btn--danger"
          >
            ✕
          </button>
        </div>
      </div>
    );
  }

  // Default view with birth data form
  return (
    <div className="profile-manager__container">
      <BirthDataForm
        onChangeLocation={onChangeLocation}
        date={date}
        setDate={setDate}
        time={time}
        timezone={timezone}
        setTimezone={setTimezone}
        setTime={setTime}
        unixTimestamp={unixTimestamp}
        setUnixTimestamp={setUnixTimestamp}
        placesValue={placesValue}
        latitude={latitude}
        longitude={longitude}
      />

      <div className="profile-manager__controls-column">
        {/* Reset Button */}
        <button 
          onClick={() => resetBirthData(index)}
          className="btn btn--reset"
        >
          ✕
        </button>
        
        {/* Save Button */}
        {unsavedUser && (
          <button
            onClick={() => setShowSaveForm(true)}
            className="btn btn--standard btn--save"
          >
            💾
          </button>
        )}
        
        <div className="flex-1" />
        
        {/* Profile List */}
        <ProfileList 
          userData={userData}
          showProfileMenu={showProfileMenu}
          setShowProfileMenu={setShowProfileMenu}
          setReverseChartModal={setReverseChartModal}
          handleLoadProfile={handleLoadProfile}
          handleDeleteProfile={handleDeleteProfile}
        />
      </div>
    </div>
  );
};

export default ProfileManager;