import { getAngleDifference } from './angleHelpers';
import { getNormalizedBodyKey, ASPECT_TYPES } from '../scripts/aspects';

// Constants
export const DEGREES_PER_SIGN = 30;
export const OFFSET_FOR_SYMBOL = 12;

/**
 * Calculate aspects between two sets of celestial bodies
 * @param {Array} bodies1 - First set of celestial bodies
 * @param {Array} bodies2 - Second set of celestial bodies
 * @param {number} sourceNo1 - Source number for the first set
 * @param {number} sourceNo2 - Source number for the second set
 * @param {boolean} excludeParts - Whether to exclude parts from calculation
 * @returns {Array} - Array of aspects
 */
export const calculateAspectsBetweenSets = (bodies1, bodies2, sourceNo1, sourceNo2, excludeParts = true) => {
  const aspects = [];
  
  // For each body in first set
  bodies1.forEach(body1 => {
    // Compare with each body in second set
    if (!excludeParts || !body1.name.includes('Part')) {
      bodies2.forEach(body2 => {
        // Calculate angle between the bodies
        const angleDiff = getAngleDifference(body1.degree, body2.degree);
  
        // Check each aspect type
        Object.entries(ASPECT_TYPES).forEach(([aspectKey, definition]) => {
          const orb = Math.abs(angleDiff - definition.angle);
          if ((!excludeParts || !body2.name.includes('Part')) && orb <= definition.orb) {
            aspects.push({
              aspectKey,
              aspectLevel: definition.level,
              aspectLevelLabel: definition.level === 'major' ? 'Major' : 'Minor',
              label: aspectKey.charAt(0).toUpperCase() + aspectKey.slice(1),
              orb,
              orbUsed: definition.orb,
              point1Key: getNormalizedBodyKey(body1.name),
              point1Label: body1.name ? body1.name : body1.bodyName,
              point1Source: sourceNo1,
              point2Key: getNormalizedBodyKey(body2.name),
              point2Label: body2.name ? body2.name : body2.bodyName,
              point2Source: sourceNo2,
            });
          }
        });
      });
    }
  });
  return aspects;
};

/**
 * Find clusters of overlapping bodies with proper handling of the 0°/360° boundary
 * @param {Array} sorted - Sorted array of celestial bodies
 * @param {number} minAngle - Minimum angle to consider bodies as clustered
 * @returns {Array} - Array of clusters
 */
export const findClusters = (sorted, minAngle) => {
  if (!sorted.length) return [];
  
  const clusters = [];
  let currentCluster = [sorted[0]];

  for (let i = 1; i < sorted.length; i++) {
    // Calculate the smallest angle difference between consecutive bodies
    const diff = getAngleDifference(sorted[i].degree, sorted[i-1].degree);
    
    if (diff < minAngle) {
      currentCluster.push(sorted[i]);
    } else {
      if (currentCluster.length > 0) {
        clusters.push(currentCluster);
      }
      currentCluster = [sorted[i]];
    }
  }
  
  if (currentCluster.length > 0) {
    clusters.push(currentCluster);
  }

  // Check if the first and last clusters should be combined (wrap around 0°/360°)
  if (clusters.length > 1) {
    const firstCluster = clusters[0];
    const lastCluster = clusters[clusters.length - 1];
    
    // Check if bodies in first and last clusters are within minAngle of each other
    const firstBody = firstCluster[0];
    const lastBody = lastCluster[lastCluster.length - 1];
    
    const wraparoundDiff = getAngleDifference(firstBody.degree, lastBody.degree);
    
    if (wraparoundDiff < minAngle) {
      // Combine last cluster with first cluster
      clusters[0] = [...lastCluster, ...firstCluster];
      clusters.pop();
    }
  }

  return clusters;
};

/**
 * Calculate the average degree of a cluster accounting for 0°/360° boundary
 * @param {Array} cluster - Cluster of celestial bodies
 * @returns {number} - Average degree
 */
export const calculateClusterCenter = (cluster) => {
  // Convert degrees to x,y coordinates on unit circle
  const points = cluster.map(body => ({
    x: Math.cos(body.degree * Math.PI / 180),
    y: Math.sin(body.degree * Math.PI / 180)
  }));
  
  // Calculate average x,y coordinates
  const avgX = points.reduce((sum, p) => sum + p.x, 0) / points.length;
  const avgY = points.reduce((sum, p) => sum + p.y, 0) / points.length;
  
  // Convert back to angle in degrees
  let avgDegree = Math.atan2(avgY, avgX) * 180 / Math.PI;
  if (avgDegree < 0) avgDegree += 360;
  
  return avgDegree;
};

/**
 * Optimize positions for overlapping items with proper boundary handling
 * @param {Array} bodies - Array of celestial bodies
 * @param {number} minAngle - Minimum angle to consider bodies as clustered
 * @returns {Array} - Array of bodies with optimized positions
 */
export const optimizePositions = (bodies, minAngle = 5) => {
  if (!bodies.length) return [];

  const adjustPositions = (bodies) => {
    // Sort bodies by degree for clustering
    const sorted = [...bodies].sort((a, b) => a.degree - b.degree);
    const clusters = findClusters(sorted, minAngle);

    return sorted.map(body => {
      // Find which cluster this body belongs to
      const cluster = clusters.find(c => c.includes(body));
      
      if (!cluster || cluster.length === 1) {
        // If not in a cluster or the only body in a cluster, keep original degree
        return { ...body, displayDegree: body.degree };
      }

      // Calculate cluster center properly accounting for 0°/360° boundary
      const clusterCenter = calculateClusterCenter(cluster);
      
      // Determine position within cluster and calculate offset
      const position = cluster.indexOf(body);
      const offset = (position - (cluster.length - 1) / 2) * minAngle;
      
      // Calculate display degree, keeping it in 0-360 range
      let displayDegree = (clusterCenter + offset) % 360;
      if (displayDegree < 0) displayDegree += 360;
      
      return {
        ...body,
        displayDegree
      };
    });
  };

  let prevResult = bodies;
  let newResult = adjustPositions(bodies);

  // Keep adjusting until no changes occur or max iterations reached
  let iterations = 0;
  const MAX_ITERATIONS = 5;
  
  while (
    iterations < MAX_ITERATIONS &&
    JSON.stringify(prevResult.map(b => b.displayDegree)) !==
    JSON.stringify(newResult.map(b => b.displayDegree))
  ) {
    prevResult = newResult;
    newResult = adjustPositions(newResult);
    iterations++;
  }

  return newResult;
};

/**
 * Calculate various radii for the zodiac wheel
 * @param {number} size - Size of the wheel
 * @param {number} sizeFactor - Size factor for synastry charts
 * @param {number} scaleFactor - Scale factor for responsive sizing
 * @returns {Object} - Object containing various radii calculations
 */
export const calculateRadii = (size, sizeFactor, scaleFactor) => {
  const radius1 = ((size + 0) - OFFSET_FOR_SYMBOL * 2) / 2;
  const radius2 = ((size + 2 * sizeFactor) - OFFSET_FOR_SYMBOL * 2) / 2;
  const radius3 = ((size - sizeFactor * 1.25) - OFFSET_FOR_SYMBOL * 2) / 2;

  const factor = scaleFactor === 1 ? 0 : 4;
  return {
    radius: radius1,
    centerX: radius2 + OFFSET_FOR_SYMBOL,
    centerY: radius2 + OFFSET_FOR_SYMBOL,
    symbolRadius: radius3 - 4,
    centerX_s: radius1 + OFFSET_FOR_SYMBOL - 0 + factor,
    centerY_s: radius1 + OFFSET_FOR_SYMBOL - 0 + factor,
    referenceRadius: (size - OFFSET_FOR_SYMBOL * 2) / 2,
  };
};