import React from 'react';
import SymbolInterpretation from './SymbolInterpretation';
import CollapsibleCard from '../ui/CollapsibleCard';

/**
 * Component for displaying Sabian symbols with expandable details
 * 
 * @param {Object} props - Component props
 * @param {boolean} props.showSabian - Whether to show Sabian symbols
 * @param {Object} props.sabianSymbol - Sabian symbol data
 * @param {Object} props.pos - Position data for the celestial body
 * @param {boolean} props.isExpanded - Whether the symbol details are expanded
 * @param {Function} props.toggleSymbolExpanded - Function to toggle expanded state
 * @returns {JSX.Element|null} - The symbol component or null if not shown
 */
const Symbol = ({ showSabian, sabianSymbol, pos, isExpanded, toggleSymbolExpanded }) => {
    if (!showSabian || !sabianSymbol) {
        return null;
    }

    return (
        <CollapsibleCard
            title={`${pos.body} at ${sabianSymbol.sign} ${sabianSymbol.degree}`}
            isExpanded={isExpanded}
            onToggle={() => toggleSymbolExpanded(pos.degree)}
            style={{
                marginLeft: 24,
                marginBottom: 8
            }}
        >
            {sabianSymbol.interpretations && sabianSymbol.interpretations.map((interp, idx) => (
                <SymbolInterpretation 
                    key={idx} 
                    interp={interp} 
                    sabianSymbol={sabianSymbol} 
                    isExpanded={isExpanded} 
                />
            ))}
        </CollapsibleCard>
    );
};

export default Symbol;