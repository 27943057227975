import React, { useState, useEffect, useMemo } from 'react';
import { getPowerlines, getSynastryPowerlines } from '../../api/trine-backend.api';
import { MarkerDivFromLine } from '../globeComponents';
import { useRelocation } from '../../contexts/relocationPathsContext';
import { useBodyViewSettings } from '../../contexts/bodyViewContext';
import { ASPECT_TYPES } from '../../scripts/aspects';
import { useViewData } from '../../contexts/viewContext';
import { useSummary } from '../../contexts/summaryContext';
import SummaryModal from '../modalsmenus/SummaryModal';

const createPowerlinePatterns = (lines, isLineVisible) => {
  return lines
    .filter(line => !line.name.includes('-Syn'))
    .map(line => {
      let type = line.type?.toLowerCase()?.replace(/s$/, '') || 'conjunct';
      if (type === 'squares') type = 'square';
      
      const body = line.marker?.body?.name || line.bodyName || line.name.split(' ')[0];
      const angle = (line.marker?.lineLabel?.replace(/[^A-Za-z]/g, '') || line.name.split(' ').pop());

      const fixBody = body === 'North Node' ? "Moon's Nodes" : body;
      const fixType = type === 'main' ? 'conjunct' : type;

      if (!isLineVisible(fixBody)) return null;

      return {
        type: fixType,
        bodies: body === 'North Node' ? ["Moon's Nodes"] : [body], 
        angle: angle.toUpperCase(),
        displayKey: `${fixBody} ${fixType} ${angle.toUpperCase()}`
      };
    })
    .filter(Boolean);
};

const createSynPowerlinePatterns = (lines, isLineVisible) => {
  return lines
    .filter(line => line.name.includes('-Syn'))
    .map(line => {
      let type = line.type?.toLowerCase()?.replace(/s$/, '') || 'conjunct';
      if (type === 'squares') type = 'square';
      
      const body = line.marker?.body?.name || line.bodyName || line.name.split(' ')[0];
      const angle = (line.marker?.lineLabel?.replace(/[^A-Za-z]/g, '') || line.name.split(' ').pop());

      const bodyTrim = body.split('-Syn')[0];
      const fixBody = bodyTrim === 'North Node' ? "Moon's Nodes" : bodyTrim;
      const fixType = type === 'main' ? 'conjunct' : type;

      if (!isLineVisible(fixBody)) return null;

      return {
        type: `synastry ${fixType}`,
        bodies: bodyTrim === 'North Node' ? ["Moon's Nodes"] : [bodyTrim], 
        angle: angle.toUpperCase(),
        displayKey: `${fixBody} ${fixType} ${angle.toUpperCase()}`
      };
    })
    .filter(Boolean);
};

const InterpretationHeader = ({ selectedLine, interpretations, setSelectedLine, maxHeight }) => {
  const hasInterpretation = selectedLine?.interpretationKey && interpretations[selectedLine.interpretationKey];
  
  return (
    <div style={{
      position: 'absolute',
      fontSize: 12,
      zIndex: 1,
      color: hasInterpretation ? 'white' : 'rgba(255,255,255,.5)',
      height: hasInterpretation ? 'auto' : maxHeight ? 48 : 64,
      maxHeight: maxHeight ? maxHeight : hasInterpretation ? 360 : 'auto',
      bottom: 0,
      left: 0,
      width: 'calc(100% - 34px)',
      borderTop: '1px solid rgba(255,255,255,.3)',
      padding: 16,
      backgroundColor: 'rgba(0,0,0,.8)',
      backdropFilter: 'blur(2px)',
      transition: 'all 0.5s ease',
    }}>
      
      {selectedLine && <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 8, marginTop: 16}}>
        <MarkerDivFromLine line={selectedLine} globe={false} />
        <div style={{color: selectedLine.color, fontSize: 14, marginLeft: 8 }}>{lineNameWithIcon(selectedLine)}</div>
        <div style={{flex: 1}}></div>
        <button 
              onClick={() => {
                setSelectedLine(null)
              }}
              style={{
                  fontSize: 12,
                  padding: '4px 6px',
                  backgroundColor: 'rgba(255,244,244,0.2)',
                  border: '1px solid #ff4444',
                  borderRadius: 4,
                  color: 'white',
                  cursor: 'pointer'
              }}
          >
              ✕
          </button>
      </div>}
      <div style={{overflowY: 'scroll', maxHeight: 'inherit'}}>
      {hasInterpretation && hasInterpretation.length ? hasInterpretation.map((interpretation, i) => {
        return <div key={`${selectedLine.interpretationKey}-${i}`}>
          <div style={{fontSize: 11, lineHeight: '16px', whiteSpace: interpretation.source === 'trine earth' ? 'pre-wrap' : ''}}>{interpretation.interpretation}</div>
          <div style={{width: '100%', textAlign: 'right', color: 'rgba(255,255,255,.5)', marginTop: 8, fontSize: 9, marginBottom: 8}}>
                excerpt from <span style={{textDecoration: 'underline', cursor: 'pointer', color: 'magenta'}} onClick={() => {
                    window.open(interpretation.sourceUrl, '_blank')
                }}>{interpretation.source}</span> on {new Date(interpretation.createdAt ? interpretation.createdAt._seconds ? interpretation.createdAt._seconds*1000 : interpretation.createdAt : interpretation.createdAt).toDateString()}</div>
        </div>}) : selectedLine ? 'NO INTERPRETATION YET' :
        'SELECT A LINE TO READ THE DESCRIPTION'}
        <div style={{minHeight: (hasInterpretation && hasInterpretation.length>1) ? 64 : 0}} />
        </div>
    </div>
  );
};

const processLineKey = (line) => {
  let type = line.type?.toLowerCase()?.replace(/s$/, '') || 'conjunct';
  if (type === 'squares') type = 'square';
  if (type === 'main') type = 'conjunct';
  
  const body = line.marker?.body?.name || line.bodyName || line.name.split(' ')[0];
  const trimmedForSynBody = body.split('-Syn')[0];
  const angle = line.marker?.lineLabel?.replace(/[^A-Za-z]/g, '') || line.name.split(' ').pop();
  
  return {
    ...line, 
    interpretationKey: `${trimmedForSynBody} ${line.synastryPath ? 'synastry ' : ''}${type} ${angle.toUpperCase()}`,
  }
};

const lineNameWithIcon = line => {
  const lineType = line.type.toLowerCase() === 'main' ? 'conjunction' : line.type.toLowerCase().endsWith('s') ? line.type.toLowerCase().slice(0,-1) : line.type.toLowerCase();
  if (lineType === 'paran' || lineType === 'parans') return line.name;
  const nameTokens = line.name.split(' ');
  
  const nameWithAspectIcon = `${nameTokens[0]}${line.name.includes('Node') ? (' ' + nameTokens[1]) : ''} ${ASPECT_TYPES[lineType].symbol} ${line.name.includes('Node') ? nameTokens[3] : nameTokens[2]}`
  return nameWithAspectIcon
};

export const LineItem = ({ line, hasInterpretation, interpretations, setSelectedLine }) => {
  const { setSelectedSummaryLine, setSelectedInterpretations } = useSummary();
  // const hasInterpretation = selectedLine?.interpretationKey && interpretations[selectedLine.interpretationKey];
  return <div 
    key={`relocUIModal-lineline-${line.name}`}
    style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'white', marginBottom: 4, cursor: 'pointer'}}
    onClick={() => setSelectedLine(line)}
  >
    <div style={{display: 'flex', width: 48, margin: 4, position: 'relative', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', fontSize: 8}}>
      <MarkerDivFromLine line={line} globe={false} />
    </div>
    <div style={{color: hasInterpretation ? 'white' : 'rgba(255,255,255,.5)', fontSize: 12, fontWeight: 300}} className='symbolText'>{lineNameWithIcon(line)}</div>
    <div style={{flex: 1}} />
    <div style={{fontSize: 10, opacity: .5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
      <div>{Math.floor(line.distance_mi * 10) / 10} mi</div>
      <div>{Math.floor(line.distance_km * 10) / 10} km</div>
    </div>
    <div
      style={{
        color: 'rgba(255,255,255,.5)',
        width: 28,
        height: 28,
        display: 'flex',
        justifyContent: 'center',
        borderRadius: 4,
        border: '1px solid rgba(255,255,255,0)',
        alignItems: 'center',
        lineHeight: '16px',
        cursor: 'pointer',
      }}
      className="symbolText"
      onClick={(e) => {
        e.stopPropagation();
        setSelectedSummaryLine(line);
        if (interpretations) setSelectedInterpretations(interpretations);
      }}
    >
      <div>⫘</div>
    </div>
  </div>
}

const LineTypeSection = ({ title, lines, interpretations, setSelectedLine }) => {
  if (!lines.length) return null;
  
  return (
    <div style={{ marginBottom: 16, paddingLeft: 16 }}>
      <div style={{ color: 'rgba(255,255,255,0.6)', fontSize: 13, fontWeight: 400, marginBottom: 8, borderBottom: '1px solid rgba(255,255,255,0.1)', paddingBottom: 4 }}>
        {title}
      </div>
      {lines.map(line => (
        <LineItem 
          key={line.name}
          line={line}
          hasInterpretation={line?.interpretationKey && interpretations[line.interpretationKey] && interpretations[line.interpretationKey].length}
          interpretations={interpretations[line.interpretationKey]}
          setSelectedLine={setSelectedLine}
        />
      ))}
    </div>
  );
};

const LinesSection = ({ title, groupedLines, interpretations, setSelectedLine }) => {
  
  const hasLines = Object.values(groupedLines).some(lines => lines.length > 0);
  if (!hasLines) return null;

  return (
    <div style={{ marginBottom: 24 }}>
      <div style={{ 
        color: 'rgba(255,255,255,0.85)', 
        fontSize: 14, 
        fontWeight: 500, 
        marginBottom: 12,
        borderBottom: '1px solid rgba(255,255,255,0.3)',
        paddingBottom: 6,
        textTransform: 'uppercase',
        letterSpacing: '0.05em'
      }}>
        {title}
      </div>
      <LineTypeSection title="Main Lines" lines={groupedLines.MAIN} interpretations={interpretations} setSelectedLine={setSelectedLine} />
      <LineTypeSection title="Trines" lines={groupedLines.TRINES} interpretations={interpretations} setSelectedLine={setSelectedLine} />
      <LineTypeSection title="Sextiles" lines={groupedLines.SEXTILES} interpretations={interpretations} setSelectedLine={setSelectedLine} />
      <LineTypeSection title="Squares" lines={groupedLines.SQUARES} interpretations={interpretations} setSelectedLine={setSelectedLine} />
      <LineTypeSection title="Parans" lines={groupedLines.PARANS} interpretations={interpretations} setSelectedLine={setSelectedLine} />
    </div>
  );
};

export const LinesList = ({ maxHeight, emptyMessage = "turn on relocation and click a spot" }) => {
  const { relocPaths } = useRelocation();
  const { isLineVisible } = useBodyViewSettings();
  const [interpretations, setInterpretations] = useState({});
  const [selectedLine, setSelectedLine] = useState(null);

  const { getOptions } = useViewData();

  // Sort and process lines in a single memoized operation to avoid multiple array copies
  const processedLines = useMemo(() => {
    if (!relocPaths.length) return [];
    return relocPaths
      .slice() // Create a single copy for sorting
      .sort((a, b) => (a.distance_mi < b.distance_mi ? -1 : 1))
      .map(processLineKey)
      .filter(line => {
        const body = line.marker?.body?.name || line.bodyName || line.name.split(' ')[0];
        const trimmedForSynBody = body.split('-Syn')[0];
        const fixBody = trimmedForSynBody === 'North Node' ? "Moon's Nodes" : trimmedForSynBody;
        return isLineVisible(fixBody);
      });
  }, [relocPaths, isLineVisible]);

  // Group lines by synastry and type
  const { regularLines, synastryLines } = useMemo(() => {
    const regular = {
      MAIN: [],
      TRINES: [],
      SEXTILES: [],
      SQUARES: [],
      PARANS: []
    };
    
    const synastry = {
      MAIN: [],
      TRINES: [],
      SEXTILES: [],
      SQUARES: [],
      PARANS: []
    };
    
    processedLines.forEach(line => {
      const isSynastry = line.name.includes('-Syn') || (!isNaN(line.index) && !isNaN(line.index2) && line.index !== line.index2);
      const targetGroup = isSynastry ? synastry : regular;
      
      const type = line.type?.toUpperCase() || 'MAIN';
      // console.log(line.name, line.type)
      if (type === 'PARANS') {
        targetGroup.PARANS.push(line);
      } else if (type === 'MAIN' || type === 'CONJUNCTION') {
        targetGroup.MAIN.push(line);
      } else if (type.includes('TRINE')) {
        targetGroup.TRINES.push(line);
      } else if (type.includes('SEXTILE')) {
        targetGroup.SEXTILES.push(line);
      } else if (type.includes('SQUARE')) {
        targetGroup.SQUARES.push(line);
      }
    });

    return { regularLines: regular, synastryLines: synastry };
  }, [processedLines]);

  useEffect(() => {
    const fetchPowerlines = async () => {
      // console.log(relocPaths)
      if (!relocPaths.length) return;
      
      try {
        const patterns = createPowerlinePatterns(relocPaths, isLineVisible);
        const synPatterns = createSynPowerlinePatterns(relocPaths, isLineVisible);
        
        if (!patterns.length && !synPatterns.length) return;

        const interpretationsMap = {};
        // console.log(patterns, synPatterns)

        if (patterns.length) {
          const response = await getPowerlines(patterns);
          // console.log(response)
          response?.lines?.forEach(line => {
            const key = `${line.body === "Moon's Nodes" ? "North Node" : line.body} ${line.type} ${line.angle}`;
            if (!interpretationsMap[key]) {
              interpretationsMap[key] = [];
            }
            if (!interpretationsMap[key].some(existing => 
              existing.interpretation === line.interpretation && 
              existing.source === line.source
            )) {
              interpretationsMap[key].push(line);
            }
          });
        }

        if (synPatterns.length) {
          const responseSynastry = await getSynastryPowerlines(synPatterns);
          responseSynastry?.lines?.forEach(line => {
            const key = `${line.body === "Moon's Nodes" ? "North Node" : line.body} ${line.type} ${line.angle}`;
            if (!interpretationsMap[key]) {
              interpretationsMap[key] = [];
            }
            if (!interpretationsMap[key].some(existing => 
              existing.interpretation === line.interpretation && 
              existing.source === line.source
            )) {
              interpretationsMap[key].push(line);
            }
          });
        }
        setInterpretations(interpretationsMap);
      } catch (error) {
        console.log('Error fetching powerlines:', error);
      }
    };

    fetchPowerlines();
  }, [relocPaths, isLineVisible]);

  if (!relocPaths.length) {
    return (
      <div style={{padding: 16, color: 'white', display: 'flex', flexDirection: 'column', overflowY: 'scroll', position: '', maxHeight: 'calc(100% - 40px)', pointerEvents: 'auto'}}>
        {emptyMessage}
      </div>
    );
  }

  return (
    <div style={{padding: 16, display: 'flex', flexDirection: 'column', overflowY: 'scroll', position: '', maxHeight: 'calc(100% - 40px)', pointerEvents: 'auto', width: maxHeight?266 : 'calc(100% - 48px)' }}>
      <InterpretationHeader selectedLine={selectedLine} interpretations={interpretations} setSelectedLine={setSelectedLine} maxHeight={maxHeight} />
      
      <LinesSection title="Lines" groupedLines={regularLines} interpretations={interpretations} setSelectedLine={setSelectedLine} />
      {getOptions().synastry && <LinesSection title="Synastry Lines" groupedLines={synastryLines} interpretations={interpretations} setSelectedLine={setSelectedLine} />}
      
      <div style={{height: 200, minHeight: maxHeight ? 200 : '120px'}} />
    </div>
    
  );
};
