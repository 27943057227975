import React, { memo } from 'react';
import styles from '../ZodiacWheel.module.scss';
import { DEGREES_PER_SIGN } from '../../../utils/zodiacCalculations';
import { ASPECT_TYPES, getNormalizedBodyKey } from '../../../scripts/aspects';


/**
 * AspectLine component renders a line between two celestial bodies representing an aspect
 */
const AspectLine = memo(({ 
  aspect, 
  bodies, 
  centerX, 
  centerY, 
  radius, 
  bodies2 = false, 
  clickHandler, 
  isSelected, 
  selectedAspect, 
  isInSearch, 
  chartAspectWeight = 1 
}) => {
  const point1 = bodies.find(b => getNormalizedBodyKey(b.name.toLowerCase()) === aspect.point1Key);
  const secondBodies = (bodies2 && bodies2.length) ? bodies2 : bodies;
  const point2 = secondBodies.find(b => getNormalizedBodyKey(b.name.toLowerCase()) === aspect.point2Key);

  if (!point1 || !point2) return null;

  const angle1 = (DEGREES_PER_SIGN - point1.degree) * (Math.PI / 180);
  const angle2 = (DEGREES_PER_SIGN - point2.degree) * (Math.PI / 180);

  const x1 = centerX + Math.cos(angle1) * radius;
  const y1 = centerY + Math.sin(angle1) * radius;
  const x2 = centerX + Math.cos(angle2) * radius;
  const y2 = centerY + Math.sin(angle2) * radius;

  const aspectType = ASPECT_TYPES[aspect.aspectKey];
  if (!aspectType) return null;

  return (
    <line 
      className={`aspectLine ${styles.aspectLine}`} 
      onClick={() => {
        console.log('Aspect clicked:', aspect);
        clickHandler(aspect);
      }}
      x1={x1}
      y1={y1}
      x2={x2}
      y2={y2}
      style={{
        cursor: 'pointer',
        pointerEvents: 'auto',
        stroke: isSelected ? 'white' : isInSearch ? 'orange' : selectedAspect ? 'rgba(255,255,255,.2)' : aspectType.color,
        strokeWidth: isSelected ? 5 : isInSearch ? 3 : 1,
        opacity: 1 - (aspect.orb / aspect.orbUsed / chartAspectWeight)
      }}
    />
  );
});

export default AspectLine;