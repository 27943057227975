import { useEffect, useMemo, useState } from "react";
import { getSabianSymbols } from "../../api/trine-backend.api";
import { useBodyViewSettings } from "../../contexts/bodyViewContext"

import symbols from './symbols.json'
import ToggleSwitch from "../ui/ToggleSwitch";


const SymbolLinesSelector = () => {

    const {symbolLineState, setSymbolLine, toggleSymbolLineVisible}  = useBodyViewSettings();
    const [sabianSymbols, setSabianSymbols] = useState([])

    const degreesToFetch = useMemo(() => Object.values(symbolLineState).map(symbol => symbol.degree), [symbolLineState]);

    useEffect(() => {
        const fetchSabianSymbols = async () => {
        if (!degreesToFetch || degreesToFetch.length === 0) return;
        
        // setLoading(true);
        try {
            // Call the API to get Sabian symbols
            const symbols = await getSabianSymbols(degreesToFetch);
            
            // Create a map of degree to symbol
            const symbolMap = {};
            degreesToFetch.forEach((degree, index) => {
            symbolMap[degree] = symbols[index];
            });
            
            setSabianSymbols(symbolMap);
        } catch (error) {
            console.error('Error fetching Sabian symbols:', error);
        } finally {
            // setLoading(false);
        }
        };
        
        fetchSabianSymbols();
    }, [degreesToFetch])


console.log(symbolLineState)
    return <div>
        { Object.keys(symbolLineState).map((symbolNumber, key) => {
            return <div key={'sabianLine'+key} style={{color: 'white', marginBottom: '15px'}}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px'}}>
                    <span>Symbol Line {symbolNumber}</span>
                    <ToggleSwitch
                        isOn={symbolLineState[symbolNumber].visible} 
                        onToggle={() => toggleSymbolLineVisible(symbolNumber)} 
                    />
                </div>
                <select 
                    style={{
                        width: '100%',
                        padding: '8px',
                        backgroundColor: '#333',
                        color: 'white',
                        border: '1px solid #555',
                        borderRadius: '4px'
                    }}
                    value={symbolLineState[symbolNumber].degree}
                    onChange={(e) => setSymbolLine(symbolNumber, parseInt(e.target.value))}
                >
                    <option value="">Select a symbol</option>
                    {symbols.map((symbol) => (
                        <option 
                            key={symbol.degree} 
                            value={symbol.degree}
                        >
                            {symbol.sign} {symbol.signDegree}° - {symbol.symbol.length > 40 
                                ? symbol.symbol.substring(0, 40) + '...' 
                                : symbol.symbol
                            }
                        </option>
                    ))}
                </select>
                
            </div>
        })}
    </div>

}

export default SymbolLinesSelector