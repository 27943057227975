//src/scripts/transits.js
/**
 * Processes natal transit response into a timestamp-keyed object
 * @param {Object} response - The response from calculateNatalTransits endpoint
 * @returns {Object} - Object with timestamps as keys, each containing array of peak transits
 */
export const processNatalTransits = (response) => {
  if (!response) return {};
  
  // Initialize result object to store transits by timestamp
  const transitsByTimestamp = {};
  // Iterate through each natal body in the response
  Object.entries(response).forEach(([natalBody, transitBodies]) => {
    // For each transit body related to this natal body
    Object.entries(transitBodies).forEach(([transitBody, transitData]) => {
      // Get all transit dates for this natal-transit body pair
      const { transitDates, peakDates } = transitData;
      // console.log(peakDates)
      

      peakDates.forEach(({aspectType, orb, transitDegree, natalDegree, date}) => {
        const peakForAspectType = peakDates.find(peak => peak.aspectType === aspectType);
        const transitObj = {
          natalBody,
          transitBody,
          aspectType,
          orb,
          transitDegree,
          natalDegree,
          isPeak: date === peakForAspectType?.date, // Check against peak date for this specific aspect type
          peakDate: date // Store peak date for this aspect type
        };
        
        if (!transitsByTimestamp[date]) {
          transitsByTimestamp[date] = [];
        }
        // Add to array for this timestamp
        transitsByTimestamp[date].push(transitObj);
      })
      // Process each transit date
      // transitDates.forEach(({ date, aspectType, orb, transitDegree, natalDegree }) => {
      //   // Initialize array for this timestamp if it doesn't exist
      //   if (!transitsByTimestamp[date]) {
      //     transitsByTimestamp[date] = [];
      //   }
        
      //   // Find the peak date for this aspect type
      //   const peakForAspectType = peakDates.find(peak => peak.aspectType === aspectType);
        
      //   // Create transit object
      //   const transitObj = {
      //     natalBody,
      //     transitBody,
      //     aspectType,
      //     orb,
      //     transitDegree,
      //     natalDegree,
      //     isPeak: date === peakForAspectType?.date, // Check against peak date for this specific aspect type
      //     peakDate: peakForAspectType?.date // Store peak date for this aspect type
      //   };
        
      //   // Add to array for this timestamp
      //   transitsByTimestamp[date].push(transitObj);
      // });
    });
  });
  // console.log(transitsByTimestamp)
  return transitsByTimestamp;
};

/**
 * Gets peak transits for a specific timestamp
 * @param {Object} transitsByTimestamp - Processed transits object
 * @param {number} timestamp - The timestamp to get peaks for
 * @returns {Array} - Array of peak transits for the timestamp
 */
export const getPeakTransitsForTimestamp = (transitsByTimestamp, timestamp) => {
  // console.log(transitsByTimestamp)
  return transitsByTimestamp[timestamp]?.filter(transit => transit.isPeak) || [];
};

/**
 * Gets all transits for a specific timestamp
 * @param {Object} transitsByTimestamp - Processed transits object
 * @param {number} timestamp - The timestamp to get transits for
 * @returns {Array} - Array of all transits for the timestamp
 */
export const getTransitsForTimestamp = (transitsByTimestamp, timestamp) => {
  return transitsByTimestamp[timestamp] || [];
};

/**
 * Gets all timestamps in the transit data
 * @param {Object} transitsByTimestamp - Processed transits object
 * @returns {Array} - Sorted array of all timestamps
 */
export const getAllTimestamps = (transitsByTimestamp) => {
  return Object.keys(transitsByTimestamp).map(Number).sort((a, b) => a - b);
};

/**
 * Gets the next timestamp that has transits
 * @param {Object} transitsByTimestamp - Processed transits object
 * @param {number} currentTimestamp - The current timestamp
 * @returns {number|null} - Next timestamp with transits or null if none found
 */
export const getNextTransitTimestamp = (transitsByTimestamp, currentTimestamp) => {
  const timestamps = getAllTimestamps(transitsByTimestamp);
  const nextIndex = timestamps.findIndex(ts => ts > currentTimestamp);
  return nextIndex !== -1 ? timestamps[nextIndex] : null;
};

/**
 * Gets the previous timestamp that has transits
 * @param {Object} transitsByTimestamp - Processed transits object
 * @param {number} currentTimestamp - The current timestamp
 * @returns {number|null} - Previous timestamp with transits or null if none found
 */
export const getPreviousTransitTimestamp = (transitsByTimestamp, currentTimestamp) => {
  const timestamps = getAllTimestamps(transitsByTimestamp);
  const prevIndex = timestamps.reverse().findIndex(ts => ts < currentTimestamp);
  return prevIndex !== -1 ? timestamps[prevIndex] : null;
};
