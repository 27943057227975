import React from 'react';
import { elementColors } from '../../../data/zodiacConstants';

/**
 * Component that renders a zodiac sign with its symbol and optionally its name
 * 
 * @param {Object} props - Component props
 * @param {Object} props.sign - Sign configuration object
 * @param {string} props.sign.name - Name of the zodiac sign (e.g., "Aries")
 * @param {string} props.sign.symbol - Unicode symbol for the sign (e.g., '♈︎')
 * @param {string} props.sign.element - Element of the sign (fire, water, air, earth)
 * @param {string} props.sign.ruler - Traditional ruler of the sign
 * @param {string} props.sign.modernRuler - Modern ruler of the sign
 * @param {boolean} props.showText - Whether to show the name of the sign alongside its symbol
 * @param {boolean} props.showRuler - Whether to show the ruler of the sign
 */
const SignComponent = ({ sign, showText, showRuler }) => {
    if (!sign) return null;

    // Get the color based on the sign's element
    const color = elementColors[sign.element] || 'rgba(255,255,255,.9)';

    return (
        <div
            className="symbolText"
            style={{
                fontSize: 12,
                color: color,
                fontFamily: "Noto Sans Symbols",
                lineHeight: 1,
                pointerEvents: 'none',
                backgroundColor: 'rgba(0,0,0,.33)',
                backdropFilter: 'blur(4px)',
                padding: 4,
                borderRadius: 16,
                border: `1px solid ${color}`
            }}
        >
            {sign.symbol} 
            {showText && (
                <span style={{ marginLeft: 4 }}>
                    {sign.name}
                </span>
            )}
        </div>
    );
};

export default SignComponent;