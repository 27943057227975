import { useState, useEffect, useMemo } from 'react';
import { getSabianSymbols } from '../api/trine-backend.api';

/**
 * Custom hook for loading and managing Sabian symbols
 * 
 * @param {Array<number>} degrees - Array of degrees to fetch Sabian symbols for
 * @returns {Object} - Object containing Sabian symbols data, loading state, and error
 */
const useSabianSymbols = (degrees) => {
  const [symbols, setSymbols] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Memoize the degrees array to prevent unnecessary API calls
  const memoizedDegrees = useMemo(() => degrees || [], [degrees ? degrees.join(',') : '']);

  useEffect(() => {
    // Skip if no degrees to fetch
    if (!memoizedDegrees.length) {
      return;
    }

    const fetchSymbols = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // Call the API to get Sabian symbols
        const response = await getSabianSymbols(memoizedDegrees);
        
        // Create a map of degree to symbol
        const symbolMap = {};
        memoizedDegrees.forEach((degree, index) => {
          symbolMap[degree] = response[index];
        });
        
        setSymbols(symbolMap);
      } catch (err) {
        console.error('Error fetching Sabian symbols:', err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    
    fetchSymbols();
  }, [memoizedDegrees]);

  return {
    symbols,
    loading,
    error,
    // Helper function to get a symbol for a specific degree
    getSymbolForDegree: (degree) => symbols[degree] || null
  };
};

export default useSabianSymbols;