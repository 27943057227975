//src/components/modalsmenus/sidebars/Sidebar.jsx

const Sidebar = ({ isOpen, setIsOpen, buttonProps, sidebarProps, children }) => {
 const width = window.innerWidth <= 768 ? '80vw' : (sidebarProps && sidebarProps.width) ? `${sidebarProps.width}px` : '320px';
 
 const buttonShow = buttonProps ? buttonProps.show  : true;
 const top = buttonProps && buttonProps.top ? buttonProps.top : 8;
 const right = buttonProps && buttonProps.right ? buttonProps.right : 8;
 const buttonIcon = buttonProps && buttonProps.icon ? buttonProps.icon : '☷';

 const sidebarBackground = sidebarProps && sidebarProps.background ? sidebarProps.background : 'rgba(0,0,0,0.8)';

 return (
   <>
     {buttonShow && <button
       onClick={() => setIsOpen(!isOpen)}
       style={{
         position: 'fixed',
         top,
         right,
         width: 40,
         height: 40,
         borderRadius: '50%',
         backgroundColor: isOpen ? 'rgba(60,60,60,0.5)' : 'rgba(0,0,0,0.5)',
         border: '1px solid rgba(255,255,255,1)',
         backdropFilter: 'blur(2px)',
         color: 'white',
         zIndex: 1003 + ((sidebarProps && sidebarProps.zMod) ? sidebarProps.zMod : 0),
         cursor: 'pointer',
         fontSize: 20,
       }}
     >
       {isOpen ? '×' : buttonIcon}
     </button>}

     <div
       style={{
         position: 'fixed',
         overflowY: 'hidden',
         overflowX: 'hidden',
         top: 0,
         right: isOpen ? 0 : `-${width}`,
         width,
         height: '100dvh',
         maxHeight: '100dvh',
         backgroundColor: sidebarBackground,
         backdropFilter: 'blur(2px)',
         border: '1px solid rgba(255,255,255,0.3)',
         transition: 'right 0.3s ease',
         zIndex: 1001 + ((sidebarProps && sidebarProps.zMod) ? sidebarProps.zMod : 0),
       }}
     >
      
       {children}
     </div>
   </>
 );
}

export default Sidebar