//src/components/modalsmenus/sidebars/SidebarRelocationTransits.jsx
import { useMemo } from "react";
import { useViewData } from "../../../contexts/viewContext";
import { useWheelConfigs } from '../../../hooks/useWheelConfigs';
import WheelConfig from '../../charts/WheelConfig';
import BodyPositionCard from "../../planetarytables/BodyPositionCard";
import HousePositionCard from "../../planetarytables/HousePositionCard";
import { LinesList } from "../../powerlines/LinesList";
import TransitModule from "../../transits/TransitModule";
import Sidebar from "./Sidebar";
import { useDualBirthData } from "../../../contexts/birthContext";
import { getTimezoneFromCoordinates } from "../../../scripts/helpers";
import AspectLists from "../../aspects/AspectLists";
import SymbolLinesSelector from "../../powerlines/SymbolLinesSelector";
import { ZODIAC_SIGNS } from "../../../data/zodiacConstants";
import { bodyReference } from "../../../scripts/chart";
import BodyComponent from "../../charts/chartComponents/BodyComponent";
import ChartRulerCard from "../../planetarytables/ChartRulerCard";


const ASPECT_TABS = ['C1', 'C2', 'R1', 'R2','SYN',  'RSYN', "DC"]


const buttonStyle = {
  flex: 1,
  padding: '12px',
  backgroundColor: 'transparent',
  border: 'none',
  color: 'white',
  cursor: 'pointer'
};

const activeButtonStyle = {
  ...buttonStyle,
  backgroundColor: 'rgba(255,255,255,0.1)'
};

const SidebarRelocationTransits = ({ selectedAspect, setSelectedAspect, aspectPaneProps }) => {
  const {
    getIsMobile,
    getLineSidebarOpen,
    setLineSidebarOpen,
    getChartTab,
    setChartTab,
    // getActiveTabAspect,
    // setActiveTabAspect,
    getRelocTransitTab,
    setRelocTransitTab,
    getOptions,
    getFloatingModalOpen,
    setFloatingModalOpen,
  } = useViewData();


  const { wheelConfigs, getConfigsArray } = useWheelConfigs({
    selectedAspect,
    setSelectedAspect,
    aspectPaneProps
  });

  const {
    midpoint, birthDataComplete1, birthDataComplete2, midpointDataComplete, setUnixTimestampNo, setCoordinatesNo, setTimezoneNo,
  } = useDualBirthData();

  const renderPositionCards = (config) => {
    if (!config?.bodies) return null;
    
    // console.log(Object.values(ZODIAC_SIGNS).find(config.angleBodies[0]))
    // console.log(Object.values(ZODIAC_SIGNS).find(config.angleBodies[0].sign))
    // console.log(Object.values(ZODIAC_SIGNS).find(config.angleBodies[0].sign)[0].ruler)
    const showWheel = !getFloatingModalOpen(config.title)
    
    
    const cardBodies = [...config.bodies, ...config.angleBodies, ...config.asteroids, ...config.parts];
    return (
      <div style={{marginBottom: '32px', color: 'white'}}>
        <h3 style={{margin: '16px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <div style={{flex: 1}}>{config.title}
          </div>
           <button style={{...buttonStyle, border: '1px solid rgba(255,255,255,.5)', borderRadius: 8, padding: 8, flex: 0}} onClick={() => {
                setFloatingModalOpen(config.title, showWheel)
              }}>{showWheel ? '↴' : '🡔'}</button>
              </h3>
              {showWheel && <WheelConfig {...Object.values(wheelConfigs)[config.number-1]} />}
        <ChartRulerCard processedAspects={aspectPaneProps?.processedAspects} config={config} chartNumber={config.number}/>
        <BodyPositionCard positionData={{
          positions: cardBodies.map(body => ({
            body: body.name,
            sign: Math.floor(body.degree / 30),
            signDegree: body.degree % 30,
            degree: body.degree,
            longitudeSpeed: body.longitudeSpeed || 0
          }))
        }} 
        cusps={config.cusps}
        />
        <HousePositionCard 
          cusps={config.cusps} 
          bodies={cardBodies}  
        />
      </div>
    );
  };

  const wheelCards = useMemo(() => {
    const wheelNumbers = []
    if (birthDataComplete1) wheelNumbers.push(wheelConfigs.chart1)
    if (birthDataComplete1 && getOptions().relocate) wheelNumbers.push(wheelConfigs.relocated1)
    if (birthDataComplete2 && getOptions().second) wheelNumbers.push(wheelConfigs.chart2)
    if (birthDataComplete2 && getOptions().relocate) wheelNumbers.push(wheelConfigs.relocated2)
    if (birthDataComplete1 && birthDataComplete2 && midpointDataComplete && getOptions().second) wheelNumbers.push(wheelConfigs.davison)
    return wheelNumbers;
  }, [getOptions().second, getOptions().relocate, birthDataComplete1, birthDataComplete2, midpointDataComplete, wheelConfigs])

  

  return !getIsMobile() && (
    <Sidebar 
      isOpen={getLineSidebarOpen()} 
      setIsOpen={setLineSidebarOpen} 
      buttonProps={{show: true, icon: '∅', right: 56}} 
      sidebarProps={{background: 'rgba(0,0,0,.5)'}}
    >
      <div style={{color: 'white', width: '100%', padding: 16, textAlign: 'left'}}>&#x25B3;&#xFE0E; trine &#x2727;&#xFE0E; earth &#x2641;&#xFE0E;</div>
      <div style={{
        display: 'flex',
        borderBottom: '1px solid rgba(255,255,255,0.1)',
        backgroundColor: 'rgba(0,0,0,0.8)',
        width: '100%',
        borderRadius: '8px 8px 0 0',
        overflow: 'hidden',
      }}>
        <button
          onClick={() => setRelocTransitTab('charts')}
          style={getRelocTransitTab() === 'charts' ? activeButtonStyle : buttonStyle}
        >
          CHARTS
        </button>
        <button
          onClick={() => setRelocTransitTab('relocation')}
          style={getRelocTransitTab() === 'relocation' ? activeButtonStyle : buttonStyle}
        >
          LINES
        </button>
        <button
          onClick={() => setRelocTransitTab('transits')}
          style={getRelocTransitTab() === 'transits' ? activeButtonStyle : buttonStyle}
        >
          TRANSITS
        </button>
        <button
          onClick={() => setRelocTransitTab('aspects')}
          style={getRelocTransitTab() === 'aspects' ? activeButtonStyle : buttonStyle}
        >
          ASPECTS
        </button>

        <button
          onClick={() => {}}
          style={getRelocTransitTab() === 'none' ? activeButtonStyle : buttonStyle}
        >
        </button>
      </div>

      <div style={{ height: 'calc(100% - 92px)', overflowY: 'hidden' }}>
        {getRelocTransitTab() === 'charts' && (
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '16px',
            overflowY: 'auto',
            overflowX: 'hidden',
            height: '100%'
          }}>
            {getOptions().second && <div style={{marginBottom: '32px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'white'}}>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}><div style={{fontSize: 12, marginRight: 16}}>DAVISON COMPOSITE</div>
              {midpointDataComplete && <button style={{...buttonStyle, border: '1px solid rgba(255,255,255,.5)', borderRadius: 8, padding: 8}} onClick={() => {
                setUnixTimestampNo(midpoint.unixTimestamp, 1)
                setCoordinatesNo(midpoint.coordinates, 1)
                setTimezoneNo(midpoint.timezone, 1)
              }}>Replace P1</button>}</div> 
              <WheelConfig {...wheelConfigs.davison} /> {/* Show Chart 1 wheel */}
            </div>}
            {/* Show position cards for charts 1-4 and 7 */}
            {wheelCards.map((config, index) => renderPositionCards(config))}
            <div style={{minHeight: 64}} />
          </div>
        )}

        {getRelocTransitTab() === 'relocation' && (<>
            <LinesList />
            <SymbolLinesSelector />
          </>
        )}

        {getRelocTransitTab() === 'transits' && (
          // <div style={{
          //   flex: 1,
          //   overflowY: 'visible',
          //   overflowX: 'hidden',
          //   height: 'calc(100% - 48px)',
          //   marginBottom: -108,
          //   backgroundColor: 'transparent',
          // }}>
            <TransitModule heightMinus={90}/>
          // </div>
        )}
        {getRelocTransitTab() === 'aspects' && (<>

          {ASPECT_TABS.map((title, index) => (
                    <button
                      key={index}
                      onClick={() => setChartTab(index)}
                      style={{
                        flex: 1,
                        padding: '12px',
                        backgroundColor: getChartTab() === index ? 'rgba(255,255,255,0.1)' : 'transparent',
                        border: 'none',
                        color: 'white',
                        cursor: 'pointer',
                        fontSize: 11, fontWeight: 800, 
                      }}
                    >
                      {title}
                    </button>
                  ))}
          <div style={{
            flex: 1,
            overflowY: 'hidden',
            height: 'calc(100% - 54px)',
            backgroundColor: 'transparent',
            paddingLeft: 16, paddingRight: 16,
          }}>
            
            <AspectLists
                {...aspectPaneProps}
                showList={ASPECT_TABS[getChartTab()]}
                isMobile={getIsMobile()}
              />
              {/* <div style={{height: 24}}  /> */}
          </div></>
        )}
      </div>
    </Sidebar>
  );
};

export default SidebarRelocationTransits;
