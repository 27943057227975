//src/scripts/helpers.js
import tzlookup from 'tz-lookup';
import { isEqual } from 'lodash';
import { useRef } from 'react';
/**
 * Updates longitude and latitude form fields based on selected city and country
 * Searches through a predefined array of countries/cities to find matching coordinates
 */
export const getLocation = (selectedCity, selectedCountry) => {
    // Get input values from form
    // const selectedCity = $("#city").val();
    // const selectedCountry = $("#country").val();
    
    // Search through countries array for matching location
    for (let i = 0; i < document.countries.length; i++) {
        const location = document.countries[i];
        
        // Check if current location matches selected city and country
        if (location.country === selectedCountry && 
            location.name === selectedCity) {
            
            // Update longitude and latitude fields
            return location
            // $("#longitude").val(location.lng);
            // $("#latitude").val(location.lat);
            //return;
        }
    }
};

export const getCities = (selectedCountry, location) => {
   // Reset location fields
//    $("#longitude").val(0);
//    $("#latitude").val(0);
//    $("#city").val("");
   
   // Get selected country
   //const selectedCountry = $("#country").val();
   
   // Clear and initialize city dropdown with default option
   const cityDropdown = document.getElementById("citylist");
   cityDropdown.innerHTML = ''
   let newOption = document.createElement('option');
    newOption.value = '';
    newOption.textContent = '--Select City--';
    cityDropdown.add(newOption);
   
   // Populate cities for selected country
   document.countries.forEach(location => {
       if (location.country === selectedCountry) {
            let newOption = document.createElement('option');
            newOption.value = '';
            newOption.textContent = location.name;
            cityDropdown.add(newOption);
        //     cityDropdown.append(
        //        `<option>${location.name}</option>`
        //    );
       }
   });
};

export function checkForNaN(arr) {
    const positions = [];
    
    for (let i = 0; i < arr.length; i++) {
        const row = arr[i];
        if (!Array.isArray(row)) {
            throw new Error(`Element at index ${i} is not an array`);
        }
        
        for (let j = 0; j < row.length; j++) {
            if (Number.isNaN(row[j])) {
                positions.push([i, j]);
            }
        }
    }
    
    return {
        hasNaN: positions.length > 0,
        positions
    };
}




export const getTimezoneOffset = (timezone, date) => {
    //console.log(timezone, date)
    try {
      const formatter = new Intl.DateTimeFormat('en-US', {
        timeZone: timezone,
        timeZoneName: 'longOffset',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      });
      
      const parts = formatter.formatToParts(date);
      const timeZonePart = parts.find(part => part.type === 'timeZoneName');
      return timeZonePart ? timeZonePart.value : '';
    } catch (error) {
      console.log('Error getting timezone offset:', error);
      return '';
    }
  };

  



export function getTimezoneFromCoordinates(lat, lng) {
    try {
        
      return tzlookup(lat, lng);
    } catch (error) {
        
      return getDefaultTimezone(lng);
    }
  }
  

const getDefaultTimezone = (longitude) => {
    
 const offset = Math.round(longitude / 15);
 const tz = `Etc/GMT${offset >= 0 ? '-' : '+'}${Math.abs(offset)}`
 return tz;
}




export const dateToUnix = (date, time, timezone) => {
    //console.log("DATE TO UNIX", date, time, timezone)
    try {
        const dateTimeObj = new Date(`${date}T${time}`);
        const tzDate = new Date(dateTimeObj.toLocaleString('en-US', { timeZone: timezone }));
        const offset = dateTimeObj.getTime() - tzDate.getTime();
        
        dateTimeObj.setTime(dateTimeObj.getTime() + offset);
        
        const timestamp = dateTimeObj.getTime();
        
        return timestamp;
    } catch (error) {
        console.error('Error processing date:', error);
        return false;
    }
}

export const unixToDate = (timestamp, timezone) => {
    //console.log('UNIX TO DATE', timestamp, timezone)
    try {
        // Create a date object in the specified timezone
        const dateObj = new Date(timestamp);
        const options = { timeZone: timezone };

        //console.log(timezone, dateObj.toLocaleString('en-US', options))

        // Get date components
        const dateString = dateObj.toLocaleDateString('en-US', {
            ...options,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });

        // Get time components
        const timeString = dateObj.toLocaleTimeString('en-US', {
            ...options,
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        });

        // Convert date string from MM/DD/YYYY to YYYY-MM-DD
        const [month, day, year] = dateString.split('/');
        const formattedDate = `${year}-${month}-${day}`;

        return {
            dateString: formattedDate,
            timeString: timeString
        };
    } catch (error) {
        console.error('Error converting timestamp:', error);
        return false;
    }
}

// Or if you need to handle any IANA timezone:
function getGMTOffset(ianaTimezone) {
    try {
        const date = new Date();
        const utcOffset = date.toLocaleTimeString('en-US', {
            timeZone: ianaTimezone,
            timeZoneName: 'shortOffset'
        }).split(' ').pop();
        return utcOffset
    } catch (error) {
        console.error('Error getting GMT offset:', error);
        return null;
    }
}

export const parseIntSafe = (value, defaultValue) => {
    const parsed = parseInt(value, 10);
    return isNaN(parsed) ? defaultValue : parsed;
  };

  export const parseCoordinates = (lat, lng) => ({
    latitude: lat ? parseFloat(lat) : null,
    longitude: lng ? parseFloat(lng) : null,
  });


  export const parseFloatSafe = (value, defaultValue) => {
    const parsed = parseFloat(value);
    return isNaN(parsed) ? defaultValue : parsed;
  };


export const useMemoWithDeepEqual = (value, deps) => {
    const ref = useRef(value);
    if (!isEqual(value, ref.current)) ref.current = value;
    return ref.current;
}

/**
 * Converts a decimal coordinate to a DMS string with cardinal direction.
 * For example, 39.1 (latitude) becomes "39°06'00.0"N".
 * @param {number} value - The latitude or longitude in decimal degrees.
 * @param {boolean} isLatitude - True if value is a latitude, false if longitude.
 * @returns {string} A string in DMS format.
 */
export function convertDecimalToDMS(value, isLatitude) {
    const absVal = Math.abs(value);
    const degrees = Math.floor(absVal);
    const minutes = Math.floor((absVal - degrees) * 60);
    const seconds = ((absVal - degrees) * 60 - minutes) * 60;
    const secondsFormatted = seconds.toFixed(1);
    const direction = isLatitude
      ? (value >= 0 ? 'N' : 'S')
      : (value >= 0 ? 'E' : 'W');
    // Format so that minutes are always two digits and seconds have one decimal place.
    return `${degrees}°${minutes.toString().padStart(2, '0')}'${secondsFormatted.padStart(4, '0')}"${direction}`;
  }
  
  /**
   * Builds a Google Maps URL using the DMS representation.
   * For example, passing lat=39.1 and lng=-74.466667 will produce:
   * "https://www.google.com/maps/place/39%C2%B006'00.0%22N+74%C2%B028'00.0%22W/"
   * @param {number} lat - The latitude in decimal degrees.
   * @param {number} lng - The longitude in decimal degrees.
   * @returns {string} A URL to view the location in Google Maps.
   */
  export function latLngToGoogleMapsUrl(lat, lng) {
    const latDMS = encodeURIComponent(convertDecimalToDMS(lat, true));
    const lngDMS = encodeURIComponent(convertDecimalToDMS(lng, false));
    return `https://www.google.com/maps/place/${latDMS}+${lngDMS}/`;
  }
  
  // Example usage:
//   const lat = 39.1;         // 39.1° N → 39°06'00.0"N
//   const lng = -74.466667;   // 74.466667° W → 74°28'00.0"W
//   console.log(latLngToGoogleMapsUrl(lat, lng));
  // Output: "https://www.google.com/maps/place/39%C2%B006'00.0%22N+74%C2%B028'00.0%22W/"
  