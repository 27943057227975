import React, { memo } from 'react';
import styles from '../ZodiacWheel.module.scss';

/**
 * ZodiacLine component renders a line representing a zodiac sign boundary
 */
const ZodiacLine = memo(({ centerX, centerY, radius, index }) => {
  const angle = (index * 30) * (Math.PI / 180);
  const x2 = centerX + Math.cos(angle) * (radius + 8);
  const y2 = centerY + Math.sin(angle) * (radius + 8);
  const x3 = centerX + Math.cos(angle) * (radius - 12);
  const y3 = centerY + Math.sin(angle) * (radius - 12);
  
  return (
    <line
      className={styles.zodiacLine}
      x1={x2}
      y1={y2}
      x2={x3}
      y2={y3}
    />
  );
});

export default ZodiacLine;