import React, { memo, useMemo } from 'react';
import styles from '../ZodiacWheel.module.scss';
import { CircleAroundSymbol } from '../../globeComponents';
import { elementColors } from '../../../data/zodiacConstants';




// Format number to have leading zeros
const formatNumber = (num) => num.toString().padStart(2, '0');

// Memoized CelestialBody component
const CelestialBody = memo(({ body, position, displayAngle, radii, centerX, centerY, size, totalRotation }) => {
    const elements = useMemo(() => [
        { content: `${formatNumber(position.minutes)}'`, radius: radii[0], size: 360 * 0.026 },
        { content: position.sign.symbol, radius: radii[1], size: 360 * 0.032, color: elementColors[position.sign.element], math: true },
        { content: `${formatNumber(position.degree)}°`, radius: radii[2], size: 360 * 0.03 },
        { content: <CircleAroundSymbol circle={body.circle} symbol={body.symbol} color={body.color} />, radius: radii[3], size: 360 * 0.044, color: body.color, math: true }
    ], [body, position, radii]);
    
    return elements.map((element, elemIndex) => {
        const x = centerX + Math.cos(displayAngle) * element.radius;
        const y = centerY + Math.sin(displayAngle) * element.radius;
        
        return (
            <div
                className={`${styles.celestialBody} ${element.math ? 'symbolText' : ''}`}
                key={`${body.bodyName}-${element.content}-${elemIndex}`}
                style={{
                    left: `${(x / size) * 100}%`,
                    top: `${(y / size) * 100}%`,
                    transform: `translate(-50%, -50%) rotate(${-totalRotation}deg)`,
                    fontSize: `${element.math ? (body.symbol && body.symbol.length > 1) ? element.size*.8 : element.size : element.size*.8}px`,
                    color: element.color || 'rgba(255,255,255,.9)',
                    fontFamily: element.math ? '"Noto Sans Symbols", sans-serif' : 'inherit',
                    lineHeight: 1,
                    pointerEvents: 'none',
                }}
            >
                {element.content}
            </div>
        );
    });
});

// Add after CelestialBody component
const CelestialBody2 = memo(({ body, position, displayAngle, radii, centerX, centerY, size, totalRotation }) => {
    // Adjust radii to go outward instead of inward
    const bodyOpacity = body.opacity ? body.opacity : 1;
    const elements = useMemo(() => [
        { content: <CircleAroundSymbol circle={body.circle} symbol={body.symbol} color={body.color} />, radius: radii[0], size: 360 * 0.044, color: body.color, math: true },
        { content: `${formatNumber(position.degree)}°`, radius: radii[1], size: 360 * 0.03, opacity: bodyOpacity },
        { content: position.sign.symbol, radius: radii[2], size: 360 * 0.032, color: elementColors[position.sign.element], math: true, opacity: bodyOpacity },
        { content: `${formatNumber(position.minutes)}'`, radius: radii[3], size: 360 * 0.026, opacity: bodyOpacity }
    ], [body, position, radii, bodyOpacity]);

    return elements.map((element, elemIndex) => {
        const x = centerX + Math.cos(displayAngle) * element.radius;
        const y = centerY + Math.sin(displayAngle) * element.radius;
        
        return (
            <div
                className={`${styles.celestialBody} ${element.math ? 'symbolText' : ''}`}
                key={`${body.bodyName}-${element.content}-${elemIndex}`}
                style={{
                    left: `${(x / size) * 100}%`,
                    top: `${(y / size) * 100}%`,
                    transform: `translate(-50%, -50%) rotate(${-totalRotation}deg)`,
                    fontSize: `${(body.symbol && body.symbol.length > 1) ? element.size*.8 : element.size}px`,
                    color: element.color || 'rgba(255,255,255,.9)',
                    opacity: element.opacity || bodyOpacity,
                    fontFamily: element.math ? '"Noto Sans Symbols", sans-serif' : 'inherit',
                    lineHeight: 1,
                    pointerEvents: 'none',
                }}
            >
                {element.content}
            </div>
        );
    });
});

export { CelestialBody, CelestialBody2 };