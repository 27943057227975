//src/contexts/pathsContext.js
import React, { createContext, useContext, useState, useMemo, useEffect } from 'react';

const MARKER_THRESHOLD = 16; // Degrees

const PathsContext = createContext();

const getDistance = (pointLat, pointLng, markerLat, markerLng) => {
  const distance = Math.sqrt(
    (pointLat - markerLat) ** 2 + (pointLng - markerLng) ** 2
  );
  return distance;
}

const getMarkerDistances = (markers, toMarker) =>  markers.map(marker => {
  if ( !isNaN(marker.lat) && !isNaN(marker.lng) ) {
    return getDistance(toMarker.lat, toMarker.lng, marker.lat, marker.lng);
  } else {
    return 100000;
  }
})

const findClosestValidPoint = (markers, points, originalMarker) => {
  let closestPoint = null;
  let closestDistance = Infinity;

  if (!markers.length) return [originalMarker.lat, originalMarker.lng];
  // console.log('markers', originalMarker, markers.map(marker => [marker.lat, marker.lng]));
  const markerDistances = getMarkerDistances(markers, originalMarker);

  // console.log(Math.min(...markerDistances), 'markerDistances', markerDistances);
  if (Math.min(...markerDistances) >= MARKER_THRESHOLD) {
    return [originalMarker.lat, originalMarker.lng];
  } else {

    const pointsByDistanceToOriginalMarker = points.sort((a,b) => {
      return getDistance(a[0], a[1], originalMarker.lat, originalMarker.lng) < getDistance(b[0], b[1], originalMarker.lat, originalMarker.lng) ? -1 : 1;
    });

    const pointsDistances = pointsByDistanceToOriginalMarker.map(point => {
      return Math.min(...getMarkerDistances(markers, {lat: point[0], lng: point[1]}))
    });

    const newPointIndex = pointsDistances.findIndex(dist => dist >= MARKER_THRESHOLD);
    if (newPointIndex !== -1) {
      return pointsByDistanceToOriginalMarker[newPointIndex];
    } else {
      return [originalMarker.lat, originalMarker.lng];
    }

    // for(let i = 0; i < pointsByDistanceToOriginalMarker.length; i++) {
    //   const point = points[i];
      

    //   const distance = getDistance(point[0], point[1], originalMarker.lat, originalMarker.lng);
    //   if (distance < closestDistance) {
    //     closestDistance = distance;
    //     closestPoint = point;
    //   }
    // }
    // const closestMarkerIndex = markerDistances.reduce((minIndex, curr, i) => curr < markerDistances[minIndex] ? i : minIndex, 0);
    // const closestMarker = markers[closestMarkerIndex];
    // const closestPointIndex = points.reduce((minIndex, curr, i) => getDistance(curr[0], curr[1], closestMarker.lat, closestMarker.lng) < getDistance(points[minIndex][0], points[minIndex][1], closestMarker.lat, closestMarker.lng) ? i : minIndex, 0);
    // points = points[closestPointIndex];
    // closestDistance = getDistance(points[0], points[1], closestMarker.lat, closestMarker.lng);
  }

  // const pointDistances = points.map(point => {
  //   return markers.map(marker => {
  //     return getDistance(point[0], point[1], marker.lat, marker.lng);
  //   })
  // })
  // const distancesToOriginalIfCloseEnough = pointDistances.map((distances, i) => {
  //   const closeEnough = distances.every(distance => distance <= MARKER_THRESHOLD);
  //   if (closeEnough) {
  //     return getDistance(points[i][0], points[i][1], originalMarker.lat, originalMarker.lng);
  //   } else return undefined;
  // })
  // const smallestIndex = distancesToOriginalIfCloseEnough.reduce((minIndex, curr, i) => curr !== undefined && (minIndex === -1 || curr < distancesToOriginalIfCloseEnough[minIndex]) ? i : minIndex, -1)
  // if (smallestIndex !== -1) {
  //   closestPoint = points[smallestIndex];
  //   closestDistance = distancesToOriginalIfCloseEnough[smallestIndex];
  // }
  // console.log("closestPoint", closestPoint, closestDistance);
  // return closestPoint;
  
}


export const PathsProvider = ({ children }) => {
  const [allPaths, setAllPaths] = useState([]);
  const [synastryParans, setSynastryParans] = useState([]);
  const [pathMarkers, setPathMarkers] = useState([]);

  // Calculate markers using useMemo to prevent infinite loops
  const pathMarkersRaw = useMemo(() => {
    const markers = [];
    
    // First add all PARANS markers as-is
    allPaths.forEach(path => {
      // if (path.type === 'PARANS' && path.marker) {
        markers.push({...path.marker, points: path.points, pathType: path.type});
      // }
      });
      return markers;
  }, [allPaths]); // Only recalculate when allPaths changes

  // useEffect(() => {
  //   console.log('pathMarkersRaw effect', pathMarkersRaw);
    
  //   const paranMarkers = pathMarkersRaw.filter(marker => marker.pathType === 'PARANS');
  //   const markers = [...paranMarkers]
  //   // console.log('paranMarkers', paranMarkers);
  //   const nonParanMarkers = pathMarkersRaw.filter(marker => marker.pathType !== 'PARANS');

  //   for(let i = 0; i < nonParanMarkers.length; i++) {
  //     const pathMarker = nonParanMarkers[i];

  //     const newPoint = findClosestValidPoint(
  //       markers,
  //       pathMarker.points,
  //       pathMarker,
  //     )
  //     // console.log('newPoint', newPoint);
  //     if (newPoint !== null) {

  //       markers.push({
  //         ...pathMarker,
  //         lat: newPoint[0],
  //         lng: newPoint[1],
  //         // points: undefined,
  //       });
        
  //     }
  //   }
  //   setPathMarkers(markers);
  // }, [pathMarkersRaw])


  const addPaths = (paths, chartIndex) => {
    setAllPaths(currentPaths => {
      const filteredPaths = currentPaths.filter(path => path.index !== chartIndex);
      const newPaths = paths.map(path => ({
        ...path,
        index: chartIndex,
        id: Math.random().toString(36).substr(2, 9)
      }));
      return [...filteredPaths, ...newPaths];
    });
  };

  const getPathsForChart = (chartIndex) => {
    return allPaths.filter(path => path.synastry === chartIndex && !path.synastryPath);
  };

  const clearPaths = (chartIndex) => {
    if (chartIndex !== undefined) {
      setAllPaths(paths => paths.filter(path => path.index !== chartIndex));
    } else {
      setAllPaths(paths => paths.filter(path => path.type === 'PARANS'));
    }
  };

  const value = useMemo(() => ({
    allPaths,
    addPaths,
    getPathsForChart,
    clearPaths,
    synastryParans,
    setSynastryParans,
    pathMarkersRaw,
    pathMarkers,
  }), [allPaths, pathMarkersRaw, pathMarkers, synastryParans]);

  return (
    <PathsContext.Provider value={value}>
      {children}
    </PathsContext.Provider>
  );
};

export const usePaths = () => {
  const context = useContext(PathsContext);
  if (context === undefined) {
    throw new Error('usePaths must be used within a PathsProvider');
  }
  return context;
};
