import React, { memo } from 'react';

import styles from '../ZodiacWheel.module.scss';
import { elementColors } from '../../../data/zodiacConstants';

/**
 * ZodiacSymbol component renders a zodiac sign symbol at the appropriate position
 */
const ZodiacSymbol = memo(({ sign, index, centerX, centerY, symbolRadius, size }) => {
  const angle = (index * 30 + 15) * (Math.PI / 180);
  const x = centerX + Math.cos(angle) * symbolRadius;
  const y = centerY + Math.sin(angle) * symbolRadius;
  const symbolRotation = (index * 30 + 195);

  return (
    <div
      className={`${styles.zodiacSymbol} ${styles[sign.element]} symbolText`}
      style={{
        left: `${(x / size) * 100}%`,
        top: `${(y / size) * 100}%`,
        transform: `translate(-50%, -50%) rotate(${symbolRotation-90}deg)`,
        fontSize: 14,
        color: elementColors[sign.element]
      }}
    >
      {sign.symbol}
    </div>
  );
});

export default ZodiacSymbol;