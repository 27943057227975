import React, { useState } from 'react';

/**
 * A reusable collapsible card component that can be expanded or collapsed
 * 
 * @param {Object} props - Component props
 * @param {string|JSX.Element} props.title - The title to display in the header
 * @param {boolean} props.isExpanded - Whether the card is expanded
 * @param {Function} props.onToggle - Function to call when the card is toggled
 * @param {React.ReactNode} props.children - The content to display when expanded
 * @param {Object} props.style - Additional styles for the card container
 * @param {Object} props.titleStyle - Additional styles for the title
 * @returns {JSX.Element} - The collapsible card component
 */
const CollapsibleCard = ({ 
    title, 
    isExpanded, 
    onToggle, 
    children, 
    style = {}, 
    titleStyle = {} 
}) => {
    // Use internal state if no external control is provided
    const [internalExpanded, setInternalExpanded] = useState(false);
    
    // Determine if component is controlled or uncontrolled
    const expanded = isExpanded !== undefined ? isExpanded : internalExpanded;
    
    // Handle toggle
    const handleToggle = () => {
        if (onToggle) {
            onToggle(!expanded);
        } else {
            setInternalExpanded(!internalExpanded);
        }
    };

    return (
        <div 
            style={{
                marginBottom: 8,
                padding: '4px 8px',
                backgroundColor: 'rgba(255,255,255,0.05)',
                borderRadius: 4,
                cursor: 'pointer',
                ...style
            }} 
        >
            {/* Card Title */}
            <div 
                style={{
                    fontSize: 10,
                    color: 'rgba(255,255,255,.54)',
                    fontWeight: 800,
                    marginBottom: expanded ? 8 : 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                    ...titleStyle
                }}
                onClick={handleToggle}
            >
                 <span>{title} </span> <span>{expanded ? '▲' : "▼"}</span>
            </div>

            {/* Expanded Content */}
            {expanded && (
                <div>
                    {children}
                </div>
            )}
        </div>
    );
};

export default CollapsibleCard;