//src/components/modalsmenus/ModalUI.jsx
import React from 'react';

const ModalUI = ({ 
  isOpen, 
  setIsOpen,
  isMobile,
  children
}) => {
  return (
    <div style={{
      position: 'fixed',
      opacity: isOpen ? 1 : 0,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      pointerEvents: isOpen ? 'auto' : 'none',
      transition: '0.3s all',
      zIndex: 1000,
      display: isOpen ? 'flex' : 'none',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      backgroundColor: isOpen ? 'rgba(0,0,0,0.5)' : 'transparent',
    }}>
      <div style={{
        maxWidth: '800px',
        width: 'calc(100% - 5px)',
        border: '1px solid white',
        maxHeight: '80vh',
        backgroundColor: isMobile ? 'rgba(0,0,0,0.8)' : 'rgba(0,0,0,0.54)',
        borderRadius: 16,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        position: 'relative',
      }}>
        {/* Close Button */}
        <div style={{
          position: 'absolute',
          top: 8,
          right: 8,
          zIndex: 1001,
          color: 'white',
        }}>
          <button
            onClick={() => setIsOpen(false)}
            style={{
              padding: '8px 12px',
              backgroundColor: 'rgba(0,0,0,0.8)',
              border: '1px solid rgba(255,255,255,0.8)',
              borderRadius: '4px',
              color: 'white',
              cursor: 'pointer',
              fontSize: 12,
            }}
          >
            CLOSE
          </button>
        </div>

        {/* Content Area */}
        <div style={{
          flex: 1,
          padding: '16px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          overflow: 'auto',
          marginTop: 48, // Space for the close button
        }}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ModalUI;