//src/components/charts/ZodiacWheel.jsx
import React, { useMemo, memo } from 'react';
import { elementColors, getSignPosition, signs } from '../../data/zodiacConstants';
import { ASPECT_TYPES } from '../../scripts/aspects';

import useViewport from '../../hooks/useViewport';

import { isAspectInSearch } from '../aspects/aspects.helpers';
import { useBodyViewSettings } from '../../contexts/bodyViewContext';
import { CircleAroundSymbol } from '../globeComponents';
import { CelestialBody, CelestialBody2 } from './chartComponents/CelestialBody';

// Import extracted components
import AspectLine from './chartComponents/AspectLine';
import ZodiacLine from './chartComponents/ZodiacLine';
import SynastryCusp from './chartComponents/SynastryCusp';
import ZodiacSymbol from './chartComponents/ZodiacSymbol';
import BodyTick from './chartComponents/BodyTick';

// Import utility functions
import { 
  calculateAspectsBetweenSets, 
  optimizePositions, 
  calculateRadii,
  DEGREES_PER_SIGN,
  OFFSET_FOR_SYMBOL
} from '../../utils/zodiacCalculations';

// Import SCSS module
import styles from './ZodiacWheel.module.scss';

const formatNumber = (num) => num.toString().padStart(2, '0');

// Export the calculateAspectsBetweenSets function for use in other components
export { calculateAspectsBetweenSets };

const ZodiacWheel = memo(({ 
  rotation = 0, 
  size = 600, 
  bodies = [], 
  ascDeg, 
  cusps, 
  aspects, 
  synastry = false, 
  selectedAspect, 
  setSelectedAspect, 
  aspectSearch, 
  asteroids = [], 
  processedAspects, 
  hideUI, 
  chartAspectWeight = 1, 
  name 
}) => {
  const viewport = useViewport();

  const {
    viewState,
    isChartVisible,
    setBodyVisibility,
    toggleBodyVisibility,
  } = useBodyViewSettings();

  // Calculate the scaled size based on viewport
  const getScaledSize = () => {
    const maxWidth = viewport.width - 0;
    const maxHeight = viewport.height - 0;
    const maximumSize = Math.min(maxWidth, maxHeight);
    return size > maximumSize ? maximumSize : size;
  };
  
  const scaledSize = getScaledSize();
  
  const scaleFactor = 1;
  
  const filterBodiesByViewSettings = unfilteredBodies => {
    return unfilteredBodies.filter(body => {
      const visible = isChartVisible(body.name);
      return visible !== false;
    });
  };

  const bodies2 = synastry && synastry.bodies;
  const angleBodies2 = synastry && synastry.angleBodies;
  const synastryAspects = synastry && synastry.synastryAspects;
  const synastryCusps = synastry && synastry.cusps;

  const sizeFactor = synastry ? 60 * scaleFactor : 0;
  
  // Use scaledSize and scaleFactor in all calculations
  const { radius, centerX, centerY, symbolRadius, centerX_s, centerY_s } = useMemo(() => 
    calculateRadii(scaledSize, sizeFactor * scaleFactor, scaleFactor), 
    [scaledSize, sizeFactor, scaleFactor]
  );
  
  const totalRotation = useMemo(() => ((ascDeg ? ascDeg : 0) + 150), [ascDeg]);
  const aspectRadius = useMemo(() => radius - (60 * scaleFactor), [radius, scaleFactor]);

  // Scale radii arrays
  const radii = useMemo(() => {
    const baseRadius = (radius - sizeFactor * 0.65) * 0.7;
    const spacing = (20 - sizeFactor/10) * scaleFactor;
    return [
      baseRadius - spacing * 0.3 - 4 * scaleFactor,
      baseRadius + spacing * 0.3 - 4 * scaleFactor,
      baseRadius + spacing * 0.9 - 4 * scaleFactor,
      baseRadius + spacing * 1.5 - 4 * scaleFactor,
    ];
  }, [radius, sizeFactor, scaleFactor]);
  
  const radii2 = useMemo(() => {
    const baseRadius = (radius - sizeFactor * scaleFactor * 0.85 + 12 * scaleFactor) * 1.05;
    const spacing = (20 - sizeFactor/10) * scaleFactor;
    return [
      baseRadius + spacing * 0.3,
      baseRadius + spacing * 0.9,
      baseRadius + spacing * 1.5,
      baseRadius + spacing * 2.1,
    ];
  }, [radius, sizeFactor, scaleFactor]);

  // Use the optimizePositions utility function
  const optimizedBodies = useMemo(() => 
    optimizePositions(filterBodiesByViewSettings([...bodies, ...asteroids]), 6), 
    [bodies, asteroids, viewState]
  );
  
  const newBodies2 = bodies2 && filterBodiesByViewSettings([...bodies2, ...angleBodies2]);
  const optimizedBodies2 = bodies2 ? optimizePositions(newBodies2) : [];

  const renderAspects = (synastryAspects && synastryAspects.length > 0) ? synastryAspects : aspects;

  if (hideUI) return null;
  
  return (
    <div 
      className={styles.zodiacWheel}
      style={{ 
        width: (scaledSize + sizeFactor * 2 * scaleFactor),
        height: (scaledSize + sizeFactor * 2 * scaleFactor),
        transform: `rotate(${totalRotation}deg)`,
      }}
    >
      <svg 
        viewBox={`${-0} ${-0} ${scaledSize + sizeFactor * 2 * scaleFactor} ${scaledSize + sizeFactor * 2 * scaleFactor}`}
        className="absolute top-0 left-0 w-full h-full"
      >
        {synastry && <circle
          className={`${styles.wheelCircle} ${styles.outer}`}
          cx={centerX}
          cy={centerY}
          r={radius + sizeFactor}
        />}
        <circle
          className={`${styles.wheelCircle} ${styles.main}`}
          cx={centerX}
          cy={centerY}
          r={radius - 1}
          fill={synastry ? "none" : "rgba(0,0,0,.3)"}
        />
        <circle
          className={`${styles.wheelCircle} ${styles.inner}`}
          cx={centerX}
          cy={centerY}
          r={radius - OFFSET_FOR_SYMBOL}
        />
        <circle
          className={`${styles.wheelCircle} ${styles.aspectCircle}`}
          cx={centerX}
          cy={centerY}
          r={radius - 60}
          id="circle3"
        />
        <g>
          {renderAspects?.filter(aspect => 
            ASPECT_TYPES[aspect.aspectKey]
          ).map((aspect, index) => (
            <AspectLine
              key={`${aspect.point1Key}-${aspect.point2Key}-${index}-${name}`}
              aspect={aspect}
              bodies={optimizedBodies}
              selectedAspect={selectedAspect}
              centerX={centerX}
              centerY={centerY}
              radius={aspectRadius}
              bodies2={optimizedBodies2}
              isSelected={selectedAspect ? isAspectInSearch(aspect, [[selectedAspect]]) : false}
              isInSearch={isAspectInSearch(aspect, aspectSearch)}
              clickHandler={aspect => setSelectedAspect(aspect)}
              chartAspectWeight={chartAspectWeight}
            />
          ))}
        </g>
        {signs && signs.map((_, index) => (
          <ZodiacLine
            key={index}
            centerX={centerX}
            centerY={centerY}
            radius={radius}
            index={index}
          />
        ))}
        {cusps && cusps.map((cusp, index) => (
          <SynastryCusp
            key={`chart-cusp-${index}-${name}`}
            centerX={centerX}
            centerY={centerY}
            outerRadius={radius - OFFSET_FOR_SYMBOL}
            radius={radius - 60}
            degree={DEGREES_PER_SIGN - cusp}
            index={index}
          />
        ))}
        {synastryCusps && synastryCusps.map((cusp, index) => (
          <SynastryCusp
            key={`synastry-cusp-${index}-${name}`}
            centerX={centerX}
            centerY={centerY}
            outerRadius={radius + sizeFactor}
            radius={radius + 11}
            degree={DEGREES_PER_SIGN - cusp}
            index={index}
          />
        ))}
        {bodies.map((body) => (
          <BodyTick
            key={`tick-${body.name}-${name}`}
            body={body}
            centerX={centerX}
            centerY={centerY}
            radius={radius - 60}
            size={-4}
            color={body.color}
          />
        ))}
        {newBodies2 && newBodies2.map((body) => (
          <BodyTick
            key={`tick2-${body.name}-${name}`}
            body={body}
            centerX={centerX}
            centerY={centerY}
            radius={radius - 60}
            size={8}
            color={body.color}
          />
        ))}
      </svg>

      {signs.map((sign, index) => (
        <ZodiacSymbol
          key={`${sign.name}-${name}`}
          sign={sign}
          index={index}
          centerX={centerX_s}
          centerY={centerY_s}
          symbolRadius={symbolRadius + 3 * scaledSize / size}
          size={size}
        />
      ))}

      {optimizedBodies2 && optimizedBodies2.map((body) => (
        <CelestialBody2
          key={`outer-${body.name}-${body.bodyName}-${body.degree}-${name}`}
          body={body} 
          position={getSignPosition(body.degree)}
          displayAngle={((DEGREES_PER_SIGN - body.displayDegree) * (Math.PI / 180))}
          radii={radii2}
          centerX={centerX_s}
          centerY={centerY_s}
          size={size}
          totalRotation={totalRotation}
        />
      ))}
      {optimizedBodies.map((body) => (
        <CelestialBody
          key={`inner-${body.name}-${body.bodyName}-${body.degree}-${name}`}
          body={body}
          position={getSignPosition(body.degree)}
          displayAngle={((DEGREES_PER_SIGN - body.displayDegree) * (Math.PI / 180))}
          radii={radii}
          centerX={centerX_s}
          centerY={centerY_s}
          size={size}
          totalRotation={totalRotation}
        />
      ))}
    </div>
  );
});

export default memo(ZodiacWheel);