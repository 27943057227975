//src/components/RelocForm.jsx
import React, { useEffect, useState } from 'react'
import Autocomplete from "react-google-autocomplete";



export const RelocForm = React.memo(( { relocation, setRelocation }) => {
    
    const [relocs, setRelocs] = useState([]);
    const [index, setIndex] = useState([])

    
    //console.log(index)
    useEffect(() => {
        setRelocs(prevState => ([...prevState, relocation]))
    }, [relocation])

    return  <div style={{border: '1px solid white', padding: 4, maxWidth: 240}}>
            <div style={{fontSize: 8}}>RELOCATION - CLICK GLOBE OR SEARCH</div>
            <Autocomplete className="input-style" style={{padding: 8,}}
            apiKey={'AIzaSyAmyqW2CPg2LeXUDSpwaECD2rqHfLi7lyE'} //apiKey={process.env.REACT_APP_GOOGLE_API_KEY}

            
            onPlaceSelected={(place) => {
                
                if (place) {
                    // console.log(place)
                    setRelocation({lat: place.geometry.location.lat(), lng: place.geometry.location.lng()})
                    setIndex(prevState => ([...prevState, place]))
                }
            }}
            />
            <div style={{fontSize: 8}}>{[...relocs].reverse().slice(0,3).map((reloc, i) => {
                const id = index.filter(i => i.geometry.location.lat() === reloc.lat && i.geometry.location.lng() === reloc.lng);
                let name = '';
                if (id.length > 0) name = id[0].formatted_address;
                return <div key={'rel'+i}>{Math.floor(reloc.lat*1000)/1000}, {Math.floor(reloc.lng*1000)/1000} - {name}</div>
            })}</div>
    </div>
})