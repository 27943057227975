import React, {useCallback, useEffect, useState, Suspense, useMemo} from 'react'
import { isEqual } from 'lodash';
import Draggable from 'react-draggable';
import './App.scss';

import GlobeMap from './components/Globe';
import OptionsPane from './components/modalsmenus/OptionsPane';
import { dateToUnix, unixToDate, parseFloatSafe, getTimezoneFromCoordinates, useMemoWithDeepEqual } from './scripts/helpers';
import { calculateAspectsBetweenSets } from './components/charts/ZodiacWheel';
import { findRelatedAspects, hiddenAspects } from './scripts/aspects';

import useSyncWithQueryParams from './hooks/useSyncWithQueryParams';
import FloatingModal from './components/modalsmenus/FloatingModal';
import { RelocForm } from './components/RelocForm';
import { buildChart, calculateAsteroids } from './api/trine-backend.api';
import MobileUIModal from './components/modalsmenus/MobileUIModal';
import { useDualBirthData } from './contexts/birthContext';
import { useViewData } from './contexts/viewContext';
import { useBodiesData } from './contexts/bodiesContext';
import SidebarMain from './components/modalsmenus/sidebars/SidebarMain';
import SidebarRelocationTransits from './components/modalsmenus/sidebars/SidebarRelocationTransits';
// import { useRelocation } from './contexts/relocationPathsContext';
import WheelConfig from './components/charts/WheelConfig';
import { useWheelConfigs } from './hooks/useWheelConfigs';
import SummaryModal from './components/modalsmenus/SummaryModal';
import EclipsePane from './components/eclipse/EclipsePane';

import { useBodyViewSettings } from './contexts/bodyViewContext';

import ModalUI from './components/modalsmenus/ModalUI';
import ReverseChart from './components/modalsmenus/ReverseChart';
import ProfileManager from './features/birthdata/ProfileManager';



function App() {
  const {
    birth1,
    birth2,
    midpoint,
    getTimezoneNo, 
    setTimezoneNo,
    getUnixTimestampNo,
    setUnixTimestampNo,
    getCoordinatesNo,
    setCoordinatesNo,
    setCoordinatesNoValue
   } = useDualBirthData();

   const { timezone, unixTimestamp, coordinates } = birth1;
   const timezone2 = birth2.timezone
   const unixTimestamp2 = birth2.unixTimestamp
   const coordinates2 = birth2.coordinates
  
  const {
    bodies,
    bodies2,
    getBodiesIndexNo,
    setBodiesIndexNo,
    aspects,
    processedAspects,
    getPAspectsNo,
    cusps,
    parts,
    angleBodies,
    setBodiesNo,
    getBodiesNo,
    setAngleBodiesNo,
    getAngleBodiesNo,
    setCuspsNo,
    getCuspsNo,
    setAspectsNo,
    getAspectsNo,
    setPartsNo,
    getPartsNo,
    setAsteroidsNo,
    getAsteroidsNo,
    resetBodiesData,
  } = useBodiesData();

  const [relocation, setRelocation] = useState({})
  

  const {
    getIsMobile,
    setIsMobile,
    getModalOpen,
    setModalOpen,
    getActiveTab,
    setActiveTab,
    getModalOpenAspect,
    setModalOpenAspect,
    getActiveTabAspect,
    setActiveTabAspect,
    getLineSidebarOpen,
    setLineSidebarOpen,
    setRelocTransitTab,
    getOptions,
    setOptionKey,
    getReverseChartModal,
    setReverseChartModal,
  } = useViewData();


  const [synastryAspects, setSynastryAspects] = useState([])
  const [relocSynastryAspects, setRelocSynastryAspects] = useState([])
  const [selectedAspect, setSelectedAspect] = useState(null)
  const [aspectSearch, setAspectSearch] = useState([]);

  const [eclipseAspects, setEclipseAspects] = useState([]);

  const aspectPaneProps = {
    aspects, 
    getAspectsNo, 
    selectedAspect, 
    setSelectedAspect, 
    synastryAspects, 
    relocSynastryAspects, 
    aspectSearch, 
    processedAspects
  };
  // console.log(synastryAspects)

  const { wheelConfigs } = useWheelConfigs({
    selectedAspect,
    setSelectedAspect,
    aspectPaneProps
  });

  // Define state variables to sync with query params
  const stateVars = useMemo(() => {
    return [
      birth1.unixTimestamp,
      birth2.unixTimestamp,
      birth1.timezone,
      birth2.timezone,
      getOptions().trines,
      getOptions().sextiles,
      getOptions().squares,
      getOptions().parans,
      getOptions().second,
      getOptions().synastry,
      getOptions().relocate,
      relocation.lat,
      relocation.lng,
      birth1.coordinates.latitude, birth1.coordinates.longitude, birth2.coordinates.latitude, birth2.coordinates.longitude,
      getModalOpen(), getModalOpenAspect(), getActiveTab(), getActiveTabAspect()
    ]
  }, [unixTimestamp, unixTimestamp2, timezone, timezone2, getOptions(), relocation, coordinates, coordinates2, getModalOpen(), getModalOpenAspect(), getActiveTab(), getActiveTabAspect()]);
  
  const setters = useMemo(() => [
    (ts) => setUnixTimestampNo(Number(ts), 1),
    (ts) => setUnixTimestampNo(Number(ts), 2),
    (tz) => setTimezoneNo(tz, 1),
    (tz) => setTimezoneNo(tz, 2),
    (value) => setOptionKey('trines', value),
    (value) => setOptionKey('sextiles', value),
    (value) => setOptionKey('squares', value),
    (value) => setOptionKey('parans', value),
    (value) => setOptionKey('second', value),
    (value) => setOptionKey('synastry', value),
    (value) => setOptionKey('relocate', value),
    (lat) => setRelocation(prev => ({ ...prev, lat: lat !== null ? parseFloatSafe(lat, null) : null })),
    (lng) => setRelocation(prev => ({ ...prev, lng: lng !== null ? parseFloatSafe(lng, null) : null })),
    (lat) => setCoordinatesNoValue(lat, "latitude", 1),
    (lng) => setCoordinatesNoValue(lng, "longitude", 1),
    (lat) => setCoordinatesNoValue(lat, "latitude", 2),
    (lng) => setCoordinatesNoValue(lng, "longitude", 2),
    setModalOpen,
    setModalOpenAspect,
    setActiveTab,
    setActiveTabAspect
  ], []);

  const queryParamKeys = useMemo(() => [
    'time1',
    'time2',
    'zone',
    'zone2',
    'a3',
    'a6',
    'a4',
    'paran',
    'p2',
    'syn',
    'rel',
    'rlat',
    'rlng',
    'c1lat',
    'c1lng',
    'c2lat',
    'c2lng', 'mch','ma','at','ata'
  ], []);
  
  // Use the custom hook to synchronize state with URL
  useSyncWithQueryParams(stateVars, setters, queryParamKeys, 300);

  const resetBirthData = (n) => {
    setCoordinatesNo({latitude: null, longitude: null}, n)
    setUnixTimestampNo(new Date().getTime(), n)
    resetBodiesData(n)
  }

  const createFormProps = n => ({
    date: unixToDate(getUnixTimestampNo(n), getTimezoneNo(n)).dateString,
    time: unixToDate(getUnixTimestampNo(n), getTimezoneNo(n)).timeString,
    timezone: getTimezoneNo(n), setTimezone: (tz) => setTimezoneNo(tz, n),
    unixTimestamp: getUnixTimestampNo(n),
    setUnixTimestamp: (ts) => setUnixTimestampNo(ts, n),
    setDate: (newDate) => setUnixTimestampNo(dateToUnix(newDate, unixToDate(getUnixTimestampNo(n), getTimezoneNo(n)).timeString, getTimezoneNo(n)), n),
    setTime: (newTime) => setUnixTimestampNo(dateToUnix(unixToDate(getUnixTimestampNo(n), getTimezoneNo(n)).dateString, newTime, getTimezoneNo(n)), n),
    latitude: getCoordinatesNo(n).latitude,
    longitude: getCoordinatesNo(n).longitude,
    setLatitude: (lat) => setCoordinatesNoValue(lat, "latitude", n),
    setLongitude: (lng) => setCoordinatesNoValue(lng, "longitude", n),
    resetBirthData, index: n,
  })

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth <= 768);
  }, []);
  
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useEffect(() => {
    try {
      if (selectedAspect && processedAspects[1] && processedAspects[2] && synastryAspects) {
        const asps = findRelatedAspects(selectedAspect, [
          ...processedAspects[1], 
          ...processedAspects[2], 
          ...synastryAspects,
        ])
        setAspectSearch(asps);
      } else if (!selectedAspect) {
        setAspectSearch([])
      }
    } catch(e) {
      console.log("ERROR IN ASPECTSEARCH", e);
    }
  }, [selectedAspect, processedAspects, synastryAspects]);

  
  

  useEffect(() => {
    // console.log(bodies)
    setSynastryAspects(calculateAspectsBetweenSets([...bodies, ...getAngleBodiesNo(1), ...getAsteroidsNo(1)], [...bodies2, ...getAngleBodiesNo(2), ...getAsteroidsNo(2)], 1, 2));
    setRelocSynastryAspects(calculateAspectsBetweenSets([...bodies, ...getAngleBodiesNo(3)], [...bodies2, ...getAngleBodiesNo(4)], 3, 4));
  }, [bodies, angleBodies, bodies2, relocation])

  const makeChart = async (latitude, longitude, timestamp, tz, n) => {
    const allDataResponse = await buildChart(latitude, longitude, timestamp, tz);
    const { chartBodies, chartCusps, chartAspects, chartAngles, chartParts, chartNonPlanets } = allDataResponse;
    
    const asteroids = await calculateAsteroids(timestamp);
    setAsteroidsNo(asteroids, n);
    
    const astAsps = calculateAspectsBetweenSets([...chartBodies, ...chartAngles, ...asteroids],asteroids, n,n);
    const newBodies = chartBodies.map(body => ({ ...body, strokeWidth: 2 }));
    
    setCuspsNo(chartCusps, n);
    setAngleBodiesNo([...chartAngles], n);
    
    setAspectsNo([...chartAspects, ...astAsps].filter(a => hiddenAspects.indexOf(a.label.toLowerCase()) === -1 && a.point1Key !== "sirius" && a.point2Key !== "sirius"), n);
    setPartsNo(chartParts, n);
    setBodiesNo([...newBodies, ...chartNonPlanets], n);
  }

  useEffect(() => {
    if (!unixTimestamp || !birth1.coordinates.latitude || !birth1.coordinates.longitude || !birth1.timezone || isNaN(birth1.coordinates.latitude) ||isNaN(birth1.coordinates.longitude)) return;
    makeChart(birth1.coordinates.latitude, birth1.coordinates.longitude, birth1.unixTimestamp, birth1.timezone, 1);
  }, [birth1.unixTimestamp, birth1.coordinates.latitude, birth1.coordinates.longitude, birth1.timezone])

  useEffect(() => {
    if (!birth2.unixTimestamp || !birth2.coordinates.latitude || !birth2.coordinates.longitude || !birth2.timezone || isNaN(birth2.coordinates.latitude) ||isNaN(birth2.coordinates.longitude)  ) return;
    makeChart(birth2.coordinates.latitude, birth2.coordinates.longitude, birth2.unixTimestamp, birth2.timezone, 2);
  }, [birth2.unixTimestamp, birth2.coordinates.latitude, birth2.coordinates.longitude, birth2.timezone])

  useEffect(() => {
    if (!midpoint.unixTimestamp || midpoint.coordinates.latitude === null || midpoint.coordinates.longitude === null || !midpoint.timezone || isNaN(midpoint.coordinates.latitude) ||isNaN(midpoint.coordinates.longitude)  ) return;
    makeChart(midpoint.coordinates.latitude, midpoint.coordinates.longitude, midpoint.unixTimestamp, midpoint.timezone, 7);
  }, [midpoint.unixTimestamp, midpoint.coordinates.latitude, midpoint.coordinates.longitude, midpoint.timezone])

  const calculateRelocs = async (timestamp, loc, n) => {
    if (isNaN(loc.lat) || isNaN(loc.lng)) return;
    const tz = await getTimezoneFromCoordinates(loc.lat, loc.lng);
    await makeChart(loc.lat, loc.lng, getUnixTimestampNo(n), tz, n+2);
  }

  const asd = {
    asdf: "a"
  }

  const eclipseBodies = bodies;//useMemo(() => [...bodies, ...getAngleBodiesNo(1), ...getAsteroidsNo(1)], [...bodies2, ...getAngleBodiesNo(2), ...getAsteroidsNo(2)], [bodies, bodies2, getAngleBodiesNo(1), getAngleBodiesNo(2), getAsteroidsNo(1), getAsteroidsNo(2)])

  const {
    isChartVisible,
  } = useBodyViewSettings();

  const eclipseBodiesArray = [
    [...bodies, ...getAngleBodiesNo(1), ...getAsteroidsNo(1), ...getPartsNo(1)],
    [...bodies2, ...getAngleBodiesNo(2), ...getAsteroidsNo(2), ...getPartsNo(2)]
  ]


  const calculateEclipseData = (chartKey, degree, eBodies) => {

    const chartNo = Object.keys(wheelConfigs).indexOf(chartKey)+1
    // console.log(eBodies)
    
    const eclipseMoon = {
      color: "rgba(153,153,153,1)",
      degree,
      index: "1",
      name: "Moon",
      strokeWidth: 2,
      symbol: "☾"
    }

    

    // console.log(eclipseBodies[chartNo], eclipseMoon)
    const filterBodiesByViewSettings = unfilteredBodies => {

      // return unfilteredBodies
      return unfilteredBodies.filter(body => {
          const visible = isChartVisible(body.name)
          return visible !== false
      })
  }
  
    setEclipseAspects(calculateAspectsBetweenSets( eBodies, [eclipseMoon], chartNo,"ECL", false).filter(a => ([...hiddenAspects, 'quincunxa'].indexOf(a.label.toLowerCase()) === -1 && a.point1Key !== "sirius" && a.point2Key !== "sirius")));
  }
  

  useEffect(() => {
    [1,2].map(n => {
      if (getUnixTimestampNo(n) && relocation) calculateRelocs(getUnixTimestampNo(n), relocation, n)  
    })
  }, [ birth1.unixTimestamp,  birth2.unixTimestamp, relocation.lat, relocation.lng])

  const options = getOptions();
  useEffect(() => {
    if (getOptions().relocate && !getLineSidebarOpen()) {
      setRelocTransitTab('relocation')
      setLineSidebarOpen(true)
    }
  }, [getOptions().relocate])

  
  const eclipseProps = {
    wheels: wheelConfigs,
    height: 630,
    eclipseWindow: getOptions().eclipse,
    calculateEclipseData, 
    eclipseAspects,
  }
  const mobileUIModalProps = {
    isOpen: getModalOpen(), 
    setIsOpen: setModalOpen, 
    activeUITab: getActiveTab(), 
    setActiveUITab: setActiveTab, 
    aspectPaneProps, 
    isMobile: getIsMobile(), 
    selectedAspect, 
    setSelectedAspect,
    eclipseProps
  }


const stableBodies = useMemoWithDeepEqual(useMemo(() => {
  const selection1 = getBodiesNo(1).concat(getPartsNo(1)).concat(getAsteroidsNo(1), getAngleBodiesNo(1));
  const selection2 = getBodiesNo(2).concat(getPartsNo(2)).concat(getAsteroidsNo(2), getAngleBodiesNo(2)); 
  return [selection1, selection2];
}, [bodies, bodies2, parts], isEqual))

  return (
    <div className="App">
      <div style={{position: 'relative', overflow: 'hidden'}}>
        <GlobeMap 
          stableBodies={stableBodies}
          onGlobeClick={e => setRelocation(e)} 
          relocation={relocation} 
          setRelocation={setRelocation} 
        />

        <div style={{position: 'absolute', top: 0, left: 0, fontSize: 10, color: 'white', zIndex: 1000}}>
          <div style={{display: 'flex', flexDirection: getIsMobile() ? 'column' : 'row'}}>
          <ProfileManager
            {...createFormProps(1)}
            onChangeLocation={(locationObj, latitude=false, longitude=false) => {
              if (locationObj && locationObj.geometry) {
                const loc = locationObj.geometry.location
                setCoordinatesNo({latitude: loc.lat(), longitude: loc.lng(), lat: loc.lat(), lng: loc.lng()}, 1)
              } else if (!locationObj && latitude !== false && longitude !== false) {
                setCoordinatesNo({latitude, longitude, lat: latitude, lng: longitude}, 1)
              }
            }} 
          />

          {getOptions().second && 
            <ProfileManager 
              {...createFormProps(2)}
              onChangeLocation={(locationObj, latitude=false, longitude=false) => {
                if (locationObj && locationObj.geometry) {
                  const loc = locationObj.geometry.location
                  setCoordinatesNo({latitude: loc.lat(), longitude: loc.lng(), lat: loc.lat(), lng: loc.lng()}, 2)
                } else if (!locationObj && latitude !== false && longitude !== false) {
                  setCoordinatesNo({latitude, longitude, lat: latitude, lng: longitude}, 2)
                }
              }}
            />
          }

          {getOptions().relocate && <RelocForm relocation={relocation} setRelocation={setRelocation} />}
          </div>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <Draggable>
              <div style={{pointerEvents: 'none'}}>
                <Suspense fallback={<div>Loading...</div>}>
                  <FloatingModal size={336} title="Chart 1" hideUI={getIsMobile()}>
                    <WheelConfig {...wheelConfigs.chart1} />
                  </FloatingModal>
                </Suspense>
              </div>
            </Draggable>

            {getOptions().second && !getIsMobile() && 
              <Draggable>
                <div style={{pointerEvents: 'none'}}>
                  <Suspense fallback={<div>Loading...</div>}>
                    <FloatingModal size={336} title="Chart 2" hideUI={getIsMobile()}>
                      <WheelConfig {...wheelConfigs.chart2} />
                    </FloatingModal>
                  </Suspense>
                </div>
              </Draggable>
            }
          </div>
        </div>

        <div style={{position: 'absolute', transition:'.5s all', top: 0, right: (getOptions().relocate && getLineSidebarOpen()) ? 280 : 0, fontSize: 10, color: 'white', zIndex: 800}}>
          {getOptions().second && !getIsMobile() &&  
            <Draggable>
              <div style={{pointerEvents: 'none'}}>
                <Suspense fallback={<div>Loading...</div>}>
                  <FloatingModal title="Synastry 1&2" hideUI={getIsMobile()} size={480}>
                    <div style={{ marginTop: 16, padding: 8, borderRadius: 320 }}>
                      <WheelConfig {...wheelConfigs.synastry} />
                    </div>
                  </FloatingModal>
                </Suspense>
              </div>
            </Draggable>
          }
        </div>

        <div className="relocSynDesk" style={{position: 'absolute', zIndex: 800, transition:'.5s all', bottom: 0, right: (getOptions().relocate && getLineSidebarOpen()) ? 280 : 0, fontSize: 10, color: 'white'}}>
          {getOptions().relocate && relocation && !getIsMobile() && getOptions().second && 
            <Draggable>
              <div style={{pointerEvents: 'none'}}>
                <Suspense fallback={<div>Loading...</div>}>
                  <FloatingModal title="Relocated Synastry 1&2" hideUI={getIsMobile()} size={480}>
                    <div style={{ marginTop: 16, padding: 8, borderRadius: 320 }}>
                      <WheelConfig {...wheelConfigs.relocatedSynastry} />
                    </div>
                  </FloatingModal>
                </Suspense>
              </div>
            </Draggable>
          }
        </div>

        {(getOptions().relocate || getOptions().eclipse) && relocation && !getIsMobile() && 
          <div className="relocationDesk" style={{position: 'absolute', bottom: 0, left: 0, fontSize: 10, color: 'white', pointerEvents: 'none',  zIndex: 800,}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end', pointerEvents: 'none'}}>
              <div style={{pointerEvents: 'none'}}>
                {getOptions().eclipse && !getIsMobile() && 
                  <Draggable>
                    <div style={{pointerEvents: 'none'}}>
                      <Suspense fallback={<div>Loading...</div>}>
                        <FloatingModal title={`eclipses`} hideUI={getIsMobile()} size={630} maxWidth={440} >
                          <EclipsePane {...eclipseProps} />
                        </FloatingModal>
                      </Suspense>
                    </div>
                  </Draggable>
                }
              </div>
              
              <div style={{pointerEvents: 'none'}}>
              {getOptions().relocate && <Draggable>
                  <div style={{pointerEvents: 'none'}}>
                    {!getIsMobile() && 
                      <Suspense fallback={<div>Loading...</div>}>
                        <FloatingModal size={336} title={`Relocated 1`} hideUI={getIsMobile()}>
                          <WheelConfig {...wheelConfigs.relocated1} />
                        </FloatingModal>
                      </Suspense>
                    }
                  </div>
                </Draggable>}
              </div>

              <div style={{pointerEvents: 'none'}}>
                {getOptions().relocate && getOptions().second && !getIsMobile() && 
                  <Draggable>
                    <div style={{pointerEvents: 'none'}}>
                      <Suspense fallback={<div>Loading...</div>}>
                        <FloatingModal size={336} title={`Relocated 2`} hideUI={getIsMobile()}>
                          <WheelConfig {...wheelConfigs.relocated2} />
                        </FloatingModal>
                      </Suspense>
                    </div>
                  </Draggable>
                }
              </div>


            </div>
          </div>
        }

        {/* {!getIsMobile() && 
          <Suspense fallback={<div>Loading...</div>}>
            <AspectModal
              aspectPaneProps={aspectPaneProps}
              isOpen={getModalOpenAspect()}
              setIsOpen={setModalOpenAspect}
              activeTab={getActiveTabAspect()}
              setActiveTab={setActiveTabAspect}
              isMobile={getIsMobile()}
            />
          </Suspense>
        } */}

        {getIsMobile() && 
          <Suspense fallback={<div>Loading...</div>}>
            <MobileUIModal {...mobileUIModalProps} />
          </Suspense>
        }

        <OptionsPane />
        <ModalUI isOpen={getReverseChartModal()} setIsOpen={setReverseChartModal} isMobile={getIsMobile()}>
          <ReverseChart />
        </ModalUI>
        <SidebarRelocationTransits 
          selectedAspect={selectedAspect}
          setSelectedAspect={setSelectedAspect}
          aspectPaneProps={aspectPaneProps}
        />
        <SidebarMain />
        <SummaryModal />
      </div>
    </div>
  );
}

export default App;

