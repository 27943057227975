// src/utils/formatting/dateTime.js
import tzlookup from 'tz-lookup';

/**
 * Gets the timezone offset for a specific timezone and date
 * @param {string} timezone - IANA timezone string
 * @param {Date} date - Date object
 * @returns {string} Timezone offset string
 */
export const getTimezoneOffset = (timezone, date) => {
  try {
    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: timezone,
      timeZoneName: 'longOffset',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    });
    
    const parts = formatter.formatToParts(date);
    const timeZonePart = parts.find(part => part.type === 'timeZoneName');
    return timeZonePart ? timeZonePart.value : '';
  } catch (error) {
    console.log('Error getting timezone offset:', error);
    return '';
  }
};

/**
 * Determines the timezone from geographic coordinates
 * @param {number} lat - Latitude
 * @param {number} lng - Longitude
 * @returns {string} IANA timezone string
 */
export function getTimezoneFromCoordinates(lat, lng) {
  try {
    return tzlookup(lat, lng);
  } catch (error) {
    return getDefaultTimezone(lng);
  }
}

/**
 * Fallback function to get a timezone based on longitude
 * @param {number} longitude - Longitude value
 * @returns {string} Etc/GMT timezone string
 */
export const getDefaultTimezone = (longitude) => {
  const offset = Math.round(longitude / 15);
  const tz = `Etc/GMT${offset >= 0 ? '-' : '+'}${Math.abs(offset)}`;
  return tz;
};

/**
 * Converts date and time strings to a Unix timestamp
 * @param {string} date - Date string in YYYY-MM-DD format
 * @param {string} time - Time string in HH:MM format
 * @param {string} timezone - IANA timezone string
 * @returns {number|boolean} Unix timestamp or false if error
 */
export const dateToUnix = (date, time, timezone) => {
  try {
    const dateTimeObj = new Date(`${date}T${time}`);
    const tzDate = new Date(dateTimeObj.toLocaleString('en-US', { timeZone: timezone }));
    const offset = dateTimeObj.getTime() - tzDate.getTime();
    
    dateTimeObj.setTime(dateTimeObj.getTime() + offset);
    
    const timestamp = dateTimeObj.getTime();
    
    return timestamp;
  } catch (error) {
    console.error('Error processing date:', error);
    return false;
  }
};

/**
 * Converts a Unix timestamp to date and time strings in a specific timezone
 * @param {number} timestamp - Unix timestamp
 * @param {string} timezone - IANA timezone string
 * @returns {Object|boolean} Object with dateString and timeString or false if error
 */
export const unixToDate = (timestamp, timezone) => {
  try {
    // Create a date object in the specified timezone
    const dateObj = new Date(timestamp);
    const options = { timeZone: timezone };

    // Get date components
    const dateString = dateObj.toLocaleDateString('en-US', {
      ...options,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });

    // Get time components
    const timeString = dateObj.toLocaleTimeString('en-US', {
      ...options,
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });

    // Convert date string from MM/DD/YYYY to YYYY-MM-DD
    const [month, day, year] = dateString.split('/');
    const formattedDate = `${year}-${month}-${day}`;

    return {
      dateString: formattedDate,
      timeString: timeString
    };
  } catch (error) {
    console.error('Error converting timestamp:', error);
    return false;
  }
};

/**
 * Gets the GMT offset for an IANA timezone
 * @param {string} ianaTimezone - IANA timezone string
 * @returns {string|null} GMT offset string or null if error
 */
export function getGMTOffset(ianaTimezone) {
  try {
    const date = new Date();
    const utcOffset = date.toLocaleTimeString('en-US', {
      timeZone: ianaTimezone,
      timeZoneName: 'shortOffset'
    }).split(' ').pop();
    return utcOffset;
  } catch (error) {
    console.error('Error getting GMT offset:', error);
    return null;
  }
}

/**
 * Converts a decimal coordinate to a DMS string with cardinal direction
 * @param {number} value - The latitude or longitude in decimal degrees
 * @param {boolean} isLatitude - True if value is a latitude, false if longitude
 * @returns {string} A string in DMS format
 */
export function convertDecimalToDMS(value, isLatitude) {
  const absVal = Math.abs(value);
  const degrees = Math.floor(absVal);
  const minutes = Math.floor((absVal - degrees) * 60);
  const seconds = ((absVal - degrees) * 60 - minutes) * 60;
  const secondsFormatted = seconds.toFixed(1);
  const direction = isLatitude
    ? (value >= 0 ? 'N' : 'S')
    : (value >= 0 ? 'E' : 'W');
  // Format so that minutes are always two digits and seconds have one decimal place
  return `${degrees}°${minutes.toString().padStart(2, '0')}'${secondsFormatted.padStart(4, '0')}"${direction}`;
}

/**
 * Builds a Google Maps URL using the DMS representation
 * @param {number} lat - The latitude in decimal degrees
 * @param {number} lng - The longitude in decimal degrees
 * @returns {string} A URL to view the location in Google Maps
 */
export function latLngToGoogleMapsUrl(lat, lng) {
  const latDMS = encodeURIComponent(convertDecimalToDMS(lat, true));
  const lngDMS = encodeURIComponent(convertDecimalToDMS(lng, false));
  return `https://www.google.com/maps/place/${latDMS}+${lngDMS}/`;
}