//src/contexts/relocationPathsContext.js
import React, { createContext, useContext, useState, useCallback } from 'react';

const RelocationPathsContext = createContext();

export const RelocationPathsProvider = ({ children }) => {
    const [relocPaths, setRelocPaths] = useState([]);

    const updateRelocPaths = useCallback((paths) => {
        // Only update if paths have actually changed
        if (paths && paths.length > 0) {
            setRelocPaths(paths);
        }
    }, []); // Remove unnecessary dependency

    const clearRelocPaths = useCallback(() => {
        setRelocPaths([]);
    }, []);

    return (
        <RelocationPathsContext.Provider value={{
            relocPaths,
            updateRelocPaths,
            clearRelocPaths
        }}>
            {children}
        </RelocationPathsContext.Provider>
    );
};

export const useRelocation = () => {
    const context = useContext(RelocationPathsContext);
    if (!context) {
        throw new Error('useRelocation must be used within a RelocationPathsProvider');
    }
    return context;
};
