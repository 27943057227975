// src/components/forms/LocationInput.jsx
import React, { useState, useEffect } from 'react';
import Autocomplete from "react-google-autocomplete";
import { getTimezoneFromCoordinates, latLngToGoogleMapsUrl } from '../../utils/formatting/dateTime';
import './LocationInput.scss';

/**
 * LocationInput component for handling location selection and coordinate input
 * 
 * @param {Object} props - Component props
 * @param {string} props.placesValue - Default value for the places autocomplete
 * @param {Function} props.onChangeLocation - Callback when location changes
 * @param {number} props.latitude - Current latitude value
 * @param {number} props.longitude - Current longitude value
 * @param {Function} props.setTimezone - Function to update timezone based on coordinates
 * @returns {JSX.Element} LocationInput component
 */
const LocationInput = ({ 
  placesValue, 
  onChangeLocation, 
  latitude, 
  longitude, 
  setTimezone 
}) => {
  const [latInput, setLatInput] = useState(latitude || '');
  const [lngInput, setLngInput] = useState(longitude || '');
  const [error, setError] = useState(null);

  // Update local state when props change
  useEffect(() => {
    setLatInput(latitude || '');
    setLngInput(longitude || '');
  }, [latitude, longitude]);

  /**
   * Validates a coordinate value
   * @param {string|number} value - The coordinate value to validate
   * @param {boolean} isLatitude - Whether this is a latitude (true) or longitude (false)
   * @returns {boolean} Whether the value is valid
   */
  const validateCoordinate = (value, isLatitude) => {
    const num = parseFloat(value);
    if (isNaN(num)) {
      setError(`Invalid coordinate: ${value} is not a number`);
      return false;
    }
    
    if (isLatitude && (num < -90 || num > 90)) {
      setError(`Latitude must be between -90 and 90 degrees`);
      return false;
    }
    
    if (!isLatitude && (num < -180 || num > 180)) {
      setError(`Longitude must be between -180 and 180 degrees`);
      return false;
    }
    
    setError(null);
    return true;
  };

  /**
   * Handles changes to the latitude input
   * @param {Object} e - Event object
   */
  const handleLatChange = (e) => {
    const newLat = e.target.value;
    setLatInput(newLat);
    
    if (newLat === '') return;
    
    if (validateCoordinate(newLat, true)) {
      const parsedLat = parseFloat(newLat);
      onChangeLocation(false, parsedLat, longitude);
      
      // Update timezone if both coordinates are valid
      if (longitude !== '') {
        try {
          const tz = getTimezoneFromCoordinates(parsedLat, parseFloat(longitude));
          setTimezone(tz);
        } catch (err) {
          console.error('Error updating timezone:', err);
        }
      }
    }
  };

  /**
   * Handles changes to the longitude input
   * @param {Object} e - Event object
   */
  const handleLngChange = (e) => {
    const newLng = e.target.value;
    setLngInput(newLng);
    
    if (newLng === '') return;
    
    if (validateCoordinate(newLng, false)) {
      const parsedLng = parseFloat(newLng);
      onChangeLocation(false, latitude, parsedLng);
      
      // Update timezone if both coordinates are valid
      if (latitude !== '') {
        try {
          const tz = getTimezoneFromCoordinates(parseFloat(latitude), parsedLng);
          setTimezone(tz);
        } catch (err) {
          console.error('Error updating timezone:', err);
        }
      }
    }
  };

  /**
   * Handles place selection from Google Autocomplete
   * @param {Object} place - Google Places API place object
   */
  const handlePlaceSelected = (place) => {
    if (place && place.geometry && place.geometry.location) {
      try {
        const tz = getTimezoneFromCoordinates(
          place.geometry.location.lat(), 
          place.geometry.location.lng()
        );
        setTimezone(tz);
        onChangeLocation(place);
        setError(null);
      } catch (err) {
        console.error('Error handling place selection:', err);
        setError('Failed to process location. Please try again or enter coordinates manually.');
      }
    }
  };

  return (
    <div>
      <div className="location-input__container">
        <Autocomplete
          className="location-input__autocomplete input-style"
          apiKey={'AIzaSyAmyqW2CPg2LeXUDSpwaECD2rqHfLi7lyE'}
          defaultValue={placesValue}
          onPlaceSelected={handlePlaceSelected}
        />
        <div className="location-input__coordinates">
          <input
            type="text"
            value={latInput}
            onChange={handleLatChange}
            placeholder="Latitude"
            className="location-input__coordinate input-style"
          />
          
          <input
            type="text"
            value={lngInput}
            onChange={handleLngChange}
            placeholder="Longitude"
            className="location-input__coordinate input-style"
          />
        </div>
        {latitude && longitude && (
          <button
            onClick={() => window.open(latLngToGoogleMapsUrl(latitude, longitude), "_blank")}
            className="location-input__map-button"
          >
            🗺
          </button>
        )}
      </div>
      {error && (
        <div className="location-input__error">
          {error}
        </div>
      )}
    </div>
  );
};

export default React.memo(LocationInput);