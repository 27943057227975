//src/components/modalsmenus/sidebars/SidebarMain.jx
import { useViewData } from "../../../contexts/viewContext";
import { useUser } from "../../../contexts/userContext";
import LineViewSettings from "../LineViewSettings";
import Sidebar from "./Sidebar";

const SidebarMain = () => {
    const {
        getSidebarOpen,
        setSidebarOpen,
    } = useViewData();

    const { user, userData, signInWithGoogle, logout } = useUser();

    const handleAuth = async () => {
        if (user) {
            await logout();
        } else {
            try {
                await signInWithGoogle();
            } catch (error) {
                console.error("Authentication error:", error);
            }
        }
    };

    return <Sidebar isOpen={getSidebarOpen()} setIsOpen={setSidebarOpen} sidebarProps={{width: 280, zMod: 2}}>
        <div style={{overflowY: 'scroll', overflowX: 'hidden', maxHeight: 'inherit'}}>
            <div style={{color: 'white', width: '100%', padding: 16, textAlign: 'left'}}>&#x25B3;&#xFE0E; trine &#x2727;&#xFE0E; earth &#x2641;&#xFE0E;</div>
            <div style={{color: 'white', width: 'calc(100% - 32px)', padding: 16, fontSize: 12, marginBottom: 16, textAlign: 'left'}} className="symbolText2">
                this site is created by a girl over time. 
                if you enjoy it, donations help keep this online! <br /> <br />
                <div style={{display: 'flex', alignItems: 'center', flexDirection: "row", fontSize: 10}}><div style={{minWidth: 48}}>venmo: </div><input style={{color: 'white', backgroundColor: 'transparent', border: '1px solid rgba(255,255,255,.5)', borderRadius: 4}} value={"@a-nyc"} readOnly /></div>
                <div style={{display: 'flex', alignItems: 'center', flexDirection: "row", fontSize: 10}}><div style={{minWidth: 48}}>eth: </div>
                    <input style={{color: 'white', backgroundColor: 'transparent', border: '1px solid rgba(255,255,255,.5)', borderRadius: 4}} value={"0x7478Ea6FD81f68D403e16DAfcAC5989969dbE0E8"} readOnly /></div>
                <div style={{display: 'flex', alignItems: 'center', flexDirection: "row", fontSize: 10}}><div style={{minWidth: 48}}>btc: </div>
                <input style={{color: 'white', backgroundColor: 'transparent', border: '1px solid rgba(255,255,255,.5)', borderRadius: 4}} value={"bc1qfyafdjcshhujnvwqhwpuahsl6kcdlq95zjwym9"} readOnly /></div>
                <br /><br />
                ༺࿊ꕥ( ू •⌄• )ꕥ࿊༻<br />
            </div>
            
            {/* Auth Button */}
            <div style={{padding: '0 16px', marginBottom: 16}}>
                <div style={{color: 'white', fontStyle: 'italic', fontSize: 12, marginBottom: 8, textAlign: 'center' }}>Questions? Bugs? Join the <span style={{color: 'magenta'}} onClick={() => window.open('https://discord.gg/hsXuh3we', '_blank')}>Discord</span> </div>
                {!user && <div style={{color: 'white', fontStyle: 'italic', fontSize: 12, marginBottom: 8, textAlign: 'center' }}>Sign in to save profiles and settings</div>}
                <button 
                    onClick={handleAuth}
                    style={{
                        width: '100%',
                        padding: '8px 16px',
                        backgroundColor: 'rgba(255,255,255,0.1)',
                        border: '1px solid rgba(255,255,255,0.2)',
                        borderRadius: 4,
                        color: 'white',
                        cursor: 'pointer',
                        fontSize: 14,
                        transition: 'all 0.2s ease'
                    }}
                    onMouseOver={(e) => e.target.style.backgroundColor = 'rgba(255,255,255,0.15)'}
                    onMouseOut={(e) => e.target.style.backgroundColor = 'rgba(255,255,255,0.1)'}
                >
                    {user ? 'Sign Out' : 'Sign In with Google'}
                </button>
            </div>

            {/* User Info */}
            {userData && (
                <div style={{
                    padding: '0 16px',
                    marginBottom: 16,
                    color: 'white',
                    fontSize: 12,
                    textAlign: 'center'
                }}>
                    <div>Signed in as: {userData.email}</div>
                    <div style={{fontSize: 10, opacity: 0.7}}>
                        Member since: {new Date(userData.createdAt).toLocaleDateString()}
                    </div>
                </div>
            )}
            
            <LineViewSettings />
        </div>
    </Sidebar>
}

export default SidebarMain;
