// zodiacConstants.js
export const signs = [
    { name: 'Aries', symbol: '\u2648\uFE0E', element: 'fire', startDegree: 0 },
    { name: 'Pisces', symbol: '\u2653\uFE0E', element: 'water', startDegree: 30 },
    { name: 'Aquarius', symbol: '\u2652\uFE0E', element: 'air', startDegree: 60 },
    { name: 'Capricorn', symbol: '\u2651\uFE0E', element: 'earth', startDegree: 90 },
    { name: 'Sagittarius', symbol: '\u2650\uFE0E', element: 'fire', startDegree: 120 },
    { name: 'Scorpio', symbol: '\u264F\uFE0E', element: 'water', startDegree: 150 },
    { name: 'Libra', symbol: '\u264E\uFE0E', element: 'air', startDegree: 180 },
    { name: 'Virgo', symbol: '\u264D\uFE0E', element: 'earth', startDegree: 210 },
    { name: 'Leo', symbol: '\u264C\uFE0E', element: 'fire', startDegree: 240 },
    { name: 'Cancer', symbol: '\u264B\uFE0E', element: 'water', startDegree: 270 },
    { name: 'Gemini', symbol: '\u264A\uFE0E', element: 'air', startDegree: 300 },
    { name: 'Taurus', symbol: '\u2649\uFE0E', element: 'earth', startDegree: 330 }
];

export const ZODIAC_SIGNS = [
    {
        name: 'Aries',
        symbol: '♈︎',
        element: 'fire',
        startDegree: 0,
        ruler: 'Mars',
        modernRuler: 'Mars',
    },
    {
        name: 'Taurus',
        symbol: '♉︎',
        element: 'earth',
        startDegree: 30,
        ruler: 'Venus',
        modernRuler: 'Venus',
    },
    {
        name: 'Gemini',
        symbol: '♊︎',
        element: 'air',
        startDegree: 60,
        ruler: 'Mercury',
        modernRuler: 'Mercury',
    },
    {
        name: 'Cancer',
        symbol: '♋︎',
        element: 'water',
        startDegree: 90,
        ruler: 'Moon',
        modernRuler: 'Moon',
    },
    {
        name: 'Leo',
        symbol: '♌︎',
        element: 'fire',
        startDegree: 120,
        ruler: 'Sun',
        modernRuler: 'Sun',
    },
    {
        name: 'Virgo',
        symbol: '♍︎',
        element: 'earth',
        startDegree: 150,
        ruler: 'Mercury',
        modernRuler: 'Mercury',
    },
    {
        name: 'Libra',
        symbol: '♎︎',
        element: 'air',
        startDegree: 180,
        ruler: 'Venus',
        modernRuler: 'Venus',
    },
    {
        name: 'Scorpio',
        symbol: '♏︎',
        element: 'water',
        startDegree: 210,
        ruler: 'Mars',
        modernRuler: 'Pluto',
    },
    {
        name: 'Sagittarius',
        symbol: '♐︎',
        element: 'fire',
        startDegree: 240,
        ruler: 'Jupiter',
        modernRuler: 'Jupiter',
    },
    {
        name: 'Capricorn',
        symbol: '♑︎',
        element: 'earth',
        startDegree: 270,
        ruler: 'Saturn',
        modernRuler: 'Saturn',
    },
    {
        name: 'Aquarius',
        symbol: '♒︎',
        element: 'air',
        startDegree: 300,
        ruler: 'Saturn',
        modernRuler: 'Uranus',
    },
    {
        name: 'Pisces',
        symbol: '♓︎',
        element: 'water',
        startDegree: 330,
        ruler: 'Jupiter',
        modernRuler: 'Neptune',
    },
];



export const signs_fwd = [
    { name: 'Aries', symbol: '\u2648\uFE0E', element: 'fire', startDegree: 0 },
    { name: 'Taurus', symbol: '\u2649\uFE0E', element: 'earth', startDegree: 330 },
    
    { name: 'Gemini', symbol: '\u264A\uFE0E', element: 'air', startDegree: 300 },
    { name: 'Cancer', symbol: '\u264B\uFE0E', element: 'water', startDegree: 270 },
    { name: 'Leo', symbol: '\u264C\uFE0E', element: 'fire', startDegree: 240 },
    { name: 'Virgo', symbol: '\u264D\uFE0E', element: 'earth', startDegree: 210 },
    { name: 'Libra', symbol: '\u264E\uFE0E', element: 'air', startDegree: 180 },
    { name: 'Scorpio', symbol: '\u264F\uFE0E', element: 'water', startDegree: 150 },
    
    
    
    
    { name: 'Sagittarius', symbol: '\u2650\uFE0E', element: 'fire', startDegree: 120 },
    { name: 'Capricorn', symbol: '\u2651\uFE0E', element: 'earth', startDegree: 90 },
    { name: 'Aquarius', symbol: '\u2652\uFE0E', element: 'air', startDegree: 60 },
    { name: 'Pisces', symbol: '\u2653\uFE0E', element: 'water', startDegree: 30 },
];

export const elementColors = {
    fire: '#FF4136',
    water: '#0074D9',
    air: '#7FDBFF',
    earth: '#FFB700'
};

export const getSignPosition = (absoluteDegree) => {
    const normalizedDegree = (((30-absoluteDegree) % 360) + 360) % 360;
    const signIndex = Math.floor(normalizedDegree / 30);
    const degree = Math.floor((absoluteDegree) % 30);
    const minutes = Math.round(((absoluteDegree) % 1) * 60);
    return {
        sign: signs[signIndex],
        degree,
        minutes: minutes || 0
    };
};

// zodiacConstants.js - add this function to your existing file
export const getZodiacDegree = (signText, degreeWithinSign) => {
    // Validate the degree is within range
    if (degreeWithinSign < 0 || degreeWithinSign >= 30) {
        throw new Error('Degree within sign must be between 0 and 29.99');
    }
    
    // Find the sign in our signs array
    const sign = signs.find(s => s.name.toLowerCase() === signText.toLowerCase());
    
    if (!sign) {
        throw new Error(`Invalid zodiac sign: ${signText}`);
    }
    
    // Since the startDegree in your array doesn't match the actual logic,
    // we need to determine the correct degree based on the sign's position
    // in the array rather than using its startDegree property
    
    // Hard-code the mapping based on the result you expect
    const mappings = {
        'Aries': 0,    // 0-29.99
        'Taurus': 30,  // 30-59.99
        'Gemini': 60,  // 60-89.99
        'Cancer': 90,  // 90-119.99
        'Leo': 120,    // 120-149.99
        'Virgo': 150,  // 150-179.99
        'Libra': 180,  // 180-209.99
        'Scorpio': 210,// 210-239.99
        'Sagittarius': 240, // 240-269.99
        'Capricorn': 270,   // 270-299.99
        'Aquarius': 300,    // 300-329.99
        'Pisces': 330       // 330-359.99
    };
    
    const baseDegree = mappings[sign.name];
    if (baseDegree === undefined) {
        throw new Error(`Sign ${sign.name} not found in mappings`);
    }
    
    return baseDegree + degreeWithinSign;
};