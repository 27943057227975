/**
 * Utility functions for planetary calculations and astrological data processing
 */

/**
 * Truncates a number to 5 decimal places (without rounding)
 * @param {number} num - The number to truncate
 * @returns {number} - The truncated number
 */
export const truncateTo5 = num => Math.trunc(num * 1e5) / 1e5;

/**
 * Determines which house a degree falls into based on house cusps
 * @param {number} degree - The degree to check (0-360)
 * @param {Array<number>} cusps - Array of house cusp degrees
 * @returns {number} - The house number (1-12)
 */
export const getHouseForDegree = (degree, cusps) => {
  // Truncate the input degree as well
  const truncatedDegree = truncateTo5(degree);

  for (let i = 0; i < cusps.length; i++) {
    const nextIndex = (i + 1) % cusps.length;
    // Truncate start and end to 5 decimals
    const start = truncateTo5(cusps[i]);
    const end = truncateTo5(cusps[nextIndex]);
    
    if (end > start) {
      if (truncatedDegree >= start && truncatedDegree < end) {
        return i + 1;
      }
    } else {
      // Handle the case where the house spans 0°
      if (truncatedDegree >= start || truncatedDegree < end) {
        return i + 1;
      }
    }
  }
  return 1; // Default to first house if not found
};

/**
 * Fetches Sabian symbols for a list of degrees
 * @param {Array<number>} degrees - Array of degrees to fetch symbols for
 * @param {Function} getSabianSymbols - API function to fetch Sabian symbols
 * @returns {Object} - Map of degree to symbol
 */
export const fetchSabianSymbols = async (degrees, getSabianSymbols) => {
  if (!degrees || degrees.length === 0) return {};
  
  try {
    // Call the API to get Sabian symbols
    const symbols = await getSabianSymbols(degrees);
    
    // Create a map of degree to symbol
    const symbolMap = {};
    degrees.forEach((degree, index) => {
      symbolMap[degree] = symbols[index];
    });
    
    return symbolMap;
  } catch (error) {
    console.error('Error fetching Sabian symbols:', error);
    return {};
  }
};

/**
 * Returns the zodiac sign array
 * @returns {Array<string>} - Array of zodiac sign names
 */
export const getZodiacSigns = () => [
  "Aries", "Taurus", "Gemini", "Cancer",
  "Leo", "Virgo", "Libra", "Scorpio",
  "Sagittarius", "Capricorn", "Aquarius", "Pisces"
];