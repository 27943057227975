import React, { createContext, useContext, useState } from 'react';

const ViewContext = createContext(null);

export const ViewProvider = ({ children }) => {
    const [viewState, setViewState] = useState({
        isMobile: false,
        modalOpen: false,
        activeTab: 0,
        modalOpenAspect: false,
        activeTabAspect: 0,
        activeTabTransit: 0,
        sidebarOpen: false,
        lineSidebarOpen: false,
        chartTab: 0,
        reverseChartModal: false,
        relocTransitTab: 'relocation',
        floatingModalState: {
          'Chart 1': true,
          'Chart 2': true,
          'Relocated 1': true,
          "Relocated 2": true,
          "Synastry 1&2": true,
          "Relocated Synastry 1&2": true,
          'eclipses': true,
        }, // Object to track state of floating modals by title
        options: {
            trines: false,
            sextiles: false,
            squares: false,
            parans: false,
            second: false,
            relocate: false,
            synastry: false,  // Added synastry option to initial state
            eclipse: false,
        }
    });

    const getIsMobile = () => viewState.isMobile;
    const getModalOpen = () => viewState.modalOpen;
    const getActiveTab = () => viewState.activeTab;
    const getModalOpenAspect = () => viewState.modalOpenAspect;
    const getActiveTabAspect = () => viewState.activeTabAspect;
    const getActiveTabTransit = () => viewState.activeTabTransit;
    const getSidebarOpen = () => viewState.sidebarOpen;
    const getChartTab = () => viewState.chartTab;
    const getLineSidebarOpen = () => viewState.lineSidebarOpen;
    const getRelocTransitTab = () => viewState.relocTransitTab;
    const getOptions = () => viewState.options;
    const getReverseChartModal = () => viewState.reverseChartModal;
    
    // Getter for floating modal state
    const getFloatingModalState = () => viewState.floatingModalState;
    
    // Getter for specific floating modal
    const getFloatingModalOpen = (modalTitle) => 
        viewState.floatingModalState[modalTitle] || false;

    const setIsMobile = (value) => setViewState((prev) => ({ ...prev, isMobile: value }));
    const setModalOpen = (value) => setViewState((prev) => ({ ...prev, modalOpen: value }));
    const setActiveTab = (value) => setViewState((prev) => ({ ...prev, activeTab: value }));
    const setModalOpenAspect = (value) => setViewState((prev) => ({ ...prev, modalOpenAspect: value }));
    const setActiveTabAspect = (value) => setViewState((prev) => ({ ...prev, activeTabAspect: value }));
    const setActiveTabTransit = (value) => setViewState((prev) => ({ ...prev, activeTabTransit: value }));
    const setSidebarOpen = (value) => setViewState((prev) => ({ ...prev, sidebarOpen: value }));
    const setChartTab = (value) => setViewState((prev) => ({ ...prev, chartTab: value }));
    const setLineSidebarOpen = (value) => setViewState((prev) => ({ ...prev, lineSidebarOpen: value }));
    const setRelocTransitTab = (value) => setViewState((prev) => ({ ...prev, relocTransitTab: value }));
    const setReverseChartModal = (value) => setViewState((prev) => ({ ...prev, reverseChartModal: value }));
    
    // Setter for specific floating modal
    const setFloatingModalOpen = (modalTitle, isOpen) => {
        setViewState((prev) => ({
            ...prev,
            floatingModalState: {
                ...prev.floatingModalState,
                [modalTitle]: isOpen
            }
        }));
    };
    
    // Setter for entire floating modal state
    const setFloatingModalState = (value) => {
        if (typeof value === 'function') {
            setViewState((prev) => ({
                ...prev,
                floatingModalState: value(prev.floatingModalState)
            }));
        } else {
            setViewState((prev) => ({
                ...prev,
                floatingModalState: value
            }));
        }
    };
    
    // Close all floating modals
    const closeAllFloatingModals = () => {
        setViewState((prev) => ({
            ...prev,
            floatingModalState: {}
        }));
    };
    
    const setOptionKey = (key, value) => {
        setViewState((prev) => ({
            ...prev,
            options: {
                ...prev.options,
                [key]: value
            }
        }));
    };
    
    const setOptions = (value) => {
        if (typeof value === 'function') {
            setViewState((prev) => ({
                ...prev,
                options: value(prev.options)
            }));
        } else {
            setViewState((prev) => ({
                ...prev,
                options: value
            }));
        }
    };

    const value = {
        viewState,
        getIsMobile,
        setIsMobile,
        getModalOpen,
        setModalOpen,
        getActiveTab,
        setActiveTab,
        getModalOpenAspect,
        setModalOpenAspect,
        getActiveTabAspect,
        setActiveTabAspect,
        getActiveTabTransit,
        setActiveTabTransit,
        getSidebarOpen,
        setSidebarOpen,
        getChartTab,
        setChartTab,
        getLineSidebarOpen,
        setLineSidebarOpen,
        getRelocTransitTab,
        setRelocTransitTab,
        getOptions,
        setOptions,
        setOptionKey,
        getReverseChartModal,
        setReverseChartModal,
        // Add floating modal functions to context value
        getFloatingModalState,
        getFloatingModalOpen,
        setFloatingModalOpen,
        setFloatingModalState,
        closeAllFloatingModals,
    };

    return <ViewContext.Provider value={value}>{children}</ViewContext.Provider>;
};

export const useViewData = () => {
    const context = useContext(ViewContext);
    if (!context) throw new Error('useViewData must be used within ViewProvider');
    return context;
};

export default ViewProvider;
