import React, { useState } from 'react';
import { signs, ZODIAC_SIGNS } from "../../data/zodiacConstants";
import { bodyReference } from "../../scripts/chart";
import BodyComponent from '../charts/chartComponents/BodyComponent';
import { AspectItem } from '../aspects/AspectItem';
import CollapsibleCard from '../ui/CollapsibleCard';

import './PlanetaryComponents.css';
import SignComponent from '../charts/chartComponents/SignComponent';

/**
 * Component for displaying the chart ruler information and its aspects
 * 
 * @param {Object} props - Component props
 * @param {Object} props.processedAspects - Processed aspects data
 * @param {Object} props.config - Chart configuration containing bodies, angles, etc.
 * @param {string} props.title - Optional title for the card
 * @param {number} props.chartNumber - The chart number to use for aspects
 * @returns {JSX.Element} - The chart ruler card component
 */
const ChartRulerCard = ({ processedAspects, config, title, chartNumber = 1 }) => {
    // State for aspects section expansion
    const [aspectsExpanded, setAspectsExpanded] = useState(false);
    
    // State for ruler type (traditional or modern)
    const [rulerType, setRulerType] = useState('ruler');
    
    // Skip rendering if no config or angle bodies
    if (!config || !config.angleBodies || config.angleBodies.length === 0) {
        return null;
    }

    // Find the zodiac sign of the first angle body (typically Ascendant)
    const zodiacSign = Object.values(ZODIAC_SIGNS).find(sign => 
        sign.name === config.angleBodies[0].sign
    );
    
    if (!zodiacSign) {
        return null;
    }
    
    // Get the appropriate ruler based on the selected ruler type
    const chartRuler = zodiacSign[rulerType];

    // Get the ruler body details from bodyReference
    const rulerBody = bodyReference[chartRuler];
    
    // If no ruler found, don't render
    if (!chartRuler || !rulerBody) {
        return null;
    }

    // Filter aspects related to the chart ruler
    const rulerAspects = processedAspects && 
        processedAspects[chartNumber] && 
        Array.isArray(processedAspects[chartNumber]) 
            ? processedAspects[chartNumber].filter(aspect => {
                const rulerLower = chartRuler.toLowerCase();
                return aspect.point1Key === rulerLower || aspect.point2Key === rulerLower;
            })
            : [];

    // Sort aspects by importance (major aspects first, then by orb)
    const sortedAspects = [...rulerAspects].sort((a, b) => {
        // First sort by aspect level (major before minor)
        if (a.aspectLevel !== b.aspectLevel) {
            return a.aspectLevel === 'major' ? -1 : 1;
        }
        // Then sort by orb (smaller orb is more exact/important)
        return (a.orb || 0) - (b.orb || 0);
    });

    // Handle ruler type change
    const handleRulerTypeChange = (e) => {
        setRulerType(e.target.value);
    };

    return (
        <div 
            style={{
                marginBottom: 24,
                borderRadius: 4,
                backgroundColor: 'rgba(255,255,255,0.03)',
                padding: '8px 12px'
            }}
        >
            {/* Title */}
            {title && (
                <div style={{ 
                    color: 'rgba(255,255,255,.5)',
                    textAlign: 'center', 
                    width: '100%',
                    marginBottom: 8
                }}>
                    {title}
                </div>
            )}

            {/* Ruler Type Dropdown */}
            
            <div style={{
                display: 'flex', 
                flexDirection: 'row', 
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: 8,
            }}>
                <span style={{
                    fontSize: 10, 
                    fontWeight: 800, 
                    marginRight: 8,
                    color: 'rgba(255,255,255,0.7)'
                }}>
                    RULER:
                </span>
                <select 
                    value={rulerType}
                    onChange={handleRulerTypeChange}
                    style={{
                        backgroundColor: 'rgba(0,0,0,0.3)',
                        color: 'rgba(255,255,255,0.8)',
                        border: '1px solid rgba(255,255,255,0.2)',
                        borderRadius: 4,
                        padding: '2px 4px',
                        fontSize: 10,
                        cursor: 'pointer'
                    }}
                >
                    <option value="ruler">Traditional</option>
                    <option value="modernRuler">Modern</option>
                </select>
            </div>

            <div style={{
                display: 'flex', 
                flexDirection: 'row', 
                alignItems: 'center'
            }}>
                <BodyComponent 
                    body={{...rulerBody, name: chartRuler}} 
                    showText={true} 
                />
                <span style={{
                    fontSize: 10, 
                    fontWeight: 800, 
                    marginRight: 8,
                    marginLeft: 8,
                    color: 'rgba(255,255,255,0.7)'
                }}>RISING:</span>
                
                <SignComponent sign={zodiacSign} showText={true}/>
            </div>



            {/* Ruler Aspects Section */}
            {sortedAspects.length > 0 && (
                <div style={{
                    marginTop: 12,
                    borderTop: '1px solid rgba(255,255,255,0.1)',
                    paddingTop: 8
                }}>
                    <CollapsibleCard
                        title="RULER ASPECTS"
                        isExpanded={aspectsExpanded}
                        onToggle={setAspectsExpanded}
                        titleStyle={{
                            fontSize: 10,
                            fontWeight: 800,
                            color: 'rgba(255,255,255,0.7)'
                        }}
                        style={{
                            backgroundColor: 'transparent',
                            padding: 0
                        }}
                    >
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems:'flex-start',
                            // width: 'fit-content',
                            gap: 4,
                            marginTop: 6
                        }}>
                            {sortedAspects.map((aspect, index) => (
                                <AspectItem
                                    key={`ruler-aspect-${index}`}
                                    aspect={aspect}
                                    setSelectedAspect={() => {}}
                                    aspectType="natal"
                                />
                            ))}
                        </div>
                    </CollapsibleCard>
                </div>
            )}
        </div>
    );
};

export default ChartRulerCard;