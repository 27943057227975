//src/components/modalsmenus/ReverseChart.jsx
import React, { useState } from 'react';
import { calculateReverseChart, getBestCandidate } from '../../api/trine-backend.api';
import { signs_fwd } from '../../data/zodiacConstants';
import { useDualBirthData } from '../../contexts/birthContext';
import { getTimezoneFromCoordinates } from '../../scripts/helpers';
import { bodyReference } from '../../scripts/chart';

const ZODIAC_SIGNS_fsigns_Fwd = [
  'Aries', 'Taurus', 'Gemini', 'Cancer', 
  'Leo', 'Virgo', 'Libra', 'Scorpio', 
  'Sagittarius', 'Capricorn', 'Aquarius', 'Pisces'
];



const CELESTIAL_BODIES = [
  { id: 'sun', name: 'Sun' },
  { id: 'moon', name: 'Moon' },
  { id: 'asc', name: 'Ascendant' },
  { id: 'mc', name: 'Midheaven' },
  { id: 'saturn', name: 'Saturn' }
];

const ReverseChart = ({ onSubmit }) => {
  const [positions, setPositions] = useState({
    sun: { sign: 'Aries', degree: 0, minute: 0 },
    moon: { sign: 'Aries', degree: 0, minute: 0 },
    asc: { sign: 'Aries', degree: 0, minute: 0 },
    mc: { sign: 'Aries', degree: 0, minute: 0 },
    saturn: { sign: 'Aries', degree: 0, minute: 0 }
  });

  const [bestFoundCandidate, setBestFoundCandidate] = useState(null);

  const {

    getTimezoneNo, 
    setTimezoneNo,
    getUnixTimestampNo,
    setUnixTimestampNo,
    setCoordinatesNo,
    setCoordinatesNoValue
  } = useDualBirthData();

  const setBirthData = (n) => {
    const isoString = bestFoundCandidate.dateTime;
    const unixTimestamp = Math.floor(new Date(isoString).getTime() );
    const latitude = bestFoundCandidate.location.latitude;
    const longitude = bestFoundCandidate.location.longitude;
    const newTimezone = getTimezoneFromCoordinates(latitude,longitude);

    
    // setCoordinatesNo(latitude, "latitude", n)
    // setCoordinatesNo(longitude, "longitude", n)

    setCoordinatesNo({latitude, longitude, lat: latitude, lng: longitude}, n)
    setTimezoneNo(newTimezone, n)
    setUnixTimestampNo(unixTimestamp, n)
    
  }


  const handleChange = (body, field, value) => {
    setPositions(prev => ({
      ...prev,
      [body]: {
        ...prev[body],
        [field]: value
      }
    }));
  };

  //setCoordinatesNo
  /**
   * 
   * @param {
   * } positions 
   * @returns 
   * setUnixTimestamp: (ts) => setUnixTimestampNo(ts, n),
       setDate: (newDate) => setUnixTimestampNo(dateToUnix(newDate, unixToDate(getUnixTimestampNo(n), getTimezoneNo(n)).timeString, getTimezoneNo(n)), n),
       setTime: (newTime) => setUnixTimestampNo(dateToUnix(unixToDate(getUnixTimestampNo(n), getTimezoneNo(n)).dateString, newTime, getTimezoneNo(n)), n),

    setLatitude: (lat) => setCoordinatesNoValue(lat, "latitude", n),
    setLongitude: (lng) => setCoordinatesNoValue(lng, "longitude", n),
   */

  const handleSubmit = async (positions) => {
    try {
      const reverseChart = await calculateReverseChart(positions);
      const best = getBestCandidate(reverseChart);
      console.log(best);
      if (reverseChart && best) {
        setBestFoundCandidate(best);
      }
      
      return best;
    } catch(e) {
      console.log(e);
    }
  };

  return (
    <div className="reverse-chart-container" style={{ 
      color: 'white',
      // padding: '16px',
      maxWidth: '800px',
      // margin: '0 auto'
    }}>
      <h3 style={{ textAlign: 'center', marginBottom: '20px' }}>Reverse Chart Calculator</h3>
      

      <div>
        {CELESTIAL_BODIES.map(body => (
          <div key={body.id} style={{ 
            // marginBottom: '8px',
            padding: '4px',
            borderRadius: '16px',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            border: '1px solid rgba(255, 255, 255, 0.1)'
          }}>
            
            <div style={{ 
              display: 'flex',
              flexWrap: 'wrap',
              gap: '8px',
              alignItems: 'center'
            }}>
              <h3 style={{ marginBottom: '4px', flex: 1, color: bodyReference[body.name].color }}>{bodyReference[body.name].symbol}</h3>
              <div style={{ minWidth: '96px' }}>
                
                <select
                  id={`${body.id}-sign`}
                  value={positions[body.id].sign}
                  onChange={(e) => handleChange(body.id, 'sign', e.target.value)}
                  style={{
                    width: '100%',
                    padding: '8px',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    color: 'white',
                    border: '1px solid rgba(255, 255, 255, 0.3)',
                    borderRadius: '8px'
                  }}
                >
                  {signs_fwd.map((signObj,sign_i) => (
                    <option key={sign_i + " "+signObj.name} value={signObj.name}>{signObj.name + " " + signObj.symbol + " " }</option>
                  ))}
                </select>
              </div>

              <div style={{  minWidth: '32px' }}>
                
                <input
                  id={`${body.id}-degree`}
                  type="number"
                  min="0"
                  max="29"
                  value={positions[body.id].degree}
                  onChange={(e) => handleChange(body.id, 'degree', parseInt(e.target.value, 10) || 0)}
                  style={{
                    width: '100%',
                    padding: '8px',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    color: 'white',
                    border: '1px solid rgba(255, 255, 255, 0.3)',
                    borderRadius: '8px'
                  }}
                />
              </div>
              <div>°</div>

              <div style={{  minWidth: '32px' }}>
                
                <input
                  id={`${body.id}-minute`}
                  type="number"
                  min="0"
                  max="59"
                  value={positions[body.id].minute}
                  onChange={(e) => handleChange(body.id, 'minute', parseInt(e.target.value, 10) || 0)}
                  style={{
                    width: '100%',
                    padding: '8px',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    color: 'white',
                    border: '1px solid rgba(255, 255, 255, 0.3)',
                    borderRadius: '8px'
                  }}
                />
              </div>
              <div>"</div>
            </div>
          </div>
        ))}

        <div style={{ textAlign: 'center', marginTop: '8px' }}>
          <button
            type="submit"
            onClick={e => {
              handleSubmit(positions);
            }}
            style={{
              padding: '12px 16px',
              backgroundColor: 'black', border: '1px solid white',
              color: 'white',
              
              borderRadius: '8px',
              cursor: 'pointer',
              fontSize: '16px'
            }}
          >
            Calculate Chart
          </button>
        </div>
        
        {bestFoundCandidate && (
          <div style={{ 
            marginTop: '8px', 
            padding: '8px', 
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            border: '1px solid rgba(255, 255, 255, 0.1)',
            color: 'white',
            borderRadius: '8px',
            fontSize: 12, lineHeight: '16px',
          }}>
            <b style={{ marginBottom: '4px', textAlign: 'center' }}>Best Match Found</b>
            
              <div>
                <p><strong>Date:</strong> {bestFoundCandidate.dateTime} UTC</p>
                {/* <p><strong>Time:</strong> {new Date(bestFoundCandidate.dateTime).toLocaleTimeString()}</p> */}
                {bestFoundCandidate.location.latitude !== undefined && (
                  <p><strong>Loc:</strong> {bestFoundCandidate.location?.latitude.toFixed(2)}°, {bestFoundCandidate.location?.longitude.toFixed(2)}°</p>
                )}
              </div>
              <div>
                {bestFoundCandidate.location.ascendantError !== undefined && (
                  <p><strong>ASC Error:</strong> {(bestFoundCandidate.location.ascendantError).toFixed(2)}° <strong>MC Error:</strong> {(bestFoundCandidate.location.mcError).toFixed(2)}°</p>
                )}
              </div>
            
            <div style={{display: 'flex', flexDirection: 'row'}}>
            {[1,2].map(n => {
              return <div key={'reversesetter'+n} style={{padding: '12px 16px',margin: 4,
              backgroundColor: 'black', border: '1px solid white',
              color: 'white',
              
              borderRadius: '8px',
              cursor: 'pointer',
              
                fontSize: '16px'}} onClick={() => setBirthData(n)}>
                SET CHART {n}
              </div>
            })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReverseChart;