//src/components/charts/WheelConfig.jsx
import React, { memo } from 'react';
import ZodiacWheel from './ZodiacWheel';
import styles from './WheelConfig.module.scss';

const WheelConfig = memo(({ 
  bodies,
  angleBodies,
  parts,
  cusps,
  aspects,
  asteroids,
  title,
  synastry,
  size = 300,
  selectedAspect,
  aspectSearch,
  setSelectedAspect,
  chartAspectWeight=1,
  name,
}) => {
  const config = {
    bodies: [...bodies, ...(angleBodies || []), ...(parts || [])],
    cusps,
    aspects,
    ascDeg: angleBodies?.[0]?.degree,
    asteroids,
    title,
    synastry,
    size,
    selectedAspect,
    aspectSearch,
    setSelectedAspect,
    chartAspectWeight,
    name,
  };

  if (synastry) {
    return (
      <div className={styles.synastryWheel}>
        <ZodiacWheel {...config} />
      </div>
    );
  }

  return <ZodiacWheel {...config} />;
}, (prevProps, nextProps) => {
  // Custom comparison function to prevent unnecessary re-renders
  const compareArrays = (arr1 = [], arr2 = []) => {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((item, index) => {
      const keys = Object.keys(item || {});
      return keys.every(key => item[key] === arr2[index]?.[key]);
    });
  };

  // Deep comparison for aspect objects
  const compareAspects = (aspect1, aspect2) => {
    if (!aspect1 && !aspect2) return true;
    if (!aspect1 || !aspect2) return false;
    
    // Compare the key properties that define an aspect
    return (
      aspect1.point1Label === aspect2.point1Label &&
      aspect1.point2Label === aspect2.point2Label &&
      aspect1.point1Source === aspect2.point1Source &&
      aspect1.point2Source === aspect2.point2Source &&
      aspect1.aspectKey === aspect2.aspectKey &&
      aspect1.orb === aspect2.orb
    );
  };

  return (
    compareArrays(prevProps.bodies, nextProps.bodies) &&
    compareArrays(prevProps.angleBodies, nextProps.angleBodies) &&
    compareArrays(prevProps.parts, nextProps.parts) &&
    compareArrays(prevProps.cusps, nextProps.cusps) &&
    compareArrays(prevProps.aspects, nextProps.aspects) &&
    compareArrays(prevProps.asteroids, nextProps.asteroids) &&
    prevProps.title === nextProps.title &&
    prevProps.size === nextProps.size &&
    compareAspects(prevProps.selectedAspect, nextProps.selectedAspect) &&
    compareArrays(prevProps.aspectSearch, nextProps.aspectSearch) &&
    JSON.stringify(prevProps.synastry || {}) === JSON.stringify(nextProps.synastry || {})
  );
});

WheelConfig.displayName = 'WheelConfig';

export default WheelConfig;