// src/features/birthdata/BirthDataForm.jsx
import React from 'react';
import DateSelector from '../../components/forms/DateSelector';
import LocationInput from '../../components/forms/LocationInput';
import './BirthDataForm.scss';

/**
 * BirthDataForm component for handling birth data input
 * 
 * This component is focused solely on collecting birth data inputs (location, date, time)
 * and does not handle profile management, which is now the responsibility of the parent
 * ProfileManager component.
 * 
 * @param {Object} props - Component props
 * @param {Function} props.onChangeLocation - Callback when location changes
 * @param {string} props.date - Date string in YYYY-MM-DD format
 * @param {Function} props.setDate - Function to update date
 * @param {string} props.time - Time string in HH:MM format
 * @param {string} props.timezone - IANA timezone string
 * @param {Function} props.setTimezone - Function to update timezone
 * @param {Function} props.setTime - Function to update time
 * @param {number} props.unixTimestamp - Unix timestamp
 * @param {Function} props.setUnixTimestamp - Function to update Unix timestamp
 * @param {string} props.placesValue - Default value for the places autocomplete
 * @param {number} props.latitude - Latitude value
 * @param {number} props.longitude - Longitude value
 * @returns {JSX.Element} BirthDataForm component
 */
const BirthDataForm = React.memo(({ 
  onChangeLocation, 
  date, 
  setDate, 
  time, 
  timezone, 
  setTimezone, 
  setTime, 
  unixTimestamp, 
  setUnixTimestamp, 
  placesValue, 
  latitude, 
  longitude 
}) => {
  // Date selector props
  const dateProps = {
    date, 
    setDate, 
    time, 
    timezone, 
    setTimezone, 
    setTime, 
    setUnixTimestamp
  };

  return (
    <div className="birth-data-form__container">
      {/* Location Input */}
      <LocationInput 
        placesValue={placesValue}
        onChangeLocation={onChangeLocation}
        latitude={latitude}
        longitude={longitude}
        setTimezone={setTimezone}
      />
      
      <div className="birth-data-form__spacer" />
      
      {/* Date Selector */}
      <div className="birth-data-form__date-container">
        <DateSelector {...dateProps} />
      </div>
    </div>
  );
});

export default BirthDataForm;