import React, { memo } from 'react';

import styles from '../ZodiacWheel.module.scss';
import { DEGREES_PER_SIGN } from '../../../utils/zodiacCalculations';

/**
 * BodyTick component renders a tick mark for a celestial body
 */
const BodyTick = memo(({ body, centerX, centerY, radius, size }) => {
  const angle = (DEGREES_PER_SIGN - body.degree) * (Math.PI / 180);
  const innerRadius = radius;
  const outerRadius = radius + size;
  
  const x1 = centerX + Math.cos(angle) * innerRadius;
  const y1 = centerY + Math.sin(angle) * innerRadius;
  const x2 = centerX + Math.cos(angle) * outerRadius;
  const y2 = centerY + Math.sin(angle) * outerRadius;
  
  return (
    <line
      className={styles.bodyTick}
      x1={x1}
      y1={y1}
      x2={x2}
      y2={y2}
      stroke={body.color || 'white'}
    />
  );
});

export default BodyTick;