import React, { useState } from 'react';

/**
 * Component for displaying individual symbol interpretations
 * 
 * @param {Object} props - Component props
 * @param {Object} props.interp - Interpretation data
 * @param {Object} props.sabianSymbol - Sabian symbol data
 * @param {boolean} props.isExpanded - Whether the parent symbol is expanded
 * @returns {JSX.Element} - The symbol interpretation component
 */
const SymbolInterpretation = ({ interp, sabianSymbol, isExpanded }) => {
    const [isInterpExpanded, setIsInterpExpanded] = useState(false);
    
    return (
        <div 
            className='symbolInterpretationContainer' 
            style={{
                fontSize: 10, 
                lineHeight: '12px',
                marginBottom: 4,
                backgroundColor: isInterpExpanded ? 'rgba(255,255,255,.06)' : 'rgba(255,255,255,.03)',
                cursor: 'pointer',
                borderRadius: 4,
            }} 
            onClick={e => {
                e.stopPropagation(); // Prevent triggering parent onClick
                setIsInterpExpanded(!isInterpExpanded);
            }}
        >
            <div style={{
                fontSize: 10,
                color: 'rgba(255,255,255,0.9)',
                fontStyle: 'italic',
                marginBottom: isExpanded ? 8 : 0
            }}>
                {interp.symbol ? interp.symbol : sabianSymbol.sign + ' ' + sabianSymbol.degree}
            </div>
            <div style={{
                fontSize: 10,
                color: 'rgba(255,255,255,0.6)',
                marginBottom: 2
            }}>
                <strong style={{background: 'white', color: 'black'}}>{interp.set}</strong> <strong>Quote Source:</strong>  
                <span 
                    style={{color: 'magenta', textDecoration: 'underline'}} 
                    onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering parent onClick
                        window.open(interp.url, "_blank");
                    }}
                >
                    {interp.key}
                </span>
            </div>
            {interp.keynote && (
                <div style={{
                    fontSize: 9,
                    color: 'rgba(255,255,255,0.8)',
                    marginBottom: 4
                }}>
                    <strong>Keynote:</strong> {interp.keynote}
                </div>
            )}
            <div style={{
                overflowY: 'auto',
                position: 'relative'
            }}>
                {isInterpExpanded 
                    ? interp.interpretation 
                    : interp.interpretation.slice(0, 180) + 
                        (interp.interpretation.length > 180
                            ? '...' 
                            : '')
                }
            </div>
        </div>
    );
};

export default SymbolInterpretation;